import { useState } from 'react';
import { FaEdit, FaPlus, FaTrash } from 'react-icons/fa';
import { capitalizeFirstLetter } from '../../../../utils/capitalizeFirstLetter';

const Accordion = ({
  category,
  items,
  onEdit,
  onDelete,
  setShowCategoryForm,
  setCategoryType,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={`${isOpen ? '' : 'border-b border-gray-300'}  mb-4`}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="w-full text-left p-4 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:bg-gray-200"
      >
        <div className="flex justify-between items-center">
          <h2 className="text-lg font-semibold">
            {category?.charAt(0).toUpperCase() + category?.slice(1)}
          </h2>
          <span>{isOpen ? '-' : '+'}</span>
        </div>
      </button>
      {isOpen && (
        <div className="py-2">
          <div className="flex justify-end pb-5">
            <button
              className="px-5 py-2 rounded-md bg-blue-500 text-white text-sm flex items-center gap-2"
              onClick={() => {
                setShowCategoryForm(true);
                setCategoryType(category);
              }}
            >
              <span>
                <FaPlus />
              </span>
              <span>Add {category}</span>
            </button>
          </div>
          <div className="grid grid-cols-7 justify-between  font-bold items-center mb-2 p-2 bg-gray-200 shadow-sm rounded-md">
            <h3 className="text-md font-medium col-span-1">{'Title'}</h3>
            <p className="text-sm text-gray-600 col-span-2">{'Description'}</p>
            <p className="text-sm text-gray-600 col-span-3">{'Tag'}</p>
          </div>
          {items
            ?.sort((a, b) => a.order - b.order)
            ?.map((item) => (
              <div
                key={item.id}
                className="grid grid-cols-7 justify-between items-center mb-2 p-2 bg-white shadow-sm rounded-md"
              >
                {/* <div> */}
                <h3 className="text-md font-medium col-span-1">{item.title}</h3>
                {/* </div> */}
                <p className="text-sm text-gray-600 col-span-2">
                  {item.description}
                </p>
                <div className="flex text-xs text-gray-600 col-span-3 flex-wrap">
                  {item?.tags.filter(Boolean)?.map((e, id) => {
                    return (
                      <p key={id} className="bg-gray-100 rounded-md mr-2 mb-1 p-2">
                        {capitalizeFirstLetter(e.trim())}
                      </p>
                    );
                  })}
                </div>
                <div className="flex space-x-4">
                  <button
                    onClick={() => onEdit(category, item)}
                    className="text-blue-500 hover:text-blue-700"
                  >
                    <FaEdit />
                  </button>
                  <button
                    onClick={() => onDelete(category, item)}
                    className="text-red-500 hover:text-red-700"
                  >
                    <FaTrash />
                  </button>
                </div>
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default Accordion;
