import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import Data from './Data';
import { useState } from 'react';
import { useGlobalContext } from '../../../../context/context';
import { capitalizeFirstLetter } from '../../../../utils/capitalizeFirstLetter';
import { dashboardColors } from './dashboardColor';

ChartJS.register(ArcElement, Tooltip, Legend);

const gender_data = {
  labels: ['Male', 'Female', 'Other'],
  datasets: [
    {
      label: 'Total',
      data: [2667, 1779],
      backgroundColor: dashboardColors,
    },
  ],
};

const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
};

function StudentCard({ students, studentLength }) {
  const [selectValue, setSelectValue] = useState('');
  const { tracks, levels, stages } = useGlobalContext();

  const track_data = {
    labels: Object.keys(tracks || {}).map((key) => tracks[key]?.title),
    datasets: [
      {
        label: 'Total',
        data: [...Object.values(students?.tracks || {})],
        backgroundColor: dashboardColors,
      },
    ],
  };

  const stage_data = {
    labels: Object.keys(stages || {}).map((key) => stages[key]?.title),
    datasets: [
      {
        label: 'Total',
        data: [...Object.values(students?.stages || {})],
        backgroundColor: dashboardColors,
      },
    ],
  };

  const level_data = {
    labels: Object.keys(levels || {}).map((key) => levels[key]?.title),
    datasets: [
      {
        label: 'Total',
        data: [...Object.values(students?.levels || {})],
        backgroundColor: dashboardColors,
      },
    ],
  };

  const sortedStages = Object.values(stages || {})?.sort(
    (a, b) => a.order - b.order
  );

  return (
    <div className="h-[100%] w-full bg-white rounded-xl p-5">
      <div className="flex justify-between items-center">
        <h1>Students</h1>
        <select
          name=""
          id=""
          value={selectValue}
          onChange={(e) => setSelectValue(e.target.value)}
          className="px-3 py-1 rounded-md border border-blue-ribbon-300"
        >
          <option value="stage">Stage</option>
          <option value="track">Track</option>
          <option value="level">Level</option>
          <option value="gender">Gender</option>
        </select>
      </div>
      <h1 className="my-2 text-gray-500">{studentLength} students</h1>
      {selectValue === 'track' ? (
        <>
          <div className="w-64 h-64 mx-auto">
            <Doughnut data={track_data} options={options}></Doughnut>
          </div>
          <div className="grid grid-cols-2 gap-3 mt-4">
            {Object.keys(tracks || {}).map((e, idx) => {
              return (
                <div className="flex items-center space-x-2" key={idx}>
                  <div
                    className={`h-3 w-3 rounded`}
                    style={{
                      backgroundColor:
                        track_data.datasets[0].backgroundColor[idx],
                    }}
                  ></div>
                  <h1 className="text-[12px] flex items-center">
                    {capitalizeFirstLetter(tracks[e]?.name)} -{' '}
                    {students?.tracks[e] || 0}
                  </h1>
                </div>
              );
            })}
          </div>
        </>
      ) : selectValue === 'level' ? (
        <>
          <div className="w-64 h-64 mx-auto">
            <Doughnut data={level_data} options={options}></Doughnut>
          </div>
          <div className="grid grid-cols-2 gap-3 mt-4">
            {Object.keys(levels || {}).map((e, idx) => (
              <div className="flex items-center space-x-2" key={idx}>
                <div
                  className={`h-3 w-3 rounded`}
                  style={{
                    backgroundColor:
                      level_data.datasets[0].backgroundColor[idx],
                  }}
                ></div>
                <h1 className="text-[12px] flex items-center" key={idx}>
                  {levels[e]?.title} - {students?.levels?.[e] || 0}
                </h1>
              </div>
            ))}
          </div>
        </>
      ) : selectValue === 'gender' ? (
        <>
          <div className="w-64 h-64 mx-auto">
            <Doughnut data={gender_data} options={options}></Doughnut>
          </div>
          <div className="grid grid-cols-2 gap-3 mt-4">
            {Data.slice(0, 2).map((e, idx) => (
              <div className="flex items-center space-x-2" key={idx}>
                <p>{'Gender'}</p>
                <h1 className="text-[12px]">{e.total}</h1>
              </div>
            ))}
          </div>
        </>
      ) : (
        <>
          <div className="w-64 h-64 mx-auto">
            <Doughnut data={stage_data} options={options}></Doughnut>
          </div>
          <div className="grid grid-cols-2 gap-3 mt-4">
            {sortedStages?.map((e, idx) => (
              <div className="flex items-center space-x-2" key={idx}>
                <div
                  className={`h-3 w-3 rounded`}
                  style={{
                    backgroundColor:
                      stage_data.datasets[0].backgroundColor[idx],
                  }}
                ></div>
                <h1 className="text-[12px]" key={idx}>
                  {e?.title} - {students?.stages?.[e.id] || 0}
                </h1>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default StudentCard;
