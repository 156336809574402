import React, { useState } from "react";
import { Bin } from "../../assets";
import PopUp from "./PopUp";

const DeleteModals = ({
  setReq,
  Req,
  DeleteFunc,
  deleteTitle,
  deleteMessage,
  title,
  message1,
  btnSuccess2,
  path,
}) => {
  const [confirm, setConfirm] = useState(false);
  const showSuccess = () => {
    DeleteFunc();
    setReq(true);
    setConfirm(true);
  };

  return (
    <>
      {Req && (
        <div className="bg-[#404040]/10 fixed z-[20] top-0 left-0 h-screen w-full overflow-y-hidden flex justify-center items-center">
          <div className="bg-white py-6 rounded-2xl lg:w-[420px] w-9/12 flex flex-col justify-center items-center mx-auto text-center fixed shadow-lg">
            <img src={Bin} alt="bin" className="my-2" />
            <p className="text-[28px] font-medium">{deleteTitle}</p>
            <p className="font-medium my-2 w-3/4">{deleteMessage}</p>
            <div className="flex justify-center items-center my-2.5">
              <button
                className="bg-transparent rounded-lg border border-[#585858] text-[#585858] py-2 px-3 mr-4"
                onClick={() => setReq(false)}
              >
                Cancel
              </button>
              <button
                className="bg-[#F41717] rounded-lg text-white py-3 px-16 mr-4"
                onClick={showSuccess}
              >
                Yes, delete
              </button>
            </div>
          </div>
        </div>
      )}
      {confirm && (
        <PopUp
          setShow={setConfirm}
          title={title}
          message1={message1}
          btnSuccess2={btnSuccess2}
          path={path}
        />
      )}
    </>
  );
};

export default DeleteModals;
