export const WeeklyCurriculum = [
  {
    id: 1,
    week: "week 1",
    weekTopic: "Introduction to UI, UX & Design Thinking",
    weeksSubTopics: [
      {
        id: 1,
        text: "Distinguish between User Interface and User Experience",
      },
      {
        id: 2,
        text: "Understand problem solving using User Experience Design",
      },
      {
        id: 3,
        text: "Differentiate between Good UX and Bad UX",
      },
      {
        id: 4,
        text: "Promotional Task",
      },
    ],
    open: false,
  },
  {
    id: 2,
    week: "week 2",
    weekTopic: "User Research & User Persona",
    weeksSubTopics: [
      {
        id: 1,
        text: "Distinguish between User Interface and User Experience",
      },
      {
        id: 2,
        text: "Understand problem solving using User Experience Design",
      },
      {
        id: 3,
        text: "Differentiate between Good UX and Bad UX",
      },
      {
        id: 4,
        text: "Promotional Task",
      },
    ],
    open: false,
  },
  {
    id: 3,
    week: "week 3",
    weekTopic: "Information Architecture & User Flow",
    weeksSubTopics: [
      {
        id: 1,
        text: "Distinguish between User Interface and User Experience",
      },
      {
        id: 2,
        text: "Understand problem solving using User Experience Design",
      },
      {
        id: 3,
        text: "Differentiate between Good UX and Bad UX",
      },
      {
        id: 4,
        text: "Promotional Task",
      },
    ],
    open: false,
  },
  {
    id: 4,
    week: "week 4",
    weekTopic: "Sketches, Wireframe & Mid-Fidelity Design",
    weeksSubTopics: [
      {
        id: 1,
        text: "Distinguish between User Interface and User Experience",
      },
      {
        id: 2,
        text: "Understand problem solving using User Experience Design",
      },
      {
        id: 3,
        text: "Differentiate between Good UX and Bad UX",
      },
      {
        id: 4,
        text: "Promotional Task",
      },
    ],
    open: false,
  },
  {
    id: 5,
    week: "week 5",
    weekTopic: "High-Fidelity Design & Prototyping",
    weeksSubTopics: [
      {
        id: 1,
        text: "Distinguish between User Interface and User Experience",
      },
      {
        id: 2,
        text: "Understand problem solving using User Experience Design",
      },
      {
        id: 3,
        text: "Differentiate between Good UX and Bad UX",
      },
      {
        id: 4,
        text: "Promotional Task",
      },
    ],
  },
  {
    id: 6,
    week: "week 6",
    weekTopic: "Usability Testing & Heuristics",
    weeksSubTopics: [
      {
        id: 1,
        text: "Distinguish between User Interface and User Experience",
      },
      {
        id: 2,
        text: "Understand problem solving using User Experience Design",
      },
      {
        id: 3,
        text: "Differentiate between Good UX and Bad UX",
      },
      {
        id: 4,
        text: "Promotional Task",
      },
    ],
  },
];
