import React from "react";

import AdminProfileMain from "../components/AdminProfile/AdminProfile";

const AdminProfile = () => {
  return (
    <>
      <AdminProfileMain />
    </>
  );
};

export default AdminProfile;
