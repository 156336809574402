import { useEffect, useState } from 'react';
import { useGlobalContext } from '../../context/context';
import { useAccountService } from '../../services/account.service';
import { useCategoryService } from '../../services/category.service';
import { useProfileService } from '../../services/profile.service';
import { useWorkspaceService } from '../../services/workspace.service';

export const useCohortJoin = (userRole) => {
  const {
    hideCohortJoinModal,
    setHideCohortJoinModal,
    setGeneratedLevels,
    setGeneratedTracks,
    generatedTracks,
    generatedLevels,
    setIsLoading,
    setWorkspace,
    setUserData,
    setUserProfile,
    setAccountData,
    accountData,
    userData,
    setOldStdRegForm,
  } = useGlobalContext();

  const currentAccount = JSON.parse(localStorage.getItem('account'));
  const [availableCohorts, setAvailableCohorts] = useState([]);
  const [cohortObj, setCohortObj] = useState({});
  const [selectedLevel, setSelectedLevel] = useState(0);
  const [cohortStages, setCohortStages] = useState([]);
  const [allTracks, setAllTracks] = useState([]);
  const [cohorts, setCohorts] = useState([]);
  const [isSuccess, setIsSuccess] = useState(false);
  const [form, setForm] = useState({});
  const [hasNewCohort, setHasNewCohort] = useState(true);
  const [userWorkspaces, setUserWorkspaces] = useState([]);
  const [newCohortSlug, setNewCohortSlug] = useState(null);

  const { fetchWorkspacesAvailable } = useWorkspaceService();
  const { fetchCategory } = useCategoryService();
  const { joinCohort, fetchAccount } = useAccountService();
  const { fetchProfile } = useProfileService();

  useEffect(() => {
    // const account = await fetchAccount();
    setUserWorkspaces(currentAccount?.account?.workspaces);
  }, []);

  useEffect(() => {
    const fetchWSAvailable = async () => {
      const ws = await fetchWorkspacesAvailable();
      setAvailableCohorts(ws);
    };
    fetchWSAvailable();
  }, []);

  const fetchCat = async (workspace) => {
    const catLevels = await fetchCategory(workspace, 'level');
    const catTracks = await fetchCategory(workspace, 'track');
    const catStages = await fetchCategory(workspace, 'stage');
    setGeneratedLevels(catLevels);
    setAllTracks(catTracks);
    setCohortStages(catStages);
  };

  useEffect(() => {
    const selectedCohort = availableCohorts.find((e) => e.slug === form.cohort);
    setCohortObj(selectedCohort);
  }, [form.cohort]);

  useEffect(() => {
    const selectedLevel = generatedLevels.find((e) => e.name === form.level);
    setSelectedLevel(selectedLevel);
  }, [form.level]);

  useEffect(() => {
    if (form.cohort) {
      fetchCat(form.cohort);
    }
  }, [form]);

  useEffect(() => {
    const filterTracks = () => {
      if (form.level !== undefined) {
        const tracks = allTracks.filter((track) =>
          !!track.tags.find(tag => selectedLevel?.tags.includes(tag))
        );
        setGeneratedTracks(tracks);
      }
    };
    filterTracks();
  }, [form.level, allTracks, selectedLevel]);

  useEffect(() => {
    const openCohort = availableCohorts.find((e) => e.status === 'open');
    if (openCohort) {
      setNewCohortSlug(openCohort.slug);
    }
  }, [availableCohorts]);

  useEffect(() => {
    if (newCohortSlug && userWorkspaces.length > 0) {
      const hasKodecamp4 = userWorkspaces.some(
        (item) => item.slug === newCohortSlug
      );

      setHasNewCohort(hasKodecamp4);
    }
  }, [newCohortSlug, userWorkspaces]);

  useEffect(() => {
    const workspaces = accountData.account?.workspaces;
    setCohorts(workspaces);
  }, [accountData]);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const stagetoUse = cohortStages?.sort((a, b) =>
      a.order < b.order ? -1 : 1
    )?.[0];
    const data = {
      workspace_id: cohortObj.id,
      user_type: userRole,
      // user_type: form.type,
      level_id: Number(selectedLevel.id),
      track_id: Number(form.track),
      stage_id: Number(stagetoUse.id),
      info: {
        employment_status: form.employment,
        qualification: form.qualification,
        survey_data: {},
      },
    };
    try {
      setIsLoading(true);
      const joinRes = await joinCohort(data);

      setWorkspace(joinRes.workspace_slug);
      localStorage.setItem('workspace_slug', joinRes.workspace_slug);

      setUserData(joinRes);
      localStorage.setItem('user', JSON.stringify(joinRes));

      const profile = await fetchProfile(joinRes.workspace_slug);

      setUserProfile(profile);
      localStorage.setItem('local-profile', JSON.stringify(profile));
      setOldStdRegForm(false);
      setIsSuccess(true);

      const updatedAccount = await fetchAccount();

      setAccountData((prev) => ({ ...prev, account: updatedAccount }));
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const handleContinue = () => {
    localStorage.setItem('account', JSON.stringify(accountData));
    setIsSuccess(false);
    setHasNewCohort(true);
    setHideCohortJoinModal(null);
  };

  const handleCancelJoinWorkspace = () => {
    setHideCohortJoinModal(true);
  };

  const handleInputChange = ({ target }) => {
    setForm({ ...form, [target.name]: target.value });
  };

  return {
    form,
    hasNewCohort,
    setHasNewCohort,
    isSuccess,
    handleContinue,
    handleFormSubmit,
    handleCancelJoinWorkspace,
    availableCohorts,
    generatedLevels,
    generatedTracks,
    handleInputChange,
    cohortObj,
  };
};
