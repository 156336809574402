import React, { useEffect, useState } from "react";

const Pagination = ({ data, setNewData, setPageData }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const totalPages = Math.ceil(data?.length / itemsPerPage);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const handlePageClick = (page) => {
    setCurrentPage(page);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  useEffect(() => {
    setNewData(data?.slice(startIndex, endIndex));
    setPageData({
      startIndex: startIndex,
      endIndex: endIndex,
    });
  }, [setNewData, setPageData, startIndex, endIndex, data]);

  const pageNumbers = Array.from(
    { length: totalPages },
    (_, index) => index + 1
  );

  return (
    <div className="flex justify-between items-center">
      <div className="flex space-x-2 items-center">
        <p className="text-sm">Number per page</p>
        <select
          value={itemsPerPage}
          className="border border-gray-500 rounded-md px-3 py-1"
          onChange={(e) => setItemsPerPage(e.target.value)}
        >
          <option value={20}>20</option>
          <option value={50}>50</option>
          <option value={100}>100</option>
        </select>
      </div>
      <div className="flex items-center justify-end space-x-6 text-sm text-gray-500">
        <button disabled={currentPage === 1} onClick={handlePrevPage}>
          Prev
        </button>

        {pageNumbers.map((pageNumber) => (
          <div
            key={pageNumber}
            onClick={() => handlePageClick(pageNumber)}
            className={`h-6 w-6 flex flex-col items-center justify-center rounded text-sm cursor-pointer ${
              pageNumber === currentPage ? "bg-blue-500 text-white" : ""
            }`}
          >
            {pageNumber}
          </div>
        ))}

        <button disabled={currentPage === totalPages} onClick={handleNextPage}>
          Next
        </button>
      </div>
    </div>
  );
};

export default Pagination;
