import { useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const InputWidget = ({
  type,
  name,
  placeholder,
  handleInputChange,
  form,
  label,
  method,
  selectOption,
  disable,
}) => {
  const [show, setShow] = useState(false);

  return (
    <div className="">
      <label htmlFor="" className="text-gray-500 text-sm mb-3">
        {label}
      </label>
      {method !== 'select' ? (
        <div className="flex justify-between items-center border border-gray-500 rounded-md mb-4 w-full px-2">
          <input
            type={show ? 'text' : type}
            name={name}
            value={form[name]}
            placeholder={placeholder}
            onChange={handleInputChange}
            required
            className={`outline-none h-10 w-full placeholder:text-[10px] placeholder:text-gray-500 text-gray-500 text-sm`}
          />
          {type === 'password' && (
            <div className="div" onClick={() => setShow(!show)}>
              {show ? <FaEye /> : <FaEyeSlash />}
            </div>
          )}
        </div>
      ) : (
        <select
          name={name}
          id=""
          value={form[name]}
          onChange={handleInputChange}
          required
          className="border border-gray-500 text-sm text-grey-500 rounded-md mb-4 w-full px-2 h-10"
        >
          <option value="">Select option</option>
          {selectOption?.map((option, idx) => {
            return (
              <option
                key={idx}
                value={option.value}
                className="text-sm text-grey-500"
              >
                {option.label}
              </option>
            );
          })}
        </select>
      )}
    </div>
  );
};

export default InputWidget;
