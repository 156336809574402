import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import AvatarWidget from '../../../../components/global/AvatarWidget';
import { useGlobalContext } from '../../../../context/context';
import { ProfileEdit } from './ProfileEdit';
import { capitalizeFirstLetter } from '../../../../utils/capitalizeFirstLetter';

const AdminProfile = () => {
  const [uploadOption, setUploadOption] = useState(false);
  const [editOption, setEditOption] = useState(false);
  const accountData = JSON.parse(localStorage?.getItem('account'));

  const { userProfile, setUserProfile } = useGlobalContext();

  const [showModal, setShowModal] = useState(false);
  const [avatarUrl, setAvatarUrl] = useState(null);

  useEffect(() => {
    if (userProfile?.profile?.avatar) {
      setAvatarUrl(`${userProfile.profile?.avatar}?cache=${Date.now()}`);
    }
  }, [userProfile]);

  return (
    <>
      <div className="flex justify-between items-center">
        <h1>{editOption ? 'Edit Profile' : 'Profile'}</h1>
      </div>
      <div className="bg-white rounded-lg px-3 md:px-8 py-4 mt-6">
        <div className="mb-6">
          <h1 className="">Personal Details</h1>
        </div>

        {!editOption ? (
          <>
            <div className="flex items-center justify-between md:justify-start md:space-x-12">
              <div
                className="img relative cursor-pointer"
                onClick={() => setUploadOption(!uploadOption)}
              >
                <AvatarWidget
                  avatarUrl={avatarUrl}
                  w={'200px'}
                  h={'200px'}
                  text={'100px'}
                  owner={userProfile?.profile}
                />
                <i className="fa-solid fa-camera absolute right-16 bottom-6 md:right-4 text-blue-ribbon-500 md:bottom-4 bg-white p-3 rounded-full"></i>
                <div
                  className={`${
                    uploadOption ? 'block' : 'hidden'
                  } upload absolute top-0 -right-10 shadow-lg bg-white p-4 rounded-lg`}
                >
                  <div className="file cursor-pointer">
                    <label
                      htmlFor="img"
                      className="cursor-pointer"
                      onClick={() => setShowModal(true)}
                    >
                      <i className="fa-solid fa-image mr-1 cursor-pointer"></i>
                      Upload Picture
                    </label>
                  </div>
                </div>
              </div>
              <div className="right space-y-4">
                <h1 className="text-lg md:text-2xl">
                  {userProfile?.profile?.first_name +
                    ' ' +
                    userProfile?.profile?.last_name}
                </h1>
                <p>
                  <span className="text-sm mr-3 text-[grey]">Track:</span>
                  <span>All Tracks</span>
                </p>
                <p>
                  <span className="text-sm text-white px-6 py-1 rounded-full bg-[#303030]">
                    {capitalizeFirstLetter(accountData?.account?.role)}
                  </span>
                </p>
              </div>
            </div>
            <div className="mt-10">
              <ul className="space-y-4 text-center text-sm md:text-base md:w-[40%]">
                <div className="grid grid-cols-1 md:grid-cols-2 text-left border-b pb-1">
                  <li className="">Username</li>

                  <li className="">{userProfile?.username}</li>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 text-left border-b pb-1">
                  <li className="">Email Address</li>
                  <li className="">{accountData?.account?.email}</li>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 text-left border-b pb-1">
                  <li className="">Phone Number</li>
                  <li className="">{'Phone Number'}</li>
                </div>
              </ul>
            </div>
          </>
        ) : (
          <ProfileEdit setEditOption={setEditOption} />
        )}
      </div>
      <PictureModal
        showModal={showModal}
        setShowModal={setShowModal}
        setUserProfile={setUserProfile}
      />
    </>
  );
};

export default AdminProfile;

function PictureModal({ showModal, setShowModal, setUserProfile }) {
  const accountData = JSON.parse(localStorage.getItem('user'));
  const [filePicked, setFilePicked] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleFileChange = ({ target }) => {
    setFilePicked(target.files[0]);
  };

  const handleUpload = () => {
    if (!filePicked) {
      console.log('No file selected.');
      return;
    }

    setIsLoading(true);

    const formData = new FormData();
    formData.append('avatar', filePicked);
    fetch(`${process.env.REACT_APP_API_URL}/profiles/avatar`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${accountData.access_token}`,
      },
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Error uploading file.');
        }
        console.log('Uploaded successfully');
        return response.json();
      })
      .then((data) => {
        setUserProfile(data);
        setIsLoading(false);
        toast.success('Uploaded Successfully');
        setShowModal(false);
      })
      .catch((error) => {
        console.error('Error uploading:', error);
        setIsLoading(false);
        setShowModal(false);
      });
  };

  return (
    <>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="font-semibold">Upload Picture</h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <input
                    type="file"
                    accept=".jpg, .jpeg, .png"
                    onChange={handleFileChange}
                  />
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button>
                  <button
                    className="bg-blue-ribbon-500 text-white active:bg-emerald-600 text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={handleUpload}
                    disabled={isLoading}
                  >
                    {isLoading ? 'Uploading...' : 'Upload'}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
