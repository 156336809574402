import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useGlobalContext } from '../../../context/context';
import { formatDateTime } from '../task/TaskDetailsSUBMISSION';

function TableWidget({ data, columns, route }) {
  const { tracks, stages } = useGlobalContext();
  const navigate = useNavigate();

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full border-separate border-spacing-y-2">
        <thead className="bg-[#eef1f5]">
          <tr>
            {columns?.map((column, index) => (
              <th
                key={index}
                className={`${
                  index === 0
                    ? 'rounded-l-md'
                    : index === columns.length - 1
                    ? 'rounded-r-md'
                    : ''
                } px-6 py-3 text-left text-xs font-medium text-blue-ribbon-500 tracking-wider`}
              >
                {column.header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white ">
          {data?.map((row, rowIndex) => (
            <tr
              key={rowIndex}
              onClick={() => navigate(`/${route}/task/${data[rowIndex].id}`)}
              className="hover:bg-blue-ribbon-100 transition-all ease-in-out duration-100 cursor-pointer"
            >
              {columns?.map((column, colIndex) => (
                <td
                  key={colIndex}
                  className={`${
                    colIndex === 0
                      ? 'rounded-l-md'
                      : colIndex === columns.length - 1
                      ? 'rounded-r-md'
                      : ''
                  } px-6 py-4 whitespace-nowrap text-sm text-gray-900`}
                >
                  {column?.accessor === 'track_id' ? (
                    <span>{tracks?.[row[column.accessor]]?.title || ''}</span>
                  ) : column?.accessor === 'stage_id' ? (
                    <span>{stages?.[row[column.accessor]]?.title || ''}</span>
                  ) : column.accessor === 'due_date' ? (
                    <span>{formatDateTime(row[column.accessor])}</span>
                  ) : column.accessor === 'submissions' ? (
                    <span>
                      {route === 'student' && row[column.accessor]?.[0]?.status}
                    </span>
                  ) : (
                    <span>{row[column.accessor]}</span>
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default TableWidget;
