import React from 'react';
import { useGlobalContext } from './context';

function JoinCohortButton() {
  const { setOldStdRegForm } = useGlobalContext();
  const handleJoinCohort = () => {
    setOldStdRegForm(true);
  };
  return (
    <div>
      <div
        className=" bottom-10 right-24 fixed bg-blue-ribbon-500 text-white p-2 rounded-md shadow-md z-40 cursor-pointer"
        onClick={handleJoinCohort}
      >
        JOIN 4.0
      </div>
    </div>
  );
}

export default JoinCohortButton;
