import React, { useContext, useEffect, useState } from 'react';
import { useGlobalContext } from './context';
import { appUrl } from './env';

const SseContext = React.createContext();

const SseProvider = ({ children }) => {
  const { workspace } = useGlobalContext();
  const [listening, setListening] = useState(false);
  const [listeners, setListeners] = useState({});

  const addMessageListener = (names, callback) => {
    let updated = listeners;
    names.forEach((name) => {
      if (!updated[name]) {
        updated[name] = [];
      }
      if (
        !updated[name].find((call) => call.toString() === callback?.toString())
      ) {
        updated[name].push(callback);
      }
    });
    setListeners(updated);
    console.log('listeners', updated);
  };

  // useEffect(() => {
  //   if (!listening && workspace) {
  //     console.log('sse initialization');
  //     const eventSource = new EventSource(`${appUrl}/${workspace}/stream`);
  //     eventSource.onopen = (event) => {
  //       console.log('Event stream connected');
  //     };
  //     eventSource.onerror = (event) => {
  //       console.error('Error', event);
  //     };
  //     eventSource.onmessage = (event) => {
  //       const data = JSON.parse(event.data);
  //       console.log('message sse received', data, listeners);
  //       listeners[data.name]?.forEach((callback) => callback(data));
  //     };
  //     eventSource.addEventListener('end_event', function (event) {
  //       eventSource.close();
  //     });
  //     setListening(true);
  //   }
  // }, [listening, listeners, workspace]);

  return (
    <SseContext.Provider value={{ addMessageListener }}>
      {children}
    </SseContext.Provider>
  );
};

const useSseContext = () => {
  return useContext(SseContext);
};

export { SseProvider, useSseContext };
