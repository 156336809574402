import React, { useEffect, useState } from 'react';

import { useGlobalContext } from '../../../context/context';
import { RefetchButton } from '../../../context/Refetch';
import useFetch from '../../../hooks/useFetch';
import Pagination from '../Pagination';

import TableWidget from '../table/TableWidget';
import { useSseContext } from '../../../context/sse';

const columns = [
  { header: 'Stage', accessor: 'stage_id' },
  { header: 'Task', accessor: 'title' },
  { header: 'Track', accessor: 'track_id' },
  { header: 'Due Date', accessor: 'due_date' },
  { header: 'Status', accessor: 'status' },
  { header: 'Points', accessor: 'points' },
];

function TaskList({ showTracks, route }) {
  const [currentPosts, setCurrentPosts] = useState([]);
  const [filter, setFilter] = useState({
    level: [],
    track: [],
    stage: [],
  });

  const { stages, levels, tracks, workspace, userProfile } = useGlobalContext();
  const accountData = JSON.parse(localStorage.getItem('account'));
  const { addMessageListener } = useSseContext();
  const [pageData, setPageData] = useState({
    startIndex: 1,
    endIndex: 20,
  });
  const offset = 0;
  const limit = 100;
  const { data: tasks, refetch: refetchTasks } = useFetch(
    `/tasks?offset=${offset}&limit=${limit}`,
    workspace
  );

  useEffect(() => {
    setFilter({
      level: Object.values(levels).map((level) => level.id) || [],
      track: Object.values(tracks).map((track) => track.id) || [],
      stage: Object.values(stages).map((stage) => stage.id) || [],
    });
  }, [levels, tracks, stages]);

  useEffect(() => {
    setCurrentPosts(
      tasks?.tasks?.filter((item, idx) => {
        return (
          filter?.level?.includes(item.level_id) &&
          filter?.track?.includes(item.track_id)
        );
      })
    );
  }, [tasks?.tasks, filter]);

  useEffect(() => {
    addMessageListener(['task-created', 'task-updated'], (data) => {
      const user =
        route === 'student'
          ? 'learner'
          : route === 'instructor'
          ? 'trainer'
          : 'admin';

      if (
        data.task?.level_id === userProfile?.[user].level_id &&
        data.task?.track_id === userProfile?.[user].track_id &&
        data.task?.stage_id === userProfile?.[user].stage_id
      ) {
        refetchTasks();
      }
    });
  }, [userProfile]);

  useEffect(() => {
    if (accountData && accountData?.access_token) {
      const timeoutId = setInterval(() => {
        refetchTasks();
      }, 600000); // 600000 milliseconds = 10 minute

      return () => {
        // Clean up the timeout function when the component unmounts
        clearInterval(timeoutId);
      };
    }
  }, [accountData]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        refetchTasks();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [workspace]);

  return (
    <div>
      <div>
        {showTracks && (
          <>
            <div className="lg:w-full lg:overflow-x-hidden overflow-x-scroll">
              <div className="flex mt-4 space-x-5 text-[#0D6EFD] text-sm">
                <p
                  className={`cursor-pointer ${
                    filter?.level?.length === Object.keys(levels)?.length
                      ? 'text-gray-500 border-b-2 border-black'
                      : 'text-[#0D6EFD]'
                  } `}
                  onClick={(e) =>
                    setFilter({
                      ...filter,
                      level:
                        Object.values(levels).map((level) => level.id) || [],
                    })
                  }
                >
                  All
                </p>
                {Object.values(levels).map((level, index) => (
                  <p
                    key={index}
                    className={`cursor-pointer ${
                      filter?.level?.length !== Object.keys(levels)?.length &&
                      filter?.level?.includes(level.id)
                        ? 'text-gray-500 border-b-2 border-black'
                        : 'text-[#0D6EFD]'
                    } `}
                    onClick={(e) => setFilter({ ...filter, level: [level.id] })}
                  >
                    {level.title}
                  </p>
                ))}
              </div>
            </div>
            <div className="lg:w-full lg:overflow-x-hidden overflow-x-scroll">
              <div className="flex my-4 space-x-5 text-[#0D6EFD] text-sm">
                <p
                  key={0}
                  className={`cursor-pointer ${
                    filter?.track?.length === Object.keys(tracks)?.length
                      ? 'text-gray-500 border-b-2 border-black'
                      : 'text-[#0D6EFD]'
                  } `}
                  onClick={(e) =>
                    setFilter({
                      ...filter,
                      track:
                        Object.values(tracks).map((track) => track.id) || [],
                    })
                  }
                >
                  All
                </p>
                {Object.values(tracks)?.map((track, index) => (
                  <p
                    key={index}
                    className={`cursor-pointer ${
                      filter?.track?.length !== Object.keys(tracks)?.length &&
                      filter?.track?.includes(track.id)
                        ? 'text-gray-500 border-b-2 border-black'
                        : 'text-[#0D6EFD]'
                    } `}
                    onClick={(e) => setFilter({ ...filter, track: [track.id] })}
                  >
                    {track.title}
                  </p>
                ))}
              </div>
            </div>
          </>
        )}

        <p className="text-base text-center font-normal"></p>

        <TableWidget data={currentPosts} columns={columns} route={route} />
        <RefetchButton name="taskListFetch" functions={[refetchTasks]} />

        <Pagination
          data={tasks?.tasks?.sort((a, b) => (a.id > b.id ? -1 : 1))}
          setNewData={setCurrentPosts}
          setPageData={setPageData}
          pageData={pageData}
        />
      </div>
    </div>
  );
}

export default TaskList;
