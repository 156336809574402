import { FormBuilder } from '@kodehauzinternship/react-library';
import { useEffect, useState } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { FaPlus } from 'react-icons/fa6';
import { toast } from 'react-toastify';
import StatusModal from '../../../../components/StatusModal';
import Modal from '../../../../components/global/Modal';
import { useGlobalContext } from '../../../../context/context';
import { AddWorkspaceFormElements } from '../../../../data/dashboardData';
import { useAccountService } from '../../../../services/account.service';
import { useWorkspaceService } from '../../../../services/workspace.service';
import WorkspaceTable from './WorkspaceTable';

function Workspace() {
  const [formData, setFormData] = useState({});
  const [addWorkspaceForm, setAddWorkspaceForm] = useState(false);
  const [loadingState, setLoadingState] = useState(false);
  const [workspaceAdded, setWorkspaceAdded] = useState(false);
  const { saveWorkspace, fetchWorkspaces } = useWorkspaceService();
  const { fetchAccount } = useAccountService();

  const [workspaces, setWorkspaces] = useState([]);
  const { setAccountData, accountData } = useGlobalContext();

  useEffect(() => {
    const fetchWS = async () => {
      const data = await fetchWorkspaces();
      setWorkspaces(data);
    };
    fetchWS();
  }, []);

  const handleFormSubmit = async (workspace_data) => {
    setLoadingState(true);
    try {
      const workspace = await saveWorkspace({
        ...workspace_data,
        logo: 'https://example.com',
        start_date: workspace_data.start_date + `T12:00:00`,
        end_date: workspace_data.end_date + `T12:00:00`,
        data: {},
      });

      if (!formData.id) {
        setWorkspaces((prev) => [...prev, workspace]);
      } else {
        setWorkspaces((prev) =>
          prev.map((e) => (e.id === formData.id ? workspace : e))
        );
      }
      const updatedAccount = await fetchAccount();
      setAccountData((prev) => ({ ...prev, account: updatedAccount }));
      localStorage.setItem('account', JSON.stringify(accountData));
      setAddWorkspaceForm(false);
      setWorkspaceAdded(true);
    } catch (error) {
      console.error(error);
      toast.error(error + '');
    } finally {
      setLoadingState(false);
    }
  };

  return (
    <div className="">
      <div className="flex justify-between items-center mb-5">
        <h1 className="text-xl">Workspace</h1>
        <button
          className="px-5 py-2 rounded-md bg-blue-500 text-white text-sm flex items-center gap-2"
          onClick={() => setAddWorkspaceForm(true)}
        >
          <span>
            <FaPlus />
          </span>
          <span>Add workspace</span>
        </button>
      </div>

      <WorkspaceTable
        data={workspaces}
        columns={column}
        setFormData={setFormData}
        setAddWorkspaceForm={setAddWorkspaceForm}
      />
      <Modal
        isOpen={addWorkspaceForm}
        onClose={() => setAddWorkspaceForm(false)}
      >
        <h1 className="mb-4">{formData.id ? 'Edit' : 'Create'} Workspace</h1>
        <FormBuilder
          elements={AddWorkspaceFormElements(!!formData.id)}
          formData={formData}
          onSubmit={handleFormSubmit}
          loadingState={loadingState}
        />
      </Modal>
      <StatusModal
        isOpen={workspaceAdded}
        onClose={() => setWorkspaceAdded(false)}
      >
        <div className="bg-white rounded-md flex flex-col items-center space-y-5 w-80 text-center">
          <FaCheckCircle className="text-[65px] text-green-400" />
          <p className="text-2xl text-gray-700">
            Workspace {!!formData.id ? 'saved' : 'created'} succesfully
          </p>
          <p className="text-smtext-gray-500">
            You can now make use of your workspace
          </p>
          <button
            className="px-14 w-[90%] py-2 rounded-md bg-blue-500 text-white text-sm"
            onClick={() => {
              setAddWorkspaceForm(false);
              setWorkspaceAdded(false);
            }}
          >
            <span>Okay</span>
          </button>
        </div>
      </StatusModal>
    </div>
  );
}

export default Workspace;

const column = [
  {
    header: '',
    accessor: 'logo',
    type: 'image',
  },
  {
    header: 'Name',
    accessor: 'name',
  },
  {
    header: 'Title',
    accessor: 'label',
  },
  {
    header: 'Description',
    accessor: 'description',
  },
  {
    header: 'Start Date',
    accessor: 'start_date',
    type: 'datetime',
  },
  {
    header: 'End Date',
    accessor: 'end_date',
    type: 'datetime',
  },
  {
    header: 'Link',
    accessor: 'registration_link',
  },
  {
    header: 'Status',
    accessor: 'status',
    type: 'status',
  },
];
