function doesTrainerMatchSearch(e, SearchValue) {
  if (SearchValue.trim() === '') {
    return true;
  }

  const firstNameMatch = e.profile?.first_name
    .toLowerCase()
    .includes(SearchValue.toLowerCase());

  const lastNameMatch = e.profile?.last_name
    .toLowerCase()
    .includes(SearchValue.toLowerCase());

  return firstNameMatch || lastNameMatch;
}

export default doesTrainerMatchSearch;
