import React from 'react';
import { useState } from 'react';
import { useGlobalContext } from '../../../../context/context';

export const ProfileEdit = ({ setEditOption }) => {
  const { userData } = useGlobalContext();
  const [isLoading] = useState(false);
  const [isError] = useState(false);
  const [errorMsg] = useState(null);

  const { data, user } = userData;
  const [value, setValue] = useState({
    first_name: data?.first_name ? data?.first_name : '',
    last_name: data?.flast_name ? data?.last_name : '',
    phone: user?.phone ? user?.phone : '',
    username: user?.username ? user?.username : '',
    email: user?.email ? user?.email : '',
  });

  const handleEditChanges = ({ target }) => {
    setValue({ ...value, [target.name]: target.value });
  };

  const handleUpdateProfile = async () => {};

  return (
    <>
      <form action="" className="mt-4">
        {isError && (
          <h1 className="text-red-600 text-sm text-center my-4">{errorMsg}</h1>
        )}
        <div className="md:flex justify-between items-center md:space-x-4">
          <div className="left md:w-[50%]">
            <label htmlFor="" className="block text-[grey]">
              First Name
            </label>
            <input
              type="text"
              name="first_name"
              value={value.first_name}
              onChange={handleEditChanges}
              className="md:py-1 px-1 border border-[grey] w-full md:w-full"
            />
          </div>
        </div>
        <div className="my-6 md:flex justify-between items-center md:space-x-4">
          <div className="left md:w-[50%]">
            <label htmlFor="" className="block text-[grey]">
              Last Name
            </label>
            <input
              type="text"
              name="last_name"
              value={value.last_name}
              onChange={handleEditChanges}
              className="md:py-1 px-1 border border-[grey] w-full md:w-full"
            />
          </div>
        </div>
        <div className="my-6 md:flex justify-between items-center md:space-x-4">
          <div className="left md:w-[50%]">
            <label htmlFor="" className="block text-[grey]">
              User Name
            </label>
            <input
              type="text"
              name="username"
              value={value.username}
              disabled
              className="md:py-1 px-1 border border-[grey] w-full md:w-full"
            />
          </div>
        </div>
        <div className="my-6 md:flex justify-between items-center md:space-x-4">
          <div className="right md:w-[50%]">
            <label htmlFor="" className="block text-[grey]">
              Email Address
            </label>
            <input
              type="email"
              name="email"
              value={value.email}
              disabled
              className="md:py-1 px-1 border border-[grey] w-full md:w-full"
            />
          </div>
        </div>
        <div className="my-6 md:flex justify-between items-center md:space-x-4">
          <div className="right md:w-[50%]">
            <label htmlFor="" className="block text-[grey]">
              Phone Number
            </label>
            <input
              type="tel"
              name="phone"
              value={value.phone}
              disabled
              className="md:py-1 px-1 border border-[grey] w-full md:w-full"
            />
          </div>
        </div>
      </form>
      <div className="flex mt-4 space-x-4">
        <button
          className="px-4 border border-[grey] text-sm py-1 rounded-md"
          onClick={() => setEditOption(false)}
        >
          Cancel
        </button>
        <button
          onClick={handleUpdateProfile}
          className="px-4 bg-blue-ribbon-500 text-white text-sm py-1 rounded-md"
        >
          Save Changes
        </button>
      </div>
      {isLoading && (
        <div className="z-50 absolute bg-black opacity-70 top-0 right-0 left-0 bottom-0 flex justify-center items-center">
          <div className="w-10 h-10 border-r-2 border-t-4 border-b-6 border-dotted border-gray-500 rounded-full animate-spin"></div>
        </div>
      )}
    </>
  );
};
