import React, { useContext, useEffect, useState } from 'react';

import useFetch from '../hooks/useFetch';
import { useCategoryService } from '../services/category.service';

const RestContext = React.createContext();

const ContextProvider = ({ children }) => {
  const [bookmarked, setBookmarked] = useState([]);
  const [globalSearch, setGlobalSearch] = useState('');
  const [userData, setUserData] = useState({});
  const [accountData, setAccountData] = useState({});
  const [versionOne, setVersionOne] = useState(true);
  const [linkLoading, setLinkLoading] = useState(false);
  const [userProfile, setUserProfile] = useState({
    id: 0,
    username: '',
    email: '',
    phone: '',
    profile: [
      {
        id: 0,
        first_name: '',
        last_name: '',
        date_of_birth: '',
        gender: '',
        marital_status: '',
        avatar: '',
      },
    ],
    student: [
      {
        id: 0,
        level_id: 0,
        track_id: 0,
        stage_id: 0,
      },
    ],
    trainer: [
      {
        id: 0,
        level_id: 0,
        track_id: 0,
        stage_id: 0,
      },
    ],
    info: [
      {
        id: 0,
        employment_status: '',
        qualification: '',
        information_source: '',
      },
    ],
  });
  const [filterOption, setFilterOption] = useState('All');
  const [stages, setStages] = useState({});
  const [levels, setLevels] = useState({});
  const [tracks, setTracks] = useState({});
  const [selectedItems, setSelectedItems] = useState([]);
  const [task, setTask] = useState([]);
  const [sortOption, setSortOption] = useState('');
  const [adminSearchValue, setAdminSearchValue] = useState('');
  const [trainerList, setTrainerList] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [generatedLevels, setGeneratedLevels] = useState([]);
  const [overall, setOverall] = useState({
    0: {
      student_id: 0,
      total_score: 0,
      total_points: 0,
    },
  });
  const [workspace, setWorkspace] = useState(null);
  const { fetchCategory } = useCategoryService(workspace);
  const [generatedTracks, setGeneratedTracks] = useState([]);
  const [availableWorkspaces, setAvailableWorkspaces] = useState([]);
  const [hideCohortJoinModal, setHideCohortJoinModal] = useState(false);
  const [oldStdRegForm, setOldStdRegForm] = useState(false);

  useEffect(() => {
    const ws_slug = localStorage.getItem('workspace_slug');
    setWorkspace(ws_slug);
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (accountData?.access_token) {
      fetchCategory(workspace, 'track').then((data) => {
        setTracks(
          data?.reduce((tracks, track) => {
            tracks[track.id] = track;
            return tracks;
          }, {})
        );
      });
      fetchCategory(workspace, 'level').then((data) => {
        setLevels(
          data?.reduce((levels, level) => {
            levels[level.id] = level;
            return levels;
          }, {})
        );
      });
      fetchCategory(workspace, 'stage').then((data) => {
        setStages(
          data?.reduce((stages, stage) => {
            stages[stage.id] = stage;
            return stages;
          }, {})
        );
      });
    }
  }, [accountData, workspace]);

  const { data: trainer } = useFetch('/trainers', workspace);

  useEffect(() => {
    setTrainerList(
      trainer?.reduce((trainers, trainer) => {
        trainers[trainer?.id] = trainer;
        return trainers;
      }, {})
    );
  }, [trainer]);

  useEffect(() => {
    const account = JSON.parse(localStorage.getItem('account'));
    setAccountData(account);
  }, []);

  return (
    <RestContext.Provider
      value={{
        userData,
        setUserData,
        accountData,
        setAccountData,
        bookmarked,
        setBookmarked,
        globalSearch,
        setGlobalSearch,
        versionOne,
        setVersionOne,
        userProfile,
        setUserProfile,
        stages,
        setStages,
        levels,
        setLevels,
        tracks,
        setTracks,
        selectedItems,
        setSelectedItems,
        task,
        setTask,
        filterOption,
        setFilterOption,
        overall,
        setOverall,
        adminSearchValue,
        setAdminSearchValue,
        sortOption,
        setSortOption,
        trainerList,
        setTrainerList,
        linkLoading,
        setLinkLoading,
        workspace,
        setWorkspace,
        isLoading,
        setIsLoading,
        generatedLevels,
        setGeneratedLevels,
        generatedTracks,
        setGeneratedTracks,
        availableWorkspaces,
        setAvailableWorkspaces,
        hideCohortJoinModal,
        setHideCohortJoinModal,
        oldStdRegForm,
        setOldStdRegForm,
      }}
    >
      {children}
    </RestContext.Provider>
  );
};

const useGlobalContext = () => {
  return useContext(RestContext);
};

export { ContextProvider, useGlobalContext };
