import React, { useEffect, useState } from 'react';
import { capitalizeFirstLetter } from '../utils/capitalizeFirstLetter';

const SelectGroup = ({ selectOptions, selections, setSelections }) => {
  const [filteredOptions, setFilteredOptions] = useState({});

  const sharesTagsWith = (cat1, cat2) => {
    return !!cat1?.tags?.find((tag) => cat2?.tags?.includes(tag));
  };

  useEffect(
    () =>
      setFilteredOptions({
        from: selectOptions,
        to: selectOptions,
      }),
    [selectOptions]
  );

  const handleChange = (category, direction, newValue) => {
    if (category === 'level') {
      const selectedLevel = selectOptions?.level?.find(
        (lvl) => lvl.id === Number(newValue)
      );
      const options =
        newValue === ''
          ? { from: selectOptions, to: selectOptions }
          : {
              ...{ from: selectOptions, to: selectOptions },
              [direction]: {
                ...selectOptions,
                track: selectOptions?.track?.filter((trk) =>
                  sharesTagsWith(trk, selectedLevel)
                ),
                stage: selectOptions?.stage?.filter((stg) =>
                  sharesTagsWith(stg, selectedLevel)
                ),
              },
            };
      setFilteredOptions(options);
    }
    setSelections((prevState) => ({
      ...prevState,
      [category]: {
        ...prevState[category],
        [direction]: newValue,
      },
    }));
  };

  return (
    <div className="grid grid-cols-3 gap-x-10 items-center py-5">
      {Object.keys(selectOptions || {})?.map((key) => (
        <div key={key} className="space-y-2">
          <p className="text-blue-500 text-sm">{capitalizeFirstLetter(key)}</p>
          <div className="flex items-center">
            <div className="w-16">
              <p className="text-sm text-gray-500">From:</p>
            </div>
            <select
              className="col-span-3 px-2 w-full py-2 rounded-md bg-transparent border border-gray-300 text-gray-500 text-sm"
              value={selections?.[key]?.from || ''}
              onChange={(e) => handleChange(key, 'from', e.target.value)}
            >
              <option value="">Select</option>
              {filteredOptions.from?.[key]?.map((option, index) => (
                <option key={index} value={option.id}>
                  {option.title}
                </option>
              ))}
            </select>
          </div>
          <div className="flex items-center">
            <div className="w-16">
              <p className="text-sm text-gray-500">To:</p>
            </div>
            <select
              className="col-span-3 px-2 w-full py-2 rounded-md bg-transparent border border-gray-300 text-gray-500 text-sm"
              value={selections?.[key]?.to || ''}
              onChange={(e) => handleChange(key, 'to', e.target.value)}
            >
              (<option value="">Select</option>)
              {filteredOptions.to?.[key]?.map((option, index) => (
                <option key={index} value={option.id}>
                  {option.title}
                </option>
              ))}
            </select>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SelectGroup;
