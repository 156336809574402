import React from 'react';
import { useGlobalContext } from '../../../../context/context';

function PerformanceDropDown({ dropdownValue, setDropdownValue }) {
  const { tracks, levels } = useGlobalContext();

  const getFilteredLevels = () => {
    if (!dropdownValue.parentdrop) return [];
    const selectedTrackTags = tracks[dropdownValue.parentdrop]?.tags || [];
    return Object.values(levels).filter((level) =>
      selectedTrackTags.some((tag) => level.tags.includes(tag))
    );
  };

  return (
    <div className="flex flex-col gap-1">
      <label>
        <select
          value={dropdownValue.parentdrop}
          onChange={(e) =>
            setDropdownValue((prev) => ({
              ...prev,
              parentdrop: e.target.value,
            }))
          }
          className="w-40 h-10 border border-[#5E9EFE] text-center rounded-md text-[#5E9EFE] text-xs"
        >
          <option value="all">All</option>
          {Object.keys(tracks || {}).map((track) => {
            return (
              <option key={track} value={track}>
                {tracks[track].title}
              </option>
            );
          })}
        </select>
      </label>

      {dropdownValue.parentdrop && (
        <label>
          <select
            value={dropdownValue.childdrop}
            onChange={(e) =>
              setDropdownValue((prev) => ({
                ...prev,
                childdrop: e.target.value,
              }))
            }
            className="w-40 h-10 border border-[#5E9EFE] text-center rounded-md text-[#5E9EFE] text-xs"
          >
            <option value="all">All</option>
            {getFilteredLevels().map((level, index) => {
              return (
                <option key={index} value={level.id}>
                  {level.title}
                </option>
              );
            })}
          </select>
        </label>
      )}
    </div>
  );
}

export default PerformanceDropDown;
