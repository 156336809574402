import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Drop from '../../../components/global/Drop';
import Progress from '../../../components/global/Progress';
import CalendarWidget from '../../../components/global/calender/CalendarWidget';
import { formatDateTime } from '../../../components/global/task/TaskDetailsSUBMISSION';
import JoinCohortModal from '../../../components/joinCohort/JoinCohortModal';
import { useCohortJoin } from '../../../components/joinCohort/useCohortJoin';
import { RefetchButton } from '../../../context/Refetch';
import { useGlobalContext } from '../../../context/context';
import {
  calculateWeekCount,
  calculateWeekPercentage,
} from '../../../context/datecalculate';
import useFetch from '../../../hooks/useFetch';
import {
  getCountOfStages,
  getCurrentStage,
} from '../../../utils/learningProgressInfo';
import { useSseContext } from '../../../context/sse';

const getName = (prop, data) => {
  return prop.find((e) => e.id === data.student_id);
};
const InstructorDashboard = () => {
  let navigate = useNavigate();
  const currentUser = JSON.parse(localStorage.getItem('user'));
  const accountData = JSON.parse(localStorage.getItem('account'));
  const {
    levels,
    stages,
    tracks,
    userProfile,
    workspace,
    oldStdRegForm,
    setOldStdRegForm,
    hideCohortJoinModal,
  } = useGlobalContext();
  const { addMessageListener } = useSseContext();
  const offset = 0;
  const limit = 2000;
  const {
    data: submissions,
    isPending,
    refetch: refetchSubmissions,
  } = useFetch(`/submissions/?offset=${offset}&limit=${limit}`, workspace);

  const dropData = ['All', 'Submitted', 'Graded'];
  const { data: students, refetch: refetchStudents } = useFetch(
    `/students?offset=${offset}&limit=${limit}`,
    workspace
  );
  const [selected, setSelected] = useState('All');

  useEffect(() => {
    addMessageListener(['task-submitted'], (data) => {
      // Refresh if current trainer is in the level and track of the submitted task.
      if (
        data.task?.level_id === userProfile?.trainer?.level_id &&
        data.task?.track_id === userProfile?.trainer?.track_id
      ) {
        refetchSubmissions();
      }
    });
  }, [userProfile]);

  useEffect(() => {
    if (accountData && accountData?.access_token) {
      const timeoutId = setInterval(() => {
        refetchSubmissions();
      }, 600000); // 600000 milliseconds = 10 minute

      return () => {
        // Clean up the timeout function when the component unmounts
        clearInterval(timeoutId);
      };
    }
  }, [accountData]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        refetchSubmissions();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [workspace]);

  const {
    form,
    hasNewCohort,
    isSuccess,
    handleContinue,
    handleFormSubmit,
    handleCancelJoinWorkspace,
    availableCohorts,
    generatedLevels,
    generatedTracks,
    handleInputChange,
    cohortObj,
  } = useCohortJoin('trainer');

  return (
    <div>
      <div className="flex dashboard justify-between items-center">
        <h1 className="font-medium text-2xl dash-text">Dashboard</h1>
        {currentUser?.type === 'trainer' ? (
          <div className="flex items-center justify-between text-sm text-gray-700 space-x-2 py-3">
            <p className="">
              {tracks[userProfile?.trainer?.track_id]?.title +
                ' ' +
                currentUser?.type?.charAt(0).toUpperCase() +
                currentUser?.type.slice(1)}{' '}
            </p>
            <p className="w-[4px] h-[4px] rounded-full bg-black"></p>
            <p className="">{levels[userProfile?.trainer?.level_id]?.title}</p>
          </div>
        ) : currentUser?.type === 'learner' ? (
          <div className="flex items-center justify-between text-xs text-gray-500 space-x-2 py-3">
            <p className="">
              {tracks[userProfile?.student?.track_id]?.title +
                ' ' +
                currentUser?.type?.charAt(0).toUpperCase() +
                currentUser?.type.slice(1)}{' '}
            </p>
            <p className="w-[4px] h-[4px] rounded-full bg-black"></p>
            <p className="">{levels[userProfile?.student?.level_id]?.title}</p>
          </div>
        ) : null}
      </div>
      <div className="grid lg:grid-cols-5 gap-4 mt-5">
        <div className="w-full lg:col-span-3 h-full relative">
          <Progress
            progressTitle="Training Progress"
            track={tracks[userProfile?.trainer?.track_id]?.title}
            week={calculateWeekCount() - 1}
            percentage={
              parseInt(calculateWeekPercentage()) > 100
                ? 100
                : parseInt(calculateWeekPercentage())
            }
            pointsTitle="No. of Students"
            totalScore={students?.length}
            totalPoints={''}
            stagesTitle="Current cohort stage"
            currentStage={getCurrentStage(stages, students)}
            countOfStages={getCountOfStages(stages)}
          />
        </div>

        <div className="bg-white px-4 w-full lg:col-span-2 rounded-xl relative">
          <div className="absolute right-0 left-0 top-0 bottom-0 z-20 flex justify-end items-start p-2">
            <div className="w-full h-full bg-white opacity-90 absolute"></div>
            <h1 className="text-sm text-gray-500 z-50 italic">Coming Soon</h1>
          </div>
          <CalendarWidget
            addView={true}
            route={'/instructor/dashboard/morecalendar'}
          />
        </div>
      </div>

      <div className="bg-white mt-6 overflow-x-auto">
        <div className="flex flex-col gap-2 md:flex-row justify-between md:items-center pt-4 px-4">
          <h1 className="text-lg">Recent Submissions</h1>

          <div className="flex items-center gap-2">
            <p className="text-lg font-normal text-[#585858]">Filter</p>

            <Drop
              data={dropData}
              selected={selected}
              setSelected={setSelected}
            />
          </div>
        </div>

        <div className="w-full overflow-x-scroll lg:w-full lg:overflow-x-hidden">
          <div className="grid grid-cols-8 items-center w-[990px] lg:w-full bg-blue-ribbon-50 py-4 px-5 my-4 text-base text-[#0D6EFD]">
            <p>S/N</p>
            <p className="col-span-2">Full Name</p>
            <p className="col-span-2">Title</p>
            <p className="col-span-2 pl-7">Submit Date</p>
            <p className="pl-7">Status</p>
          </div>

          <div className="flex flex-col gap-3">
            {submissions?.submissions
              ?.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))
              ?.filter((e) =>
                selected === 'All'
                  ? e.status
                  : e.status === selected.toLowerCase()
              )
              .map((data, idx) => (
                <div
                  key={data.id}
                  className="grid grid-cols-8 gap-2 items-center w-[990px] lg:w-full bg-white py-4 px-5 text-[#808080] text-sm rounded-xl mt-4 cursor-pointer hover:bg-blue-ribbon-50"
                  onClick={() =>
                    navigate(`/instructor/task/${data.task_id}/submissions`)
                  }
                >
                  <p>{idx + 1}</p>
                  <p className="col-span-2 truncate w-10/12">
                    {getName(submissions?.students, data)?.profile?.first_name +
                      ' ' +
                      getName(submissions?.students, data)?.profile?.last_name}
                  </p>
                  <p className="col-span-2">{data?.task?.title}</p>
                  <p className="pl-7 col-span-2">
                    {formatDateTime(data?.updated_at)}
                  </p>
                  {submissions?.submissions?.length > 0 ? (
                    data?.status === 'graded' ? (
                      <p className="pl-7 text-green-500">Graded</p>
                    ) : (
                      <p className="pl-7 text-yellow-500">Submitted</p>
                    )
                  ) : (
                    <p className="pl-7 text-red-500">Not Submitted</p>
                  )}
                </div>
              ))}
          </div>
        </div>

        {isPending && (
          <div className="min-h-[4rem] w-full flex items-center justify-center">
            <div className="w-8 h-8 border-r-2 border-t-4 border-b-6 border-dotted border-gray-500 rounded-full animate-spin"></div>
          </div>
        )}
        {submissions?.submissions?.length < 1 && (
          <p className="text-center mt-5 text-black">No Submissions</p>
        )}
      </div>
      <div className="flex justify-end mt-5">
        <h1
          className="text-white px-4 py-1 rounded-md bg-blue-ribbon-500 cursor-pointer"
          onClick={() => navigate(`/instructor/task/`)}
        >
          View All
        </h1>
      </div>
      <RefetchButton
        name="InstructorDashBoardRefetch"
        functions={[refetchSubmissions, refetchStudents]}
      />
      <JoinCohortModal
        hasNewCohort={hasNewCohort}
        hideCohortJoinModal={hideCohortJoinModal}
        handleCancelJoinWorkspace={handleCancelJoinWorkspace}
        setOldStdRegForm={setOldStdRegForm}
        oldStdRegForm={oldStdRegForm}
        handleInputChange={handleInputChange}
        handleFormSubmit={handleFormSubmit}
        form={form}
        availableCohorts={availableCohorts}
        generatedLevels={generatedLevels}
        generatedTracks={generatedTracks}
        isSuccess={isSuccess}
        handleContinue={handleContinue}
        cohortObj={cohortObj}
      />
    </div>
  );
};

export default InstructorDashboard;
