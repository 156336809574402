import React from "react";
import AdminSupportMain from "./AdminSupport/AdminSupport";
function AdminSupport() {
  return (
    <div>
      <AdminSupportMain />
    </div>
  );
}

export default AdminSupport;
