import React from "react";
import { Outlet } from "react-router-dom";

import PageWrapper from "../../layouts/PageWrapper";
import { instructor_SideItem } from "../../layouts/sidebar_data";

const Instructor = () => {
  return (
    <div>
      <PageWrapper route={"instructor"} sideItem={instructor_SideItem}>
        <Outlet />
      </PageWrapper>
    </div>
  );
};

export default Instructor;
