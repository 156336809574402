import React from 'react';

import { useGlobalContext } from '../../../context/context';
import { useParams } from 'react-router-dom';
import useFetch from '../../../hooks/useFetch';
import AvatarWidget from '../../../components/global/AvatarWidget';
import { formatDate } from '../../student/components/StudentProfile';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { useEffect } from 'react';

const InstructorStudentProfile = () => {
  const { id } = useParams();
  const currentAccount = JSON.parse(localStorage.getItem('account'));
  const { levels, tracks, stages, workspace } = useGlobalContext();
  const [isLoading, setIsLoading] = useState(false);
  const [certFile, setCertFile] = useState(null);
  const { data: student } = useFetch(`/students/${id}`, workspace);
  const { data: cert, refetch } = useFetch(
    `/admin/profiles/certificate/${id}`,
    workspace
  );

  useEffect(() => {
    setCertFile(cert);
  }, [cert]);

  const handleUpload = async (file) => {
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append('certificate', file);

      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/${workspace}/admin/profiles/certificate/${id}`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${currentAccount.access_token}`,
          },
          body: formData,
        }
      );

      if (res.ok) {
        setCertFile(file);
        toast.success('Upload Successful');
      } else {
        toast.error(res.detail[0].msg);
      }
    } catch (err) {
      console.error('Error:', err);
      toast.error('Upload Failed');
    } finally {
      setIsLoading(false);
      refetch();
    }
  };

  const handleCert = async () => {
    if (certFile) {
      window.open(process.env.REACT_APP_BASE_URL + cert.url);
    } else {
      const fileInput = document.getElementById('certificateInput');
      if (fileInput) {
        fileInput.click();
      }
    }
  };

  const handleDeleteCert = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/${workspace}/admin/profiles/certificate/${id}`,
        {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${currentAccount.access_token}`,
          },
        }
      );

      if (res.ok) {
        setCertFile('');
        toast.success('Delete Successful');
      } else {
        toast.error(res.detail[0].msg);
      }
    } catch (err) {
      console.error('Error:', err);
      toast.error('Delete Failed');
    } finally {
      refetch();
    }
  };

  return (
    <>
      {!student ? (
        <div className="flex justify-center items-center h-[90vh] w-full">
          <h1>Please wait...</h1>
        </div>
      ) : (
        <>
          <div className="flex justify-between items-center">
            <h1>Profile</h1>
          </div>

          <div className="bg-white rounded-lg px-8 py-4 mt-6">
            <div className="flex space-x-2 md:space-x-12">
              <h1 className={`${'text-blue-ribbon-500'} cursor-pointer`}>
                Personal details
              </h1>
            </div>
            <div className="md:flex items-center md:space-x-12 mt-8">
              <div className="img relative cursor-pointer">
                <AvatarWidget
                  avatarUrl={student?.profile?.avatar}
                  w={'200px'}
                  h={'200px'}
                  text={'100px'}
                  owner={student?.profile}
                />{' '}
              </div>
              <div className="right space-y-4">
                <h1 className="text-lg md:text-2xl">
                  {student?.profile?.first_name +
                    ' ' +
                    student?.profile?.last_name}
                </h1>
                <p>
                  <span className="text-sm mr-3 text-[grey]">Track:</span>
                  <span>{tracks[student?.student?.track_id]?.title}</span>
                </p>
                <p>
                  <span className="text-sm mr-3 text-[grey]">Level:</span>
                  <span className="text-[#D4AF37]">
                    {levels[student?.student?.level_id]?.title}
                  </span>
                </p>
                <p>
                  <span className="text-sm mr-3 text-[grey]">Stage:</span>
                  <span>{stages[student?.student?.stage_id]?.title}</span>
                </p>
              </div>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-4 mt-8 overflow-auto">
              <div className="left">
                <ul className="space-y-4">
                  <li className="border-b pb-1">Username</li>
                  <li className="border-b pb-1">Email Address</li>
                  <li className="border-b pb-1">Date of Birth</li>
                  <li className="border-b pb-1">Gender</li>
                  <li className="border-b pb-1">Phone Number</li>
                </ul>
              </div>

              <div className="right md:col-span-3">
                <ul className="space-y-4">
                  <li className="border-b pb-1 pl-5">
                    {student?.account?.username}
                  </li>
                  <li className="border-b pb-1 pl-5">
                    {student?.email ?? 'example@examplemail.com'}
                  </li>
                  <li className="border-b pb-1 pl-5">
                    {formatDate(student?.profile?.date_of_birth)}
                  </li>
                  <li className="border-b pb-1 pl-5">
                    {firstWordCapitalized(student?.profile?.gender)}
                  </li>
                  <li className="border-b pb-1 pl-5">
                    {student?.phone ?? '00000000000'}
                  </li>
                </ul>
              </div>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-4 mt-8">
              <div className="left">
                <ul className="space-y-4">
                  <li className="border-b pb-1">Employement</li>
                  <li className="border-b pb-1">Education</li>
                </ul>
              </div>
              <div className="right md:col-span-3">
                <ul className="space-y-4">
                  <li className="border-b pb-1 pl-5 font-bold">
                    {firstWordCapitalized(student?.info?.employment_status)}
                  </li>
                  <li className="border-b pb-1 pl-5 font-bold">
                    {firstWordCapitalized(student?.info?.qualification)}
                  </li>
                </ul>
              </div>
            </div>
            <div className="flex items-center space-x-4">
              <button
                className="text-sm text-white bg-blue-ribbon-500 px-4 py-2 rounded-lg mt-4 relative"
                type="button"
                onClick={handleCert}
              >
                <i
                  className={`fa-solid ${
                    certFile ? 'fa-certificate' : 'fa-upload'
                  } mr-2`}
                ></i>
                <span>{certFile ? 'View' : 'Upload'}</span>
                Certificate
                <input
                  type="file"
                  id="certificateInput"
                  accept=".jpg, .jpeg, .png, .pdf"
                  style={{ display: 'none' }}
                  onChange={(e) => {
                    if (e.target.files.length > 0) {
                      handleUpload(e.target.files[0]);
                    }
                  }}
                />
              </button>
              <button
                className={`text-sm text-white bg-red-500 ${
                  !certFile && 'opacity-40'
                } px-4 py-2 rounded-lg mt-4`}
                type="button"
                disabled={!certFile}
                onClick={handleDeleteCert}
              >
                <i className="fa-solid fa-trash mr-2"></i>
                Delete Certificate
              </button>
            </div>
            {isLoading && (
              <div className="absolute loader bg-black opacity-70 top-0 right-0 left-0 bottom-0 flex flex-col justify-center items-center">
                <div className="w-10 h-10 border-r-2 border-t-4 border-b-6 border-dotted border-gray-500 rounded-full animate-spin"></div>
                <p className="text-white z-40">Uploading.. Please wait</p>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default InstructorStudentProfile;

const firstWordCapitalized = (word) => {
  return word?.slice(0, 1).toUpperCase() + word?.slice(1);
};
