import React from "react";
import { Outlet } from "react-router-dom";

import PageWrapper from "../../layouts/PageWrapper";
import { admin_SideItem } from "../../layouts/sidebar_data";

const Admin = () => {
  return (
    <div>
      <PageWrapper route={"admin"} sideItem={admin_SideItem}>
        <Outlet />
      </PageWrapper>
    </div>
  );
};

export default Admin;
