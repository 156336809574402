import React, { useEffect } from 'react';
import './App.css';
import Loader from './components/global/Loader';
import JoinCohortButton from './context/JoinCohortButton';
import { useGlobalContext } from './context/context';
import Routes from './routes/Routes';
import { ToastContainer } from 'react-toastify';

function App() {
  const { setUserProfile, hideCohortJoinModal } = useGlobalContext();

  useEffect(() => {
    const localProfile = JSON.parse(localStorage.getItem('local-profile'));
    if (localProfile) {
      setUserProfile(localProfile);
    }
  }, []);

  return (
    <div className="App">
      <Routes />
      <Loader />
      <ToastContainer />
      {hideCohortJoinModal ? <JoinCohortButton /> : null}
    </div>
  );
}

export default App;
