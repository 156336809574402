export const getStatusColor = (status) => {
  switch (status) {
    case 'open':
      return 'rgb(91, 206, 91)';
    case 'closed':
      return 'rgb(244, 136, 131)';
    case 'restricted':
      return 'gray';
    case 'readonly':
      return 'black';
    default:
      return 'black';
  }
};
