import React, { useState } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import Data from './Data';
import { useGlobalContext } from '../../../../context/context';
import { capitalizeFirstLetter } from '../../../../utils/capitalizeFirstLetter';
import { dashboardColors } from './dashboardColor';

ChartJS.register(ArcElement, Tooltip, Legend);

const gender_data = {
  labels: ['Male', 'Female', 'Other'],
  datasets: [
    {
      label: 'Total',
      data: [2667, 1779],
      backgroundColor: dashboardColors,
    },
  ],
};

const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
};

function TrainerCard({ trainers, trainerLength }) {
  const [selectValue, setSelectValue] = useState('');
  const { tracks, levels } = useGlobalContext();

  const track_data = {
    labels: Object.keys(tracks || {}).map((key) => tracks[key]?.title),
    datasets: [
      {
        label: 'Total',
        data: [...Object.values(trainers?.tracks || {})],
        backgroundColor: dashboardColors,
      },
    ],
  };
  const level_data = {
    labels: Object.keys(levels || {}).map((key) => levels[key]?.title),
    datasets: [
      {
        label: 'Total',
        data: [...Object.values(trainers?.levels || {})],
        backgroundColor: dashboardColors,
      },
    ],
  };

  return (
    <div className="h-[100%] w-full bg-white rounded-xl p-5">
      <div className="flex justify-between items-center">
        <h1>Trainers</h1>
        <select
          name=""
          id=""
          value={selectValue}
          onChange={(e) => setSelectValue(e.target.value)}
          className="text-blue-ribbon-500 px-3 py-1 rounded-md border border-blue-ribbon-300"
        >
          <option value="track">Track</option>
          <option value="level">Level</option>
          <option value="gender">Gender</option>
        </select>
      </div>
      <h1 className="my-2 text-gray-500">{trainerLength} trainers</h1>
      {selectValue === 'gender' ? (
        <>
          <div className="w-64 h-64 mx-auto">
            <Pie data={gender_data} options={options}></Pie>
          </div>
          <div className="grid grid-cols-2 gap-3 mt-4">
            {Data.slice(0, 2).map((e, idx) => (
              <div className="flex items-center space-x-2" key={idx}>
                <div
                  className={`h-3 w-3 rounded`}
                  style={{
                    backgroundColor:
                      gender_data.datasets[0].backgroundColor[idx],
                  }}
                ></div>
                <h1 className="text-[12px]" key={idx}>
                  {idx === 0 ? 'Male' : 'Female'} - {e.total}
                </h1>
              </div>
            ))}
          </div>
        </>
      ) : selectValue === 'level' ? (
        <>
          <div className="w-64 h-64 mx-auto">
            <Pie data={level_data} options={options}></Pie>
          </div>
          <div className="grid grid-cols-2 gap-3 mt-4">
            {Object.keys(levels || {}).map((e, idx) => (
              <div className="flex items-center space-x-2" key={idx}>
                <div
                  className={`h-3 w-3 rounded`}
                  style={{
                    backgroundColor:
                      level_data.datasets[0].backgroundColor[idx],
                  }}
                ></div>
                <h1 className="text-[12px]" key={idx}>
                  {levels[e]?.title} - {trainers?.levels?.[e] || 0}
                </h1>
              </div>
            ))}
          </div>
        </>
      ) : (
        <>
          <div className="w-64 h-64 mx-auto">
            <Pie data={track_data} options={options}></Pie>
          </div>
          <div className="grid grid-cols-2 gap-3 mt-4">
            {Object.keys(tracks || {}).map((e, idx) => (
              <div className="flex items-center space-x-2" key={idx}>
                <div
                  className={`h-3 w-3 rounded`}
                  style={{
                    backgroundColor:
                      track_data.datasets[0].backgroundColor[idx],
                  }}
                ></div>
                <h1 className="text-[12px]" key={idx}>
                  {capitalizeFirstLetter(tracks[e]?.name)} -{' '}
                  {trainers?.tracks?.[e] || 0}
                </h1>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default TrainerCard;
