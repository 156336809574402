import React from "react";
import { Success } from "../../assets";
import { Link } from "react-router-dom";

const PopUp = (props) => {
  return (
    <div className="bg-[#404040]/10 fixed z-[50] top-0 left-0 h-screen w-full overflow-y-hidden flex justify-center items-center">
      <div className="bg-white py-10 rounded-2xl lg:w-[420px] z-50 w-10/12 flex flex-col justify-center items-center mx-auto text-center fixed shadow-lg">
        <img src={Success} className="w-20" alt="success" />
        <h2 className="text-[24px] font-medium">{props.title}</h2>
        <p className="font-medium my-2 w-3/4">{props.message1}</p>
        <p className="font-medium my-2 w-3/4">{props.message2}</p>
        <div className="flex gap-2 my-2">
          <button className="border text-[#0D6EFD] border-blue-ribbon-500 rounded-lg px-4 py-2">
            Post Task
          </button>
          <Link
            to={props.path}
            className="bg-blue-ribbon-500 text-white rounded-lg px-8 py-2"
          >
            {props.btnSuccess2}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default PopUp;
