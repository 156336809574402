import React from "react";
import AdminTrainer from "./AminTrainer/AdminTrainer";

function AdminTrainerList() {
  return (
    <div>
      <AdminTrainer />
    </div>
  );
}

export default AdminTrainerList;
