import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useFetch from '../../../hooks/useFetch';
import Pagination from '../Pagination';
import { useGlobalContext } from '../../../context/context';

function DraftTask({ tracks }) {
  let navigate = useNavigate();
  const [currentPosts, setCurrentPosts] = useState([]);
  const [filterData, setFilterData] = useState('All');
  const [pageData, setPageData] = useState({
    startIndex: 1,
    endIndex: 5,
  });
  const { workspace } = useGlobalContext();
  const { data: tasks, isPending, error } = useFetch('/tasks', workspace);

  const filterlabel = [
    'All',
    'UI/UX',
    'Frontend',
    'Backend',
    'Android',
    'Project Management',
    'CAD',
    'Digital Marketing',
  ];

  return (
    <div>
      <div>
        {tracks && (
          <div className="my-6 flex items-center gap-4 text-blue-500 overflow-x-auto md:w-full md:overflow-x-hidden whitespace-nowrap">
            {filterlabel.map((label, i) => (
              <p
                className="text-base cursor-pointer"
                key={i}
                onClick={(e) => setFilterData(e.target.innerText)}
              >
                {label}
              </p>
            ))}
          </div>
        )}

        <p className="text-base text-center font-normal"></p>

        <div className="overflow-x-scroll md:overflow-x-hidden">
          {isPending && <div className="text-sm">Loading.....</div>}
          {error && <div className="text-sm bg-red-500 p-6 ">{error}</div>}

          <table class="table-auto border-separate border-spacing-y-3 text-sm text-left text-gray-500 w-full">
            <caption class="caption-top">
              Showing {pageData.startIndex}-{pageData.endIndex} of{' '}
              {tasks && tasks.length} submissions
            </caption>
            <thead className="bg-blue-ribbon-50 rounded-md text-[#0D6EFD]">
              <tr className="">
                <th class="px-4 py-2">Stage</th>
                <th class="px-4 py-2">Task</th>
                <th class="px-4 py-2">Track</th>
                <th class="px-4 py-2">Level</th>
                <th class="px-4 py-2">Due Date</th>
                <th class="px-4 py-2">Status</th>
                <th class="px-4 py-2">Graded</th>
              </tr>
            </thead>
            <tbody>
              {currentPosts &&
                currentPosts
                  .filter((data) => {
                    if (filterData === 'All') {
                      return data;
                    } else if (
                      data.track
                        .toLowerCase()
                        .includes(filterData.toLowerCase())
                    ) {
                      return data;
                    }
                    return data;
                  })
                  .map((data) => {
                    const {
                      id,
                      stage,
                      taskTitle,
                      track,
                      level,
                      dueDate,
                      status,
                      points,
                    } = data;
                    return (
                      <tr
                        key={id}
                        className="bg-white rounded-md hover:bg-blue-ribbon-50 cursor-pointer border-b mb-4"
                        onClick={() => navigate(`/admin/task/${id}`)}
                      >
                        <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                          {stage}
                        </td>
                        <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                          {taskTitle}
                        </td>
                        <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                          {track}
                        </td>
                        <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                          {level}
                        </td>
                        <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                          {dueDate}
                        </td>
                        <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                          {status}
                        </td>
                        <td class="text-sm text-gray-900 font-light px-6 py-4 whitespace-nowrap">
                          {points}
                        </td>
                      </tr>
                    );
                  })}
            </tbody>
          </table>
        </div>
        <Pagination
          data={tasks}
          setNewData={setCurrentPosts}
          setPageData={setPageData}
        />
      </div>
    </div>
  );
}

export default DraftTask;
