import { useEffect, useState } from 'react';
import { CiMenuKebab } from 'react-icons/ci';
import { capitalizeFirstLetter } from '../../../../utils/capitalizeFirstLetter';
import { getStatusColor } from '../../../../utils/getStatusColor';

function WorkspaceTable({ columns, data, setFormData, setAddWorkspaceForm }) {
  const [showMenu, setShowMenu] = useState(null);

  useEffect(() => {
    const handleClickOutside = () => {
      if (showMenu !== null) {
        setShowMenu(null);
      }
    };

    window.addEventListener('click', handleClickOutside);

    return () => {
      window.removeEventListener('click', handleClickOutside);
    };
  }, [showMenu]);

  const handleDeleteWorkspace = (id) => {
    console.log(id);
  };

  return (
    <div className="overflow-x-auto" onClick={() => setShowMenu(null)}>
      {data?.length > 0 ? (
        <table className="min-w-full border-separate border-spacing-y-2">
          <thead className="bg-[#eef1f5]">
            <tr>
              {columns?.map((column, index) => (
                <th
                  key={index}
                  className={`${
                    index === 0 ? 'rounded-l-md' : ''
                  } px-6 py-3 text-left text-xs font-medium text-blue-ribbon-500 tracking-wider`}
                >
                  {column.header}
                </th>
              ))}
              <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-900 w-10 rounded-r-md">
                <CiMenuKebab />
              </td>
            </tr>
          </thead>
          <tbody className="bg-white ">
            {data?.map((row, rowIndex) => (
              <tr key={rowIndex} className="">
                {columns?.map((column, colIndex) => (
                  <td
                    key={colIndex}
                    className={`${
                      colIndex === 0 ? 'rounded-l-md' : ''
                    } px-6 py-3 whitespace-nowrap text-sm text-gray-900`}
                  >
                    {column.type === 'image' ? (
                      <img
                        src={row[column.accessor]}
                        alt=""
                        className="w-10 h-10"
                      />
                    ) : column?.type === 'status' ? (
                      <span
                        className="px-4 py-1 rounded-md text-white"
                        style={{
                          backgroundColor: getStatusColor(row[column.accessor]),
                        }}
                      >
                        {capitalizeFirstLetter(row[column.accessor])}
                      </span>
                    ) : column?.type === 'date' || column?.type === 'datetime' ? (
                      <span>{new Date(row[column.accessor]).toLocaleDateString()}</span>
                    ) : (
                      <span>{row[column.accessor]}</span>
                    )}
                  </td>
                ))}
                <td className="px-6 py-3 whitespace-nowrap text-sm text-gray-900 w-10 rounded-r-md">
                  <CiMenuKebab
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowMenu(() =>
                        showMenu === rowIndex ? null : rowIndex
                      );
                    }}
                    className="text-xl"
                  />
                </td>
                {showMenu === rowIndex ? (
                  <ul className="absolute w-24 bg-white shadow rounded-md right-20">
                    <li
                      className="px-4 py-3 hover:bg-gray-200 rounded-t-md"
                      onClick={() => {
                        setAddWorkspaceForm(true);
                        setFormData(row);
                      }}
                    >
                      Edit
                    </li>
                    <li
                      className="px-4 py-3 hover:bg-gray-200 text-red-500 rounded-b-md"
                      onClick={() => handleDeleteWorkspace(row.id)}
                    >
                      Delete
                    </li>
                  </ul>
                ) : null}
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="h-96 w-full flex justify-center items-center">
          <h1>No workspace created</h1>
        </div>
      )}
    </div>
  );
}

export default WorkspaceTable;
