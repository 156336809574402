import React, { useEffect, useState } from 'react';
import Style from './Login.module.css';
import { Logo, Lock, Mail } from '../../assets';
import { Link } from 'react-router-dom';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import LoginTheme from '../../components/authentication/AuthTheme';
import { useNavigate } from 'react-router-dom';
import GetProfileModal from './components/GetProfileModal';
import { useGlobalContext } from '../../context/context';
import { toast } from 'react-toastify';
import { fetchData } from '../../hooks/api';
import { useProfileService } from '../../services/profile.service';

const Login = () => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    setAccountData,
    workspace,
    setUserData,
    setWorkspace,
    setUserProfile,
  } = useGlobalContext();
  const [showpassword, setShowpassword] = useState(false);
  const [profileForm, setProfileForm] = useState(false);
  const [loginDetail, setLoginDetail] = useState({
    username: '',
    password: '',
  });

  const [errorMessage, setErrorMessage] = useState({
    msg: '',
    status: false,
  });
  const { fetchProfile } = useProfileService();

  const navigate = useNavigate();

  const toggle = () => {
    setShowpassword(!showpassword);
  };

  const handleLoginDetail = ({ target }) => {
    setLoginDetail({ ...loginDetail, [target.name]: target.value });
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    const abortCont = new AbortController();
    setIsLoading(true);
    const { username, password } = loginDetail;

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/login`, {
        signal: abortCont.signal,
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: new URLSearchParams({
          username: username,
          password: password,
        }).toString(),
      });

      if (!response.ok) {
        const errorData = await response.json();

        if (Array.isArray(errorData)) {
          setErrorMessage({ msg: errorData.detail[0].msg, status: true });
          toast.error(errorData.detail[0].msg);
          setTimeout(() => {
            setErrorMessage({
              status: false,
            });
          }, 10000);
        } else {
          setErrorMessage({ msg: errorData.detail, status: true });
          toast.error(errorData.detail);
          setTimeout(() => {
            setErrorMessage({
              status: false,
            });
          }, 10000);
        }
        throw new Error(errorData);
      }

      const data = await response.json();
      const ws = data.account?.currentWorkspace?.slug;
      localStorage.setItem('workspace_slug', ws);
      setWorkspace(ws);
      setAccountData(data);
      localStorage.setItem('account', JSON.stringify(data));

      const user = await fetchData('/users/me', ws);
      setUserData(user);
      localStorage.setItem('user', JSON.stringify(user));

      const profile = await fetchProfile(ws);
      setUserProfile(profile);
      localStorage.setItem('local-profile', JSON.stringify(profile));

      const currentUser = JSON.parse(localStorage.getItem('user'));
      if (currentUser) {
        setIsLoading(false);
        if (currentUser.type === 'learner') {
          navigate('/student/dashboard');
        } else if (currentUser.type === 'trainer') {
          navigate('/instructor/dashboard');
        } else {
          navigate('/admin/dashboard');
        }
      }
    } catch (error) {
      if (error.name === 'AbortError') {
        console.error(error.name);
      } else {
        console.error('Error logging in user:', error);
        setIsLoading(false);
      }
    }
    return () => abortCont.abort();
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const token_expired = params.get('token_expired');
    if (token_expired === 'true') {
      toast.error('Session expired. Please login again to continue');
    }
  }, []);

  return (
    <LoginTheme>
      <div className="relative w-full flex flex-col justify-center h-full">
        <div className="flex flex-col gap-4 justify-center items-center">
          <img src={Logo} alt="" className={`bg-white rounded-lg p-3 m-auto`} />
          <h2 className={`text-lg my-4 text-center`}>Log in to dashboard</h2>
        </div>

        {errorMessage?.status && (
          <div className="flex justify-center text-red-500">
            <p className="error-message">{errorMessage?.msg}</p>
          </div>
        )}
        <div className={`${Style.login_text} w-full md:px-10 mt-[1.4rem]`}>
          <form action="" onSubmit={handleLogin}>
            <div className="mb-4 flex flex-col gap-2">
              <label htmlFor="email">Email</label>
              <div className="relative flex flex-row items-center">
                <span className="absolute left-3">
                  <img src={Mail} alt="" />
                </span>
                <input
                  type="email"
                  name="username"
                  value={loginDetail.username}
                  onChange={handleLoginDetail}
                  placeholder="Type your email"
                  className="border border-gray-400 rounded-lg h-[54px] outline-none pr-3 pl-10 w-full"
                  required
                />
              </div>
            </div>

            <div className="mb-4 flex flex-col gap-2">
              <label htmlFor="email">Password</label>
              <div className="relative flex items-center justify-between bg-white px-3 rounded-md border border-gray-400">
                <div className="flex space-x-3 items-center w-full">
                  <span className="">
                    <img src={Lock} alt="lock" />
                  </span>
                  <input
                    type={showpassword === false ? 'password' : 'text'}
                    name="password"
                    value={loginDetail.password}
                    onChange={handleLoginDetail}
                    placeholder="Type your password"
                    className="outline-none text-black w-full"
                    required
                  />
                </div>
                {showpassword === false ? (
                  <FaEye onClick={toggle} />
                ) : (
                  <FaEyeSlash onClick={toggle} />
                )}
              </div>
            </div>

            <div className="flex items-center justify-between mb-8">
              <div className="flex justify-center items-center">
                <input
                  type="checkbox"
                  name=""
                  id=""
                  className="border border-gray-700"
                />
                <span className="pl-2">Remember me</span>
              </div>
              <p className={`${Style.login_link}`}>
                <Link to={'/forgot-password'}>Forgot password?</Link>
              </p>
            </div>

            <div>
              <input
                type="submit"
                value="Log In"
                className={`bg-blue-500 text-white w-full h-[54px] text-center cursor-pointer`}
              />
            </div>

            <div className="mt-5">
              <p className="text-center">
                Don't have an account?
                <span
                  className={`${Style.login_link} create pl-2 hidden md:block`}
                >
                  <Link to={'/signup'}>Create account</Link>
                </span>
              </p>
              <p className={`${Style.login_link} pl-2 text-center md:hidden`}>
                <Link to={'/signup'}>Create account</Link>
              </p>
            </div>
          </form>
          {isLoading && (
            <div className="absolute loader bg-black opacity-70 top-0 right-0 left-0 bottom-0 flex justify-center items-center">
              <div className="w-10 h-10 border-r-2 border-t-4 border-b-6 border-dotted border-gray-500 rounded-full animate-spin"></div>
            </div>
          )}
        </div>
      </div>
      <GetProfileModal
        profileForm={profileForm}
        setProfileForm={setProfileForm}
      />
    </LoginTheme>
  );
};

export default Login;
