export const getCurrentStage = (stages, students) => {
  return Math.max(
    ...Object.values(students || [])?.map(
      (s) => stages[s.student.stage_id]?.order || 0
    )
  );
};

export const getCountOfStages = (stages) => {
  return Math.max(...Object.values(stages).map((s) => s.order));
};
