import React from 'react';
import useFetch from '../../../hooks/useFetch';
import { useParams } from 'react-router';
import { DueDate, Calender } from '../../../assets';
import { Markup } from 'interweave';
import { useGlobalContext } from '../../../context/context';

import AvatarWidget from '../AvatarWidget';
import { RefetchButton } from '../../../context/Refetch';
import LinkPreview from '../LinkPreview';

import TaskDetailsStudent from './TaskDetailsStudent';

function TaskDetailsTASK({ route }) {
  const { id } = useParams();
  const { stages, levels, tracks, workspace } = useGlobalContext();
  const {
    data: task,
    isPending,
    error,
    refetch: refetchTask,
  } = useFetch(`/tasks/${id}`, workspace);

  return (
    <div>
      {isPending && <div className="text-sm">Loading.....</div>}
      {error && (
        <div className="text-sm bg-red-500 p-6 text-white mt-5">{error}</div>
      )}

      {task && (
        <div className="grid md:grid-cols-3 gap-4 mt-4">
          <div className="md:col-span-2 text-2xl bg-white rounded-xl p-5">
            <div className="w-[97%] mx-auto border-b border-grey-400 pb-3 flex flex-col gap-4">
              <div className="flex flex-col gap-1 md:flex-row justify-between md:items-center">
                <h2 className="text-lg">{task.title}</h2>
                <p className="text-sm text-[#808080]">
                  {tracks[task?.track_id]?.title} -{' '}
                  {levels[task?.level_id]?.title}
                </p>
              </div>

              <div className="flex justify-between items-center">
                <div className="flex gap-4 items-items">
                  <div className="flex gap-2 items-center">
                    <div className="rounded-full">
                      <AvatarWidget
                        avatarUrl={task?.owner?.profile?.avatar}
                        w={'25px'}
                        h={'25px'}
                        text={'8px'}
                        owner={task?.owner?.profile}
                      />
                    </div>
                    <p className="text-sm text-[#808080]">
                      {task?.owner?.profile?.first_name +
                        ' ' +
                        task?.owner?.profile?.last_name}
                    </p>
                  </div>
                  <div className="flex gap-2 items-center">
                    <img src={Calender} alt="" />
                    <p className="text-sm text-[#808080]">
                      {formatDateString(task?.created_at)}
                    </p>
                  </div>
                </div>
                <p className="text-sm text-gray-500">
                  {stages[task?.stage_id]?.title}
                </p>
              </div>

              <div className="flex flex-col gap-1 md:flex-row justify-between md:items-center">
                <div className="flex gap-2 items-center">
                  <img src={DueDate} alt="clock" />
                  <p className="text-sm text-[#808080]">
                    {calculateDaysDifferenceFromToday(task?.due_date)}
                  </p>
                </div>
                <p className="text-sm text-[#808080]">{task.points} points</p>
              </div>
            </div>

            <div className="w-[97%] mx-auto border-b border-grey-400 py-3 flex flex-col gap-2">
              <Markup
                className="text-sm text-black min-h-[8rem]"
                content={task.description}
              />
            </div>
            <div className="grid grid-cols-2 gap-8 text-sm w-[97%] mx-auto mt-5">
              <div className="left">
                <h1 className="font-bold">Links</h1>
                <ul className="space-y-2 mt-3">
                  {task.submission_links.map((e, idx) => {
                    return (
                      <li
                        key={idx}
                        className="flex items-center space-x-1 overflow-auto"
                      >
                        <p>{idx + 1 + '. '}</p>

                        <div className="">{e}</div>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="left">
                <h1 className="font-bold">Resources</h1>
                <ul className="space-y-2 mt-3">
                  {task.resources.map((e, idx) => {
                    return (
                      <li
                        key={idx}
                        className={`px-2 py-1 rounded-md overflow-auto`}
                      >
                        <a
                          href={e}
                          target="_blank"
                          rel="noreferrer"
                          className="text-blue-500"
                        >
                          {e}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>

          {route === 'instructor' || route === 'admin' ? (
            <div className="md:col-span-1 text-2xl bg-white rounded-xl p-5 self-start w-full">
              <div className="flex items-center justify-between">
                <p className="text-lg">Status</p>
                {task.status === 'posted' ? (
                  <p className="text-sm text-green-500">Posted</p>
                ) : (
                  <p className="text-sm text-red-500">Not Posted</p>
                )}
              </div>

              <div className="flex justify-start mt-5">
                <h1 className="border border-blue-ribbon-500 text-blue-ribbon-500 text-sm px-4 py-2 rounded-lg">
                  Posted
                </h1>
              </div>
              <ul className="mt-8 space-y-3">
                <h1 className="text-sm text-gray-500">Collaborator(s)</h1>
                {task?.collabs?.map((collab, idx) => {
                  return (
                    <li
                      key={idx}
                      className="text-sm text-gray-500 flex items-center space-x-2"
                    >
                      <AvatarWidget
                        avatarUrl={collab?.user?.profile?.avatar}
                        w={'45px'}
                        h={'45px'}
                        text={'12px'}
                        owner={collab?.user?.profile}
                      />
                      <p>
                        {collab?.user?.profile?.first_name +
                          ' ' +
                          collab?.user?.profile?.last_name}
                      </p>
                    </li>
                  );
                })}
              </ul>
            </div>
          ) : (
            <TaskDetailsStudent task={task} />
          )}
        </div>
      )}
      <RefetchButton name="detailTaskRefetch" functions={[refetchTask]} />
    </div>
  );
}

export default TaskDetailsTASK;

function formatDateString(dateString) {
  const dateObject = new Date(dateString);
  const formattedDate = dateObject.toLocaleDateString('en-US', {
    month: 'long',
    day: 'numeric',
  });
  return formattedDate;
}

function calculateDaysDifferenceFromToday(dueDateString) {
  const dueDate = new Date(dueDateString);
  const currentDate = new Date();

  const timeDifference = dueDate - currentDate;
  if (timeDifference < 0) {
    return 'Overdue';
  } else if (timeDifference < 86400000) {
    return 'Due today';
  } else {
    const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
    return `Due in ${daysDifference} day${daysDifference !== 1 ? 's' : ''}`;
  }
}
