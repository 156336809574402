import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { AiOutlinePlus } from 'react-icons/ai';
import { Outlet } from 'react-router-dom';

const TaskHeader = ({ taskroute, draftroute, addtaskroute }) => {
  // const id = 0;

  return (
    <div>
      <div className="flex flex-col gap-6 md:flex-row md:items-center md:justify-between mb-6">
        <div className="flex items-center gap-9">
          <NavLink
            to={taskroute}
            className={({ isActive }) =>
              isActive
                ? 'text-xl font-medium text-[#303030] border-b border-[#303030]'
                : 'text-blue-ribbon-500'
            }
          >
            Task
          </NavLink>
          <NavLink
            // to={draftroute}
            to={''}
            className={({ isActive }) =>
              isActive
                ? 'text-xl font-medium text-[#303030] border-b border-[#303030]'
                : // : 'text-blue-ribbon-500'
                  ''
            }
          >
            Drafts
          </NavLink>
        </div>
        <div className="flex justify-start">
          <Link
            to={addtaskroute}
            className="flex items-center gap-2 bg-blue-ribbon-400 text-white px-4 py-2 rounded-md text-base"
          >
            <AiOutlinePlus className="text-base" />
            <p>Add New Task</p>
          </Link>
        </div>
      </div>

      <Outlet />
    </div>
  );
};

export default TaskHeader;
