import React, { useState } from "react";

import CurriculumPageWrapper from "../../../components/curriculum/CurriculumPageWrapper";
import NotAvailable from "../../../components/global/NotAvailable";
import { useGlobalContext } from "../../../context/context";

const StudentCurriculum = () => {
  const [studentCurr, setStudentCurri] = useState(true);
  const { versionOne } = useGlobalContext();

  const CreateNew = () => {
    setStudentCurri(studentCurr);
  };

  if (versionOne) {
    return <NotAvailable />;
  }

  return (
    <>
      <div>
        <CurriculumPageWrapper
          studentCurr={studentCurr}
          setStudentCurri={setStudentCurri}
          CreateNew={CreateNew}
        >
          <h1>Curriculum</h1>
        </CurriculumPageWrapper>
      </div>
    </>
  );
};

export default StudentCurriculum;
