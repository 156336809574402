import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Logo } from '../../assets';
import AuthTheme from '../../components/authentication/AuthTheme';
import ForgotPasswordModal from './components/ForgotPasswordModal';

const ForgotPassword = () => {
  const [isPending, setIsPending] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const handleForgotPassword = async (e) => {
    e.preventDefault();
    const abortCont = new AbortController();
    setIsPending(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/password-recovery`,
        {
          signal: abortCont.signal,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email: email,
          }),
        }
      );

      if (!response.ok) {
        const res = await response.json();
        toast.error(res.detail);
        throw new Error('Failed');
      }
      setIsPending(false);
      setShowModal(true);
    } catch (error) {
      if (error.name === 'AbortError') {
        setIsPending(false);
      } else {
        console.error('Error:', error);
        setIsPending(false);
      }
    } finally {
      abortCont.abort();
    }
  };

  return (
    <>
      <ForgotPasswordModal
        showModal={showModal}
        setShowModal={setShowModal}
        title={'Check your email'}
        body={`A 4-digit code has been sent to`}
        emails={`${email}`}
        button={'Okay'}
        font={<i className="fa-solid fa-envelope text-[#00BD56]"></i>}
      />

      <AuthTheme>
        <div className="h-full flex flex-col justify-center w-full">
          <div className="flex flex-col gap-[1.3rem] items-center text-center">
            <div className="w-20 h-20 rounded-lg bg-transparent shadow-md grid place-items-center">
              <img src={Logo} alt="kodecamp-logo" />
            </div>

            <p className="font-medium text-[28px] text-[#303030]">
              Forgot Password
            </p>

            <div className="w-2/5 flex text-white">
              <div className="flex w-full items-center">
                <p className="px-3 py-1 border-2 rounded-full bg-blue-ribbon-500">
                  1
                </p>
                <hr className="w-full" />
              </div>

              <div className="flex w-full items-center">
                <p className="px-3 py-1 rounded-full bg-[#808080]">2</p>
                <hr className="w-full" />
              </div>

              <div className="flex items-center">
                <p className="px-3 py-1 rounded-full bg-[#808080]">3</p>
              </div>
            </div>
          </div>

          <div className="px-10 text-gray-600">
            <p className="text-center mt-4 w-[82%] mx-auto leading-[22px]">
              Please enter your email address below for verification. A link
              will be sent to your email
            </p>

            <form
              onSubmit={handleForgotPassword}
              className="pt-6 flex flex-col gap-5"
            >
              <div>
                <label htmlFor="email" className="text-[#808080] text-[16px]">
                  Email
                </label>
                <br />
                <input
                  type="email"
                  name="email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Type your email address"
                  className="w-full px-3 h-[54px] border-[1.5px] rounded-lg mt-1 border-[#808080]"
                />
              </div>
              <button
                type="submit"
                className="bg-blue-ribbon-500 h-[54px] rounded-lg text-white"
              >
                {isPending ? 'Please wait...' : 'Send Link'}
              </button>
              <div className="flex text-sm items-center justify-center space-x-1">
                <p>Remember Password?</p>
                <p
                  className="text-blue-ribbon-500"
                  onClick={() => navigate('/')}
                >
                  Login
                </p>
              </div>
            </form>
          </div>
        </div>
      </AuthTheme>
    </>
  );
};

export default ForgotPassword;
