import React from 'react';
import { useGlobalContext } from '../../context/context';

function Loader() {
  const { isLoading } = useGlobalContext();
  return (
    <>
      {isLoading ? (
        <div className="absolute top-0 left-0 right-0 bottom-0 flex flex-col justify-center items-center z-50">
          <div className="bg-black opacity-40 absolute top-0 left-0 right-0 bottom-0"></div>
          <div className="load w-24 h-24 border-[14px] border-dashed rounded-full animate-spin"></div>
          <p>Please wait</p>
        </div>
      ) : null}
    </>
  );
}

export default Loader;
