import React, { useState } from "react";
import BlueDownArrow from "../../assets/icons/arrow-down-blue.svg";
import MenuDots from "../../assets/icons/Dots.svg";
import DeleteWeek from "./curriculum-components/DeleteWeek";
import UpdateCurriculum from "./curriculum-components/UpdateCurriculum";
import { WeeklyCurriculum } from "./curriculumData";
import CreateCurriculumWeek from "../createcurriculumweek/CreateCurriculumWeek";

const CurriculumPageWrapper = ({
  children,
  newWeek,
  newWeekAdmin,
  studentCurr,
}) => {
  const [show, setShow] = useState(-1);
  const [deletePop, setDeletePop] = useState(true);

  const showOption = (index) => {
    if (index === show) {
      setShow(-1);
      return;
    }
    setShow(index);
  };
  const [update, setUpdate] = useState(null);

  const updateOptions = (index) => {
    if (index === update) {
      setUpdate(null);
      return;
    }
    setUpdate(index);
  };

  return (
    <>
      {newWeek || newWeekAdmin || studentCurr ? (
        <div>
          {deletePop ? (
            <div
              onClick={() => show && showOption()}
              className="w-[100%] h-full flex flex-col justify-center items-center mt-[54px]"
            >
              <div className="w-[100%]">{children}</div>

              <ul className="w-[100%] mt-[28px]">
                {WeeklyCurriculum.map((curi, index) => {
                  return (
                    <li
                      key={index}
                      className={`${
                        show === index ? "pb-[24px]" : "pb-[0]"
                      } hover:shadow-[5px_5px_5px_5px_rgba(0.1,_0.1,_0.1,_0.1)] cursor-pointer mb-[24px] rounded-[12px] px-[24px]  w-[100%] bg-[#FFFFFF]`}
                    >
                      <div className="flex justify-between items-center w-[100%] h-[72px] bg-[#FFFFFF]">
                        <div
                          onClick={() => showOption(index)}
                          className=" flex justify-between items-center h-[72px] w-[98%]"
                        >
                          <p>{curi.week}</p>
                          <div className="flex items-center">
                            <button className="flex items-center h-[72px] border-[0] border-[0]">
                              <span className="pr-[14px] text-[#0D6EFD] text-[16px]">
                                {curi.weekTopic}
                              </span>
                              <img
                                className={`${
                                  show === index
                                    ? "rotate-[175deg]"
                                    : "rotate-[0deg]"
                                } w-[12px] h-[7.41px]`}
                                src={BlueDownArrow}
                                alt="down arrow"
                              />
                            </button>
                          </div>
                        </div>

                        <div className="flex items-center justify-center relative">
                          <img
                            onClick={() => updateOptions(index)}
                            className="pl-[32px]"
                            src={MenuDots}
                            alt="menu"
                          />
                          <div
                            className={` ${
                              update !== index ? "hidden" : "block"
                            } absolute top-[20px] right-[-24px] `}
                          >
                            <UpdateCurriculum
                              updateOptions={updateOptions}
                              index={index}
                              deletePop={deletePop}
                              setDeletePop={setDeletePop}
                            />
                          </div>
                        </div>
                      </div>

                      <div
                        className={`${
                          show === index ? "block" : "hidden"
                        }  bg-[#FFFFFF]  w-[100%]`}
                      >
                        <hr />
                        {curi.weeksSubTopics.map((subtopic, i) => {
                          return (
                            <div key={subtopic.id} className="">
                              <div className="w-[100%]">
                                <p className="mt-[24px] text-[#1C1C1C]">
                                  {subtopic.text}
                                </p>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          ) : (
            <DeleteWeek deletePop={deletePop} setDeletePop={setDeletePop} />
          )}
        </div>
      ) : (
        <CreateCurriculumWeek />
      )}
    </>
  );
};

export default CurriculumPageWrapper;
