import React from 'react';
// import { useLinkPreview } from 'get-link-preview';

function LinkPreview({ link }) {
  // const { loading, data } = useLinkPreview(`${link}`);

  const data = '';
  const loading = false;

  return (
    <a href={'https://www.' + data?.domain} target="_blank" rel="noreferrer">
      <div
        className={`${
          loading && 'animate-pulse'
        } border rounded-lg flex justify-start items-center space-x-4 h-[55px]`}
      >
        <div
          className="h-[100%] w-[60px] rounded-l-lg"
          style={{
            backgroundImage: `url(${data?.image})`,
            backgroundPosition: 'center',
            backgroundSize: 'center',
            backgroundRepeat: 'no-repeat',
          }}
        ></div>
        <div className="space-y-1 p-2 w-3/5">
          <h6 className="truncate">{data?.title}</h6>
          <p className="text-[12px] text-gray-500 truncate">
            {'https://www.' + data?.domain}
          </p>
        </div>
      </div>
    </a>
  );
}

export default LinkPreview;
