export const dashboardColors = [
  '#00BD56', // Green
  '#F9FD50', // Yellow
  '#0D6EFD', // Blue
  '#E4E4E4', // Light Gray
  '#86B6FE', // Light Blue
  '#FDFEC5', // Light Yellow
  '#FF5733', // Red
  '#C70039', // Dark Red
  '#900C3F', // Maroon
  '#581845', // Purple
  '#FFC300', // Bright Yellow
  '#DAF7A6', // Light Green
  '#FF33D4', // Pink
  '#33FFF6', // Cyan
  '#335FFF', // Dark Blue
  '#FF33A1', // Magenta
];
