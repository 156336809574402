import React, { useEffect, useState } from 'react';
import JoinCohortModal from '../../../components/joinCohort/JoinCohortModal';
import { useCohortJoin } from '../../../components/joinCohort/useCohortJoin';
import InternshipProgress from './AdminDashboard/InternshipProgress';
import Online from './AdminDashboard/Online';
import StudentCard from './AdminDashboard/StudentCard';
import StudentPerformance from './AdminDashboard/StudentPerformance';
import TrainerCard from './AdminDashboard/TrainerCard';
import { useGlobalContext } from '../../../context/context';
import { useAdminDashboardService } from '../../../services/admin.dashboard.service';
import { dashboardAnalysis } from '../../../utils/dashboardAnalysis';

const AdminDashboard = () => {
  const {
    hideCohortJoinModal,
    oldStdRegForm,
    setOldStdRegForm,
    workspace,
    stages,
  } = useGlobalContext();
  const {
    form,
    hasNewCohort,
    isSuccess,
    handleContinue,
    handleFormSubmit,
    handleCancelJoinWorkspace,
    availableCohorts,
    generatedLevels,
    generatedTracks,
    handleInputChange,
    cohortObj,
  } = useCohortJoin('admin');
  const { fetchDashboardData } = useAdminDashboardService();
  const accountData = JSON.parse(localStorage.getItem('account'));
  const [dashboardData, setDashBoardData] = useState([]);
  const [usersCount, setUsersCount] = useState({
    studentLength: 0,
    trainerLength: 0,
  });
  const [useAnalysis, setUseAnalysis] = useState({
    students: {},
    trainers: {},
    performance: [],
  });

  const getData = async () => {
    try {
      const dashboardResponse = await fetchDashboardData(workspace);
      setDashBoardData(dashboardResponse);
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
    }
  };

  useEffect(() => {
    getData();
  }, [workspace]);

  useEffect(() => {
    const { students, trainers, performance } =
      dashboardAnalysis(dashboardData);
    setUseAnalysis({ students, trainers, performance });

    const studentLength = dashboardData.students?.reduce(
      (acc, current) => acc + current.count,
      0
    );
    const trainerLength = dashboardData.trainers?.reduce(
      (acc, current) => acc + current.count,
      0
    );
    setUsersCount({ studentLength, trainerLength });
  }, [dashboardData]);

  useEffect(() => {
    if (accountData && accountData?.access_token) {
      const timeoutId = setInterval(() => {
        getData();
      }, 600000); // 600000 milliseconds = 10 minute

      return () => {
        // Clean up the timeout function when the component unmounts
        clearInterval(timeoutId);
      };
    }
  }, [accountData]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        getData();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [workspace]);

  return (
    <div>
      <h1 className="mb-4">Dashboard</h1>
      <div className="md:grid md:grid-cols-3 gap-6 space-y-4 md:space-y-0">
        <div className="">
          {
            <StudentCard
              students={useAnalysis.students}
              studentLength={usersCount.studentLength}
            />
          }
        </div>
        <div className="">
          <TrainerCard
            trainers={useAnalysis.trainers}
            trainerLength={usersCount.trainerLength}
          />
        </div>
        <div className="">
          <InternshipProgress />
        </div>
        <div className="col-span-2">
          <StudentPerformance
            performance={useAnalysis.performance}
            studentLength={usersCount.studentLength}
          />
        </div>
        <div className="">
          <Online />
        </div>
      </div>
      <JoinCohortModal
        hasNewCohort={hasNewCohort}
        hideCohortJoinModal={hideCohortJoinModal}
        handleCancelJoinWorkspace={handleCancelJoinWorkspace}
        setOldStdRegForm={setOldStdRegForm}
        oldStdRegForm={oldStdRegForm}
        handleInputChange={handleInputChange}
        handleFormSubmit={handleFormSubmit}
        form={form}
        availableCohorts={availableCohorts}
        generatedLevels={generatedLevels}
        generatedTracks={generatedTracks}
        isSuccess={isSuccess}
        handleContinue={handleContinue}
        cohortObj={cohortObj}
      />
    </div>
  );
};

export default AdminDashboard;
