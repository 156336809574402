import React, { useEffect, useState } from "react";
import { AiFillCalendar, AiOutlinePlus } from "react-icons/ai";
import { Link } from "react-router-dom";
import Modal from "./AddEditTaskModal";
import Calendar from "./Calendar";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function CalendarWidget({ modalObj, addView, route }) {
  const [monthVal, setMonthVal] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [mv, setMV] = useState(1);

  useEffect(() => {
    setMonthVal(months[mv].slice(0, 3));
  }, [mv]);

  return (
    <div>
      <div className="bg-white p-5 py-5 rounded-lg">
        <div className="flex items-center justify-between">
          <h1>Calendar</h1>
          <Link to={route}>
            <div className="flex items-center border border-blue-ribbon-500 px-4 py-1 rounded-md space-x-2">
              <h1 className="">{monthVal} </h1>
              <AiFillCalendar className="text-lg text-blue-ribbon-500" />
            </div>
          </Link>
        </div>
        <Calendar
          monthVal={monthVal}
          modalObj={modalObj}
          mv={mv}
          setMV={setMV}
        />
        <div className="flex justify-between items-center mt-5 w-[90%] mx-auto">
          <div className="flex items-center">
            <div className="h-4 w-4 bg-blue-ribbon-400 rounded-full mr-1"></div>
            <span>Task</span>
          </div>
          <div className="flex items-center">
            <div className="h-4 w-4 bg-green-400 rounded-full mr-1"></div>
            <span>Class</span>
          </div>
          <div className="flex items-center">
            <div className="h-4 w-4 bg-yellow-400 rounded-full mr-1"></div>
            <span>Meeting</span>
          </div>
        </div>
        {addView && (
          <div className="flex items-center space-x-3 mt-5">
            <button
              className="text-white bg-blue-ribbon-500 px-3 py-1 rounded-md text-sm flex items-center space-x-2"
              onClick={() => setShowModal(true)}
            >
              <span>Add new </span> <AiOutlinePlus />
            </button>
            <Link to={route}>
              <button className="text-blue-ribbon-500 border border-blue-ribbon-500 px-3 py-1 rounded-md text-sm">
                View all
              </button>
            </Link>
          </div>
        )}
      </div>
      <Modal
        modalObj={modalObj}
        showModal={showModal}
        setShowModal={setShowModal}
      />
    </div>
  );
}

export default CalendarWidget;
