import React from "react";
import { BiRefresh } from "react-icons/bi";

export function RefetchButton({ functions, name }) {
  return (
    <BiRefresh
      className="text-4xl bottom-10 right-10 fixed bg-blue-ribbon-500 text-white w-10 h-10 rounded-md shadow-md z-40 cursor-pointer"
      onClick={() => {
        functions.forEach((func) => func());
      }}
    />
  );
}
