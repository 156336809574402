import { getSubmissionScore } from "./getSubmissionScore";

const MAXSCORE = 100;
const CUTOFF = 75;

export const scoreColor2 = (task) => {
  if (task?.submissions?.length === 0) {
    return "text-red-500";
  } else if (
    task?.submissions[task?.submissions.length - 1]?.status === "graded"
  ) {
    if (percentScore(task) < CUTOFF) {
      return "text-red-500";
    }
    return "text-green-500";
  } else {
    return "text-yellow-500";
  }
};

export const scoreColor = (submissions) => {
  if (submissions?.length === 0) {
    return "text-red-500";
  } else if (submissions[submissions?.length - 1]?.status === "graded") {
    return "text-green-500";
  } else {
    return "text-yellow-500";
  }
};

export const scoreText = (submissions) => {
  if (submissions?.length === 0) {
    return "Not Submitted";
  } else if (submissions[submissions?.length - 1]?.status === "graded") {
    return "Graded";
  } else {
    return "Submitted";
  }
};

export const promoColor = (task, student, stages) => {
  if (percentScore(task) < CUTOFF) {
    return "text-red-500";
  } else if (stages[student?.stage_id]?.order > stages[task?.stage_id]?.order) {
    return "text-green-500";
  }
  return "text-gray-500";
};

export const promoText = (task, student, stages) => {
  if (!task || !student || !stages || stages.length === 0) return '';
  if (percentScore(task) < CUTOFF) {
    return "Not Promoted";
  } else if (stages[student?.stage_id]?.order > stages[task?.stage_id]?.order) {
    return "Promoted";
  }
  return "Awaiting Promotion";
};

export const percentScore = (task) => {
  return (getSubmissionScore(task?.submissions) / task?.points) * MAXSCORE;
};
