import React, { useRef, useState, useEffect } from 'react';
import { BiTask } from 'react-icons/bi';
import { useParams } from 'react-router';
import { useGlobalContext } from '../../../context/context';
import useFetch from '../../../hooks/useFetch';
import Pagination from '../Pagination';
import { RefetchButton } from '../../../context/Refetch';
import AvatarWidget from '../AvatarWidget';

const getNames = (prop, student_id) => {
  return prop?.find((e) => e.id === student_id)?.profile;
};

function TaskDetailsSUBMISSION() {
  const { id } = useParams();
  const [currentPosts, setCurrentPosts] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [isLoading] = useState(false);
  const [gradeScore, setGradeScore] = useState([]);
  const inputRef = useRef(null);

  const {
    levels,
    tracks,
    selectedItems,
    setSelectedItems,
    filterOption,
    sortOption,
    workspace,
    task,
    setTask,
    globalSearch,
  } = useGlobalContext();
  const [pageData, setPageData] = useState({
    startIndex: 0,
    endIndex: 20,
  });

  const {
    data: taskSubmissions,
    isPending,
    error,
    refetch: refetchTaskSubmissions,
  } = useFetch(`/tasks/${id}`, workspace);

  const offset = 0;
  const limit = 1000;
  const { data: students, refetch: refetchStudents } = useFetch(
    `/students?task_id=${id}&offset=${offset}&limit=${limit}`,
    workspace
  );

  useEffect(() => {
    setTask(taskSubmissions);
  }, [taskSubmissions]);

  const handleCheckAll = (checked) => {
    setSelectAll(checked);
    if (checked) {
      setSelectedItems(
        currentPosts.map((item) => ({
          id: item.id,
          score: gradeScore[item.id],
        }))
      );
    } else {
      setSelectedItems([]);
    }
  };

  const handleCheckItem = (checked, itemId) => {
    if (checked) {
      setSelectedItems((prevSelectedItems) => [
        ...prevSelectedItems,
        {
          id: itemId,
          score:
            gradeScore[itemId] ||
            task?.submissions?.find((e) => e.id === itemId)?.score,
        },
      ]);
    } else {
      setSelectedItems((prevSelectedItems) =>
        prevSelectedItems.filter((item) => item.id !== itemId)
      );
    }
    setSelectAll(selectedItems.length + 1 === currentPosts.length);
  };

  const handleGradeScore = (val, id) => {
    setGradeScore((prevGradeScores) => ({
      ...prevGradeScores,
      [id]: val,
    }));
  };

  useEffect(() => {
    const handleWheel = (e) => {
      e.preventDefault();
    };

    const inputElement = inputRef.current;
    if (inputElement) {
      inputElement.addEventListener('wheel', handleWheel);

      return () => {
        inputElement.removeEventListener('wheel', handleWheel);
      };
    }
  }, []);

  const num_of_submissions = task?.submissions?.length || 0;

  const reducedStudentsList = students?.reduce((students, student) => {
    students[student.id] = student;
    return students;
  }, {});
  const filteredPosts = currentPosts
    ?.filter((post) => {
      const student = reducedStudentsList?.[post.student_id]?.profile;
      const fullName = `${student?.first_name || ''} ${
        student?.last_name || ''
      }`.toLowerCase();

      const matchesFilterOption =
        filterOption === 'All' || post.status === filterOption.toLowerCase();

      const matchesGlobalSearch =
        fullName.includes(globalSearch.toLowerCase()) && matchesFilterOption;

      return matchesGlobalSearch;
    })
    ?.sort((a, b) =>
      sortOption.trim() !== ''
        ? a.score - b.score
        : new Date(b.updated_at) - new Date(a.updated_at)
    );

  return (
    <div className="mt-4 text-gray-500">
      <p className="text-base">{task?.title}</p>
      <p className="text-[12px] text-grey-500 mt-2">
        {tracks[task?.track_id]?.title + ' - ' + levels[task?.level_id]?.title}
      </p>

      <div className="flex flex-col-reverse gap-4 md:flex-row mt-4 md:items-center lg:gap-[15rem]">
        <div className="flex items-center gap-2 bg-white py-3 px-4 rounded-md">
          <input
            type="checkbox"
            name=""
            id="checkall"
            checked={selectAll}
            onChange={(e) => handleCheckAll(e.target.checked)}
          />
          <p className="text-grey-500 text-sm">Select all students</p>
        </div>

        <p className="text-base font-normal">
          Showing {pageData.startIndex}-
          {num_of_submissions > pageData.endIndex
            ? pageData.endIndex
            : num_of_submissions}{' '}
          of {num_of_submissions} submissions
        </p>
      </div>

      {isPending && <div className="text-sm">Loading.....</div>}
      {error && <div className="text-sm bg-red-500 p-6 ">{error}</div>}
      <div className="overflow-x-scroll">
        {filteredPosts?.length < 1 ? (
          <p className="text-center mt-8 text-black font-lg">
            No submissions yet
          </p>
        ) : (
          filteredPosts?.map((data, index) => {
            const {
              id,
              student_id,
              submission_urls,
              score,
              status,
              updated_at,
            } = data;
            return (
              <div
                className="bg-white grid lg:grid-cols-5 items-center my-4 px-2 lg:px-0 rounded-md"
                key={index}
              >
                <div className="flex items-center">
                  <div className="flex items-center">
                    <div className="py-4 px-2 rounded-l-lg">
                      <input
                        type="checkbox"
                        name=""
                        id=""
                        checked={selectedItems?.some((item) => item.id === id)}
                        onChange={(e) => handleCheckItem(e.target.checked, id)}
                      />
                    </div>
                    <div className="py-4 px-2">
                      <AvatarWidget
                        avatarUrl={getNames(students, student_id)?.avatar}
                        w={'40px'}
                        h={'40px'}
                        text={'12px'}
                        owner={getNames(students, student_id)}
                      />
                    </div>
                  </div>
                  <div className="py-4 px-2">
                    {getNames(students, student_id)
                      ? getNames(students, student_id)?.first_name +
                        ' ' +
                        getNames(students, student_id)?.last_name
                      : 'No Name'}
                  </div>
                </div>

                <div className="lg:col-span-3 py-4 px-2 lg:px-10 border-r border-l">
                  <div className="flex justify-between items-center text-[11px] text-gray-500">
                    {' '}
                    <p className="">{task?.title}</p>
                    <p className="">{formatDateTime(updated_at)}</p>
                  </div>
                  <div className="max-h-32 overflow-y-auto">
                    {submission_urls?.map((e, idx) => (
                      <div
                        className="border border-gray-100 p-2 flex overflow-hidden"
                        key={idx}
                      >
                        <div className="w-10 border-r flex items-center justify-center">
                          <BiTask className="text-3xl" />
                        </div>
                        <div className="text-[12px] px-2 w-60 md:w-96 lg:w-auto">
                          <p>{taskSubmissions?.submission_links[idx]}</p>
                          <a
                            href={e}
                            target="_blank"
                            rel="noreferrer"
                            className="break-words w-11/12 text-blue-500"
                          >
                            {e}
                          </a>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="py-4 px-2 rounded-r-lg">
                  <div className="flex items-center gap-4">
                    <div className="flex flex-col text-xs items-center">
                      <p className="text-sm">Grade</p>
                      <p
                        className={`${
                          status === 'submitted'
                            ? 'text-yellow-500'
                            : status === 'graded'
                            ? 'text-green-500'
                            : 'text-red-500'
                        }`}
                      >
                        {status === 'submitted'
                          ? 'Not Graded'
                          : status === 'graded'
                          ? 'Graded'
                          : 'Not Submitted'}
                      </p>
                    </div>

                    <div className="flex items-center justify-center pr-1 text-sm border rounded-md">
                      <input
                        type="number"
                        ref={inputRef}
                        max={task?.points}
                        value={gradeScore[id] || score}
                        onChange={(e) => handleGradeScore(e.target.value, id)}
                        onInput={(e) => {
                          if (e.target.value > task?.points) {
                            e.target.value = task?.points;
                            handleGradeScore(task?.points, id);
                          }
                        }}
                        className="pl-1 text-center bg-red-50 h-10 w-12 outline-none"
                      />
                      <p className="w-8">/{task?.points}</p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>
      <RefetchButton
        name="submissionScreen"
        functions={[refetchStudents, refetchTaskSubmissions]}
      />
      {/* ch */}
      <Pagination
        data={task?.submissions}
        setNewData={setCurrentPosts}
        setPageData={setPageData}
        pageData={pageData}
      />
      {isLoading && (
        <div className="z-50 absolute bg-black opacity-70 top-0 right-0 left-0 bottom-0 flex justify-center items-center">
          <div className="w-10 h-10 border-r-2 border-t-4 border-b-6 border-dotted border-gray-500 rounded-full animate-spin"></div>
        </div>
      )}
    </div>
  );
}

export default TaskDetailsSUBMISSION;

export function formatDateTime(dateTimeString) {
  const options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };

  const date = new Date(dateTimeString);
  return date.toLocaleString('en-US', options);
}
