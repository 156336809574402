import { MdSpaceDashboard } from 'react-icons/md';
import { SiGoogleclassroom } from 'react-icons/si';
import { IoPerson } from 'react-icons/io5';
import { FaNetworkWired, FaTasks } from 'react-icons/fa';
import { MdOutlineSupportAgent } from 'react-icons/md';
import { IoSettings } from 'react-icons/io5';
import { BsPeopleFill } from 'react-icons/bs';
import { FaChalkboardTeacher } from 'react-icons/fa';
import { GrConfigure } from 'react-icons/gr';

export const student_SideItem = [
  {
    name: 'Dashboard',
    link: 'dashboard',
    logo: <MdSpaceDashboard />,
  },
  {
    name: 'Profile',
    link: 'profile',
    logo: <IoPerson />,
  },
  // {
  //   name: 'My Classroom',
  //   link: 'studentclassroom',
  //   logo: <SiGoogleclassroom />,
  // },
  {
    name: 'Task',
    link: 'task',
    logo: <FaTasks />,
  },
  // {
  //   name: 'Curriculum',
  //   link: 'studentcurriculum',
  //   logo: <MdSpaceDashboard />,
  // },
  // {
  //   name: 'Support',
  //   link: 'support',
  //   logo: <MdOutlineSupportAgent />,
  // },
  {
    name: 'Settings',
    link: 'settings',
    logo: <IoSettings />,
  },
];

export const instructor_SideItem = [
  {
    name: 'Dashboard',
    link: 'dashboard',
    logo: <MdSpaceDashboard />,
  },
  {
    name: 'Profile',
    link: 'profile',
    logo: <IoPerson />,
  },
  {
    name: 'Students',
    link: 'student',
    logo: <BsPeopleFill />,
  },
  // {
  //   name: 'My Classroom',
  //   link: 'studentclassroom',
  //   logo: <SiGoogleclassroom />,
  // },
  {
    name: 'Task',
    link: 'task',
    logo: <FaTasks />,
  },
  // {
  //   name: 'Curriculum',
  //   link: 'studentcurriculum',
  //   logo: <MdSpaceDashboard />,
  // },
  // {
  //   name: 'Support',
  //   link: 'support',
  //   logo: <MdOutlineSupportAgent />,
  // },
  {
    name: 'Settings',
    link: 'settings',
    logo: <IoSettings />,
  },
];

export const admin_SideItem = [
  {
    name: 'Dashboard',
    link: 'dashboard',
    logo: <MdSpaceDashboard />,
  },
  {
    name: 'Profile',
    link: 'profile',
    logo: <IoPerson />,
  },

  {
    name: 'Students',
    link: 'student-list',
    logo: <BsPeopleFill />,
  },
  {
    name: 'Trainer',
    link: 'trainer',
    logo: <FaChalkboardTeacher />,
  },
  // {
  //   name: 'My Classroom',
  //   link: 'studentclassroom',
  //   logo: <SiGoogleclassroom />,
  // },
  {
    name: 'Task',
    link: 'task',
    logo: <FaTasks />,
  },
  {
    name: 'Curriculum',
    link: 'studentcurriculum',
    logo: <MdSpaceDashboard />,
  },
  {
    name: 'Workspace',
    link: 'workspace',
    logo: <FaNetworkWired />,
  },
  // {
  //   name: 'Support',
  //   link: 'support',
  //   logo: <MdOutlineSupportAgent />,
  // },
  {
    name: 'Configuration',
    link: 'configuration',
    logo: <GrConfigure />,
  },
  {
    name: 'Settings',
    link: 'settings',
    logo: <IoSettings />,
  },
];
