import { useGlobalContext } from '../context/context';
import { createData, fetchData } from '../hooks/api';

const offset = 0;
const limit = 1000;

export const useStudentTransitionService = () => {
  const { workspace } = useGlobalContext();

  const getPromotionList = async () => {
    return fetchData(
      `/admin/tasks/promotion?offset=${offset}&limit=${limit}`,
      workspace,
      {}
    );
  };
  const getDemotionList = async () => {
    return fetchData(
      `/admin/tasks/demotion?offset=${offset}&limit=${limit}`,
      workspace,
      {}
    );
  };

  const pushPromotion = async (data) => {
    return createData(`/admin/tasks/promotion`, data, workspace, {});
  };
  const pushDemotion = async (data) => {
    return createData(`/admin/tasks/demotion`, data, workspace, {});
  };

  return {
    getPromotionList,
    getDemotionList,
    pushPromotion,
    pushDemotion,
  };
};
