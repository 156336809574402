import React from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import InputWidget from '../../../../components/global/InputWidget';
import { useGlobalContext } from '../../../../context/context';

export const ProfileEdit = ({ setEditOption }) => {
  const { userProfile, setUserProfile, workspace } = useGlobalContext();
  const [isLoading, setIsLoading] = useState(false);

  const [setIsError] = useState(false);
  const [setErrorMsg] = useState(null);

  const currentUser = JSON.parse(localStorage.getItem('user'));
  const currentAccount = JSON.parse(localStorage.getItem('account'));

  const profiles = userProfile?.profile;
  const infos = userProfile?.info;
  const [form, setForm] = useState({
    first_name: profiles?.first_name ? profiles?.first_name : '',
    last_name: profiles?.last_name ? profiles?.last_name : '',
    marital: profiles?.marital_status ? profiles?.marital_status : '',
    dob: profiles?.date_of_birth ? profiles?.date_of_birth : '',
    gender: profiles?.gender ? profiles?.gender : '',
  });

  const handleInputChange = ({ target }) => {
    setForm({ ...form, [target.name]: target.value });
  };

  const handleUpdateProfile = async () => {
    const { first_name, last_name, marital, dob, gender } = form;
    const profile = {
      id: currentUser?.user?.id,
      first_name: first_name,
      last_name: last_name,
      gender: gender,
      marital_status: marital,
      date_of_birth: dob,
      owner_id: currentUser?.user?.id,
    };
    const info = {
      employment_status: infos?.employment_status,
      qualification: infos?.qualification,
      information_source: infos?.information_source,
      survey_data: [],
    };
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/${workspace}/profiles`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${currentAccount?.access_token}`,
          },
          body: JSON.stringify({
            profile: profile,
            info: info,
          }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        if (Array.isArray(errorData)) {
          setErrorMsg(errorData.detail[0].msg);
          toast.error(errorData.detail[0].msg);
        } else {
          setErrorMsg(errorData.detail);
          toast.error(errorData.detail);
        }
        setIsLoading(false);
        setIsError(true);

        throw new Error(errorData);
      }

      const updatedData = await response.json();

      setUserProfile(updatedData);
      toast.success('Profile updated successful');
      setIsLoading(false);
      setEditOption(false);
    } catch (error) {
      console.error('Error updating profile:', error);
      setIsLoading(false);
      toast.error(error);
      setIsError(true);
    }
  };

  return (
    <>
      <form action="" className="mt-4 md:w-[50%]">
        {formData.map((data, idx) => (
          <InputWidget
            key={idx}
            handleInputChange={handleInputChange}
            form={form}
            label={data.label}
            name={data.name}
            placeholder={data.placeholder}
            type={data.type}
            method={data.method ? data.method : ''}
            selectOption={data.selectOption ? data.selectOption : ''}
          />
        ))}
      </form>
      <div className="flex mt-4 space-x-4">
        <button
          className="px-4 border border-[grey] text-sm py-1 rounded-md"
          onClick={() => setEditOption(false)}
        >
          Cancel
        </button>
        <button
          className="px-4 bg-blue-ribbon-500 text-white text-sm py-1 rounded-md"
          onClick={handleUpdateProfile}
        >
          {isLoading ? 'Updating...' : 'Save Changes'}
        </button>
      </div>
    </>
  );
};

const formData = [
  {
    type: 'text',
    name: 'first_name',
    placeholder: 'Enter your first name',
    label: 'First Name',
  },
  {
    type: 'text',
    name: 'last_name',
    placeholder: 'Enter your last name',
    label: 'First Name',
  },
  {
    type: 'date',
    name: 'dob',
    placeholder: 'DD/MM/YYY',
    label: 'Date of Birth',
  },
  {
    type: 'text',
    name: 'gender',
    placeholder: 'Select gender',
    label: 'Gender',
    method: 'select',
    selectOption: ['Male', 'Female', 'Other'],
  },
  // {
  //   type: 'text',
  //   name: 'state',
  //   placeholder: 'Enter your state of origin',
  //   label: 'State of Origin',
  // },
  // {
  //   type: 'text',
  //   name: 'city',
  //   placeholder: 'Enter your city of origin',
  //   label: 'City of Origin',
  // },
  // {
  //   type: 'text',
  //   name: 'level',
  //   placeholder: 'Select level',
  //   label: 'Level',
  //   method: 'select',
  //   selectOption: ['Begginner', 'Intermediate'],
  // },
  // {
  //   type: 'text',
  //   name: 'track',
  //   placeholder: 'Select track',
  //   label: 'Track',
  //   method: 'select',
  //   selectOption: [
  //     'Front-End (Begginer)',
  //     'Front-End (React)',
  //     'Back-End (NodeJs-Begginner)',
  //     'Back-End (NodeJs-Intermediate)',
  //     'Andriod (Begginner)',
  //     'Andriod (Intermediate)',
  //     'UI/UX (Begginner)',
  //     'UX/UX (Intermediate)',
  //   ],
  // },
  // {
  //   type: 'text',
  //   name: 'employment',
  //   placeholder: 'Select employment',
  //   label: 'Employment Status',
  //   method: 'select',
  //   selectOption: ['Student', 'Unemployed', 'Self-Employed', 'Employed'],
  // },
  // {
  //   type: 'text',
  //   name: 'qualification',
  //   placeholder: 'Select qualification',
  //   label: 'Educational Qualification',
  //   method: 'select',
  //   selectOption: [
  //     'High School',
  //     'Undergraduate',
  //     'Postgraduate',
  //     'Masters',
  //     'PhD',
  //   ],
  // },
  {
    type: 'text',
    name: 'marital',
    placeholder: 'Select marital status',
    label: 'Marital Status',
    method: 'select',
    selectOption: ['Single', 'Married', 'Others'],
  },
];
