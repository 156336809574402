export const dashboardAnalysis = (data) => {
  const summarize = (obj, key, count) => (obj[key] = (obj[key] || 0) + count);

  const studentSummary = data?.students?.reduce(
    (acc, { track, level, stage, count }) => {
      summarize(acc.stages, stage, count);
      summarize(acc.levels, level, count);
      summarize(acc.tracks, track, count);
      return acc;
    },
    { stages: {}, levels: {}, tracks: {} }
  );

  const trainerSummary = data?.trainers?.reduce(
    (acc, { track, level, count }) => {
      summarize(acc.levels, level, count);
      summarize(acc.tracks, track, count);
      return acc;
    },
    { levels: {}, tracks: {} }
  );

  return {
    students: studentSummary,
    trainers: trainerSummary,
    performance: data.performance,
  };
};
