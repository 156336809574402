import { useGlobalContext } from '../context/context';
import { createData, deleteData, fetchData, updateData } from '../hooks/api';

export const useTaskService = () => {
  const { workspace } = useGlobalContext();

  const getAllTasks = async (offset, limit, options = {}) => {
    return fetchData('/tasks', workspace, options);
  };

  const getTask = async (task_id, options = {}) => {
    return fetchData(`/tasks/${task_id}`, workspace, options);
  };

  const updateTask = async (task, options = {}) => {
    return updateData('/tasks', task.id, task, workspace, options);
  };

  const createTask = async (task, options = {}) => {
    return createData('/tasks', task, workspace, options);
  };

  const deleteTask = async (task, options = {}) => {
    return deleteData('/tasks', task.id, workspace, options);
  };

  return {
    getAllTasks,
    getTask,
    createTask,
    updateTask,
    deleteTask,
  };
};

export const useTaskSubmissionService = (task_id) => {
  const { workspace } = useGlobalContext();

  const submitTask = async (submission, options = {}) => {
    return createData(
      `/tasks/${task_id}/submit`,
      submission,
      workspace,
      options
    );
  };

  const gradeTask = async (grades, options = {}) => {
    return createData(`/tasks/${task_id}/grade`, grades, workspace, options);
  };

  return { submitTask, gradeTask };
};
