import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';

const Error404 = () => {
  const st = JSON.parse(localStorage?.getItem('user'));
  const [userType, setUserType] = useState('');
  const [routeLink, setRouteLink] = useState('');
  const [routeText, setRouteText] = useState('');

  useEffect(() => {
    setUserType(st?.type);
  }, [st]);

  useEffect(() => {
    setRouteLink(
      userType === 'learner'
        ? '/student'
        : userType === 'trainer'
        ? '/instructor'
        : userType === 'admin'
        ? '/admin'
        : '/'
    );

    setRouteText(
      userType === 'learner'
        ? 'Back to student dashboard'
        : userType === 'trainer'
        ? 'Back to instructor dashboard'
        : userType === 'admin'
        ? 'Back to admin dashboard'
        : 'Back to login page'
    );
  }, [userType]);

  return (
    <div className="w-full h-screen flex justify-center items-center">
      <div className="error text-center">
        <h1 className="text-[50px] font-bold">Error!!!</h1>
        <p className="mt-5 mb-12">This page doesnt exist for this user</p>
        <Link
          to={routeLink}
          className="px-12 py-2 shadow-md text-xl rounded-md hover:bg-blue-ribbon-100 transition-all ease-in-out duration-500"
        >
          {routeText}
        </Link>
      </div>
    </div>
  );
};

export default Error404;
