import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

import PerformanceDropDown from './PerformanceDropDown';
import { useGlobalContext } from '../../../../context/context';
import { dashboardColors } from './dashboardColor';

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const options = {
  plugins: {
    legend: {
      display: false,
    },
  },
};

function StudentPerformance({ performance, studentLength }) {
  const [dropdownValue, setDropdownValue] = useState({
    parentdrop: 'all',
    childdrop: 'all',
  });
  const { tracks, levels, stages } = useGlobalContext();
  const [performanceScores, setPerformanceScores] = useState(
    new Array(8).fill(0)
  );

  const filterData = () => {
    if (
      dropdownValue.parentdrop !== 'all' &&
      dropdownValue.childdrop !== 'all'
    ) {
      return performance.filter(
        (item) =>
          item.track === parseInt(dropdownValue.parentdrop, 10) &&
          item.level === parseInt(dropdownValue.childdrop, 10)
      );
    } else if (dropdownValue.parentdrop !== 'all') {
      return performance.filter(
        (item) => item.track === parseInt(dropdownValue.parentdrop, 10)
      );
    } else if (dropdownValue.childdrop !== 'all') {
      return performance.filter(
        (item) => item.level === parseInt(dropdownValue.childdrop, 10)
      );
    } else {
      return performance;
    }
  };

  const calculateStageScores = (filterData) => {
    const stageScores = new Array(8).fill(0);
    filterData?.forEach((item) => {
      stageScores[stages?.[item.stage]?.order] += item.score;
    });
    return stageScores;
  };

  useEffect(() => {
    (async () => {
      const filter = await filterData();
      const stageScores = calculateStageScores(filter);
      setPerformanceScores(stageScores);
    })();
  }, [dropdownValue, performance]);

  const sortedStages = Object.values(stages || {})?.sort(
    (a, b) => a.order - b.order
  );

  const data = {
    labels: sortedStages.map((stage) => stage.title),
    datasets: [
      {
        label: 'Student Performance',
        data: [...Object.values(performanceScores || {})],
        backgroundColor: dashboardColors,
      },
    ],
  };

  return (
    <div className="w-full bg-white rounded-xl p-5">
      <div className="flex justify-between items-center">
        <div className="space-y-2">
          <p className="text-xs text-gray-400">
            {dropdownValue.parentdrop === 'all'
              ? 'All Tracks'
              : tracks[dropdownValue.parentdrop]?.title}
            {' - '}
            {dropdownValue.childdrop === 'all'
              ? 'All Levels'
              : levels[dropdownValue?.childdrop]?.title}
          </p>
          <p>Students Performance</p>
        </div>
        <div className="relative">
          <PerformanceDropDown
            dropdownValue={dropdownValue}
            setDropdownValue={setDropdownValue}
          />
        </div>
      </div>

      <h1 className="my-2 text-gray-500">
        <span className="text-blue-ribbon-500">{65}</span> average .{' '}
        <span className="text-blue-ribbon-500">{studentLength}</span> students
      </h1>
      <Bar data={data} options={options}></Bar>
    </div>
  );
}

export default StudentPerformance;
