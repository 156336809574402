import React from 'react';
import Style from './Login.module.css';
import auth_img from '../../assets/images/auth-image.svg';

function AuthTheme({ children }) {
  return (
    <div className="">
      <div className=" flex flex-row shadow-xl md:rounded-xl">
        <div
          className={`bg-auth-img w-full h-screen hidden md:flex flex-col items-end justify-center`}
        >
          <div
            className={`relative h-[90%] overflow-hidden rounded-l-2xl w-[90%] flex flex-col justify-center items-center`}
          >
            <div className="absolute w-full h-full rounded-l-2xl opacity-70 bg-black"></div>
            <h2 className={`text-3xl font-bold text-center text-white z-50`}>
              KodeCamp LMS
            </h2>
            <p className={`font-base z-50 text-white text-center max-w-md`}>
              Learning has been made simple, interactive and fun.
            </p>
          </div>
        </div>

        <div
          className={`${Style.col2_bg} w-full h-screen md:flex flex-col items-start justify-center`}
        >
          <div
            className={`${Style.box_shadow} h-full md:h-[90%] md:rounded-r-2xl w-full px-5 md:px-0 md:w-[90%] flex flex-col items-center overflow-y-auto`}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AuthTheme;
