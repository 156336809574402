import React from "react";

import CalendarWidget from "../../../../components/global/calender/CalendarWidget";

const num = [4, 12];
const percent = Math.floor((num[0] / num[1]) * 100);

function InternshipProgress() {
  return (
    <div className="w-full h-[100%] rounded-xl flex flex-col justify-between">
      <div className="p-5 py-8 bg-white rounded-lg">
        <h1>Internship Progress</h1>
        <div className="mt-6 flex justify-between items-center">
          <p>
            Week {num[0]} of {num[1]}
          </p>
          <p>{percent}%</p>
        </div>
        <div className="w-full h-2 bg-gray-100 shadow-inner rounded-md mt-2">
          <div
            className={`w-[${percent}%] rounded transition-all ease-in-out duration-500 h-[100%] bg-blue-ribbon-500`}
          ></div>
        </div>
      </div>
      <CalendarWidget addView={true} route={"/admin/dashboard/morecalendar"} />
    </div>
  );
}

export default InternshipProgress;
