const data = [
  {
    id: 1,
    stage: 1,
    task: "Information Architecture",
    track: "UI/UX",
    dueDate: "8th Oct, 2022. 11:59PM",
    points: 100,
    status: "Submitted",
  },
  {
    id: 2,
    stage: 2,
    task: "Information Architecture",
    track: "UI/UX",
    dueDate: "8th Oct, 2022. 11:59PM",
    points: 49,
    status: "Not submitted",
  },
  {
    id: 3,
    stage: 3,
    task: "Information Architecture",
    track: "UI/UX",
    dueDate: "8th Oct, 2022. 11:59PM",
    points: 50,
    status: "Graded",
  },
  {
    id: 4,
    stage: 4,
    task: "Information Architecture",
    track: "UI/UX",
    dueDate: "8th Oct, 2022. 11:59PM",
    points: 100,
    status: "Graded",
  },
  {
    id: 5,
    stage: 5,
    task: "Information Architecture",
    track: "UI/UX",
    dueDate: "8th Oct, 2022. 11:59PM",
    points: 100,
    status: "Graded",
  },
  {
    id: 6,
    stage: 6,
    task: "Information Architecture",
    track: "UI/UX",
    dueDate: "8th Oct, 2022. 11:59PM",
    points: 78,
    status: "Submitted",
  },
  {
    id: 7,
    stage: 7,
    task: "Information Architecture",
    track: "UI/UX",
    dueDate: "8th Oct, 2022. 11:59PM",
    points: 100,
    status: "Graded",
  },
  {
    id: 8,
    stage: 8,
    task: "Information Architecture",
    track: "UI/UX",
    dueDate: "8th Oct, 2022. 11:59PM",
    points: 32,
    status: "Not submitted",
  },
];

export const students = [
  {
    id: "0001",
    name: "Sammy Smith",
    gender: "male",
    level: "Beginner",
    track: "Product Management",
    stage: 3,
    grade: "160/200",
    certificate: "Not uploaded",
    banned: "Yes",
    img: "https://i.ibb.co/SJ2GPxC/Ellipse-47-4.png",
  },
  {
    id: "0002",
    name: "flourish Udeke",
    gender: "female",
    level: "Intermidiate",
    track: "UI/UX",
    stage: 8,
    grade: "780/800",
    certificate: "Uploaded",
    banned: "No",
    img: "https://i.ibb.co/6RNhQgK/Ellipse-47-3.png",
  },
  {
    id: "0003",
    name: "Sammy Ayobami",
    gender: "male",
    level: "Intermidiate",
    track: "Andriod",
    stage: 4,
    grade: "340/200",
    certificate: "Not uploaded",
    banned: "No",
    img: "https://i.ibb.co/605z71y/Ellipse-47-2.png",
  },
  {
    id: "0004",
    name: "Mariam Salam",
    gender: "female",
    level: "Beginner",
    track: "UI/UX",
    stage: 5,
    grade: "400/600",
    certificate: "Not uploaded",
    banned: "No",
    img: "https://i.ibb.co/6RNhQgK/Ellipse-47-3.png",
  },
  {
    id: "0005",
    name: "Sammy Sikiru",
    gender: "male",
    level: "Beginner",
    track: "Product Management",
    stage: 8,
    grade: "750/800",
    certificate: "Uploaded",
    banned: "No",
    img: "https://i.ibb.co/605z71y/Ellipse-47-2.png",
  },
  {
    id: "0006",
    name: "Bello Wills",
    gender: "male",
    level: "Intermidiate",
    track: "Android",
    stage: 3,
    grade: "160/200",
    certificate: "Not uploaded",
    banned: "No",
    img: "https://i.ibb.co/SJ2GPxC/Ellipse-47-4.png",
  },
  {
    id: "0007",
    name: "Ayobami Akanni",
    gender: "male",
    level: "Intermidiate",
    track: "Product Management",
    stage: 6,
    grade: "600/700",
    certificate: "Not uploaded",
    banned: "No",
    img: "https://i.ibb.co/605z71y/Ellipse-47-2.png",
  },
  {
    id: "0008",
    name: "Grace Chioma",
    gender: "female",
    level: "Beginner",
    track: "UI/UX",
    stage: 8,
    grade: "760/800",
    certificate: "Uploaded",
    banned: "No",
    img: "https://i.ibb.co/6RNhQgK/Ellipse-47-3.png",
  },
  {
    id: "0009",
    name: "Samuel Ebuka",
    gender: "male",
    level: "Beginner",
    track: "Product Management",
    stage: 6,
    grade: "460/700",
    certificate: "Not uploaded",
    banned: "No",
    img: "https://i.ibb.co/605z71y/Ellipse-47-2.png",
  },
  {
    id: "0010",
    name: "Jin Mu",
    gender: "male",
    level: "Intermidiate",
    track: "Frontend",
    stage: 8,
    grade: "760/800",
    certificate: "Uploaded",
    banned: "No",
    img: "https://i.ibb.co/SJ2GPxC/Ellipse-47-4.png",
  },
  {
    id: "0011",
    name: "Brian White",
    gender: "male",
    level: "Beginner",
    track: "Backend",
    stage: 3,
    grade: "160/200",
    certificate: "Not uploaded",
    banned: "No",
    img: "https://i.ibb.co/605z71y/Ellipse-47-2.png",
  },
  {
    id: "0012",
    name: "Precious Uregbeka",
    gender: "female",
    level: "Intermidiate",
    track: "CAD",
    stage: 7,
    grade: "680/800",
    certificate: "Uploaded",
    banned: "No",
    img: "https://i.ibb.co/6RNhQgK/Ellipse-47-3.png",
  },
  {
    id: "0013",
    name: "Gloria Smith",
    gender: "female",
    level: "Intermidiate",
    track: "Product Management",
    stage: 2,
    grade: "100/200",
    certificate: "Not uploaded",
    banned: "No",
    img: "https://i.ibb.co/6RNhQgK/Ellipse-47-3.png",
  },
  {
    id: "0014",
    name: "Ismail Boss",
    gender: "male",
    level: "Intermidiate",
    track: "Frontend",
    stage: 8,
    grade: "780/800",
    certificate: "Uploaded",
    banned: "No",
    img: "https://i.ibb.co/SJ2GPxC/Ellipse-47-4.png",
  },
  {
    id: "0015",
    name: "Simp Soda",
    gender: "male",
    level: "Beginner",
    track: "CAD",
    stage: 8,
    grade: "770/800",
    certificate: "Uploaded",
    banned: "No",
    img: "https://i.ibb.co/605z71y/Ellipse-47-2.png",
  },
  {
    id: "0016",
    name: "Bayo Davies",
    gender: "male",
    level: "Intermidiate",
    track: "Backend",
    stage: 8,
    grade: "750/800",
    certificate: "Uploaded",
    banned: "No",
    img: "https://i.ibb.co/SJ2GPxC/Ellipse-47-4.png",
  },
  {
    id: "0017",
    name: "Uluma John",
    gender: "female",
    level: "Beginner",
    track: "Digital Marketing",
    stage: 3,
    grade: "160/200",
    certificate: "Not uploaded",
    banned: "No",
    img: "https://i.ibb.co/6RNhQgK/Ellipse-47-3.png",
  },
  {
    id: "0018",
    name: "Victor Praise",
    gender: "male",
    level: "Intermidiate",
    track: "Digital Marketing",
    stage: 8,
    grade: "750/800",
    certificate: "Uploaded",
    banned: "No",
    img: "https://i.ibb.co/605z71y/Ellipse-47-2.png",
  },
  {
    id: "0019",
    name: "Sammy Smithers",
    gender: "male",
    level: "Beginner",
    track: "Product Management",
    stage: 3,
    grade: "160/200",
    certificate: "Not uploaded",
    banned: "No",
    img: "https://i.ibb.co/605z71y/Ellipse-47-2.png",
  },
  {
    id: "0020",
    name: "Wiiliam Brooks",
    gender: "male",
    level: "Beginner",
    track: "Product Management",
    stage: 3,
    grade: "160/200",
    certificate: "Not uploaded",
    banned: "No",
    img: "https://i.ibb.co/SJ2GPxC/Ellipse-47-4.png",
  },
  {
    id: "0021",
    name: "Korede Smith",
    gender: "male",
    level: "Beginner",
    track: "Product Management",
    stage: 8,
    grade: "760/800",
    certificate: "Uploaded",
    banned: "No",
    img: "https://i.ibb.co/SJ2GPxC/Ellipse-47-4.png",
  },
];

export default data;
