export function hasEmptyValue(obj) {
  for (let key in obj) {
    if (typeof obj[key] === 'object') {
      if (hasEmptyValue(obj[key])) {
        return true;
      }
    } else {
      if (obj[key] === '') {
        return true;
      }
    }
  }
  return false;
}
