import {
  callApi,
  createData,
  deleteData,
  fetchData,
  updateData,
} from '../hooks/api';

export const useWorkspaceService = () => {
  const fetchWorkspaces = async () => {
    return fetchData('/workspaces');
  };

  const fetchWorkspacesAvailable = async () => {
    return callApi('GET', '/workspaces/available', null, null, {}, true);
  };

  const fetchWorkspace = async (workspace_id) => {
    return fetchData('/workspaces/' + workspace_id);
  };
  const saveWorkspace = async (workspace_data) => {
    if (workspace_data.id) {
      return updateData('/workspaces', workspace_data.id, workspace_data);
    }
    return createData('/workspaces', workspace_data);
  };

  const deleteWorkspace = async (workspace_id) => {
    return deleteData('/workspaces', workspace_id);
  };

  return {
    fetchWorkspaces,
    fetchWorkspacesAvailable,
    fetchWorkspace,
    saveWorkspace,
    deleteWorkspace,
  };
};
