import { useEffect, useState } from "react";

import { Logo, Tick } from "../../assets";
import { useNavigate } from "react-router-dom";

import AuthTheme from "../../components/authentication/AuthTheme";

const VerifyEmail = () => {
  const hoursMinSecs = { initialMinute: 2, initialSeconds: 0 };
  const { initialMinute = 0, initialSeconds = 0 } = hoursMinSecs;
  const [minutes, setMinutes] = useState(initialMinute);
  const [seconds, setSeconds] = useState(initialSeconds);
  const navigate = useNavigate();

  useEffect(() => {
    let myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  }, [minutes, seconds]);

  return (
    <AuthTheme>
      <div className="flex flex-col justify-center items-center h-full mx-auto text-center">
        <div className="w-20 h-20 rounded-lg bg-transparent shadow-md mb-4 grid place-items-center">
          <img src={Logo} alt="kodecamp-logo" />
        </div>
        <h2 className="text-[28px] font-medium mb-3">Verify email</h2>
        <div className="flex my-4 isolate">
          <span className="w-8 h-8 rounded-full flex justify-center items-center bg-green-600">
            <img src={Tick} alt="tick" />
          </span>
          <div className="flex justify-center items-center">
            <hr className="w-14 h-px flex place-items-center text-center bg-gray-600" />
          </div>
          <span className="w-8 h-8 rounded-full flex justify-center items-center bg-blue-600">
            <p className="font-medium text-lg text-center text-white">2</p>
          </span>
          <div className="flex justify-center items-center">
            <hr className="w-14 h-px flex place-items-center text-center bg-gray-600" />
          </div>
          <span className="w-8 h-8 rounded-full flex justify-center items-center bg-[#808080]">
            <p className="font-medium text-lg text-center text-white">3</p>
          </span>
        </div>
        <p className="font-medium text-lg text-gray-600 mb-5">
          A Link has been sent to your email
        </p>
        <p className="w-[60%]">
          Please open your email and click on the link to reset your password
        </p>

        <div className="flex justify-center">
          <p
            className="text-blue-ribbon-500 mt-12 cursor-pointer"
            onClick={() => navigate("/forgot-password")}
          >
            Try Again
          </p>
        </div>
      </div>
    </AuthTheme>
  );
};

export default VerifyEmail;
