export function calculateWeekPercentage() {
  const startDate = new Date('2024-05-13');
  const endDate = new Date('2024-08-16');
  const todayDate = new Date();

  const totalDuration = endDate - startDate;
  const completedDuration = todayDate - startDate;

  const percentageCompleted = (completedDuration / totalDuration) * 100;
  return percentageCompleted;
}

export function calculateWeekCount() {
  const startDate = new Date('2024-05-13');
  const today = new Date();
  const millisecsInAWeek = 6 * 24 * 60 * 60 * 1000;

  const weekCount = Math.ceil((today - startDate) / millisecsInAWeek);

  return weekCount;
}

// function getWeekDifference() {
//   const start = new Date('2024-5-13');
//   const end = new Date('2024-6-3');
//   const millisecsInAWeek = 6 * 24 * 60 * 60 * 1000;

//   const diffInMillisecs = end - start;

//   const weekCount = Math.ceil(diffInMillisecs / millisecsInAWeek);

//   return weekCount;
// }

// console.log(getWeekDifference());
