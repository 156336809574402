import React from 'react';
import { FaCheckCircle, FaInfoCircle } from 'react-icons/fa';
import { OldStdFormElements } from '../../data/dashboardData';
import InputWidget from '../global/InputWidget';
import Modal from '../global/Modal';

const JoinCohortModal = ({
  hasNewCohort,
  hideCohortJoinModal,
  handleCancelJoinWorkspace,
  setOldStdRegForm,
  oldStdRegForm,
  handleFormSubmit,
  form,
  handleInputChange,
  availableCohorts,
  generatedLevels,
  generatedTracks,
  isSuccess,
  handleContinue,
  cohortObj,
}) => {
  return (
    <>
      <Modal isOpen={!hasNewCohort && !hideCohortJoinModal} maxWidth='560px'>
        <div className="flex flex-col items-center justify-center gap-5">
          <FaInfoCircle className="text-blue-500 text-[100px]" />
          <p className="text-lg md:text-3xl font-semibold text-center">
            New Cohort Now Available
          </p>
          <p className="text-center text-lg text-gray-500">
            Join Now to Explore More Opportunity <br /> and Expand Your Skill
          </p>
          <div className="flex items-center gap-4 mb-5">
            <button
              className="py-2 border border-blue-500 text-blue-500 w-40 rounded-md"
              onClick={handleCancelJoinWorkspace}
            >
              Cancel
            </button>
            <button
              className="py-2 bg-blue-ribbon-500 text-white w-40  rounded-md"
              onClick={() => {
                setOldStdRegForm(true);
              }}
            >
              Register Now
            </button>
          </div>
        </div>
      </Modal>
      <Modal isOpen={oldStdRegForm} onClose={() => setOldStdRegForm(false)} maxWidth='480px'>
        <div className="flex justify-center gap-4 py-5">
          <img src={cohortObj?.logo} alt={cohortObj?.label} />
          <p className="text-xl font-bold">{cohortObj?.label}</p>
        </div>
        <form onSubmit={handleFormSubmit}>
          {OldStdFormElements(
            availableCohorts,
            generatedLevels,
            generatedTracks
          ).map((data, idx) => (
            <InputWidget
              key={idx}
              handleInputChange={handleInputChange}
              form={form}
              label={data.label}
              name={data.name}
              placeholder={data.placeholder}
              type={data.type}
              method={data.method ? data.method : ''}
              selectOption={data.selectOption ? data.selectOption : ''}
            />
          ))}
          <div className="flex justify-center">
            <button
              type="submit"
              className="px-14 py-2 text-white bg-blue-500 rounded-md"
            >
              Join Cohort
            </button>
          </div>
        </form>
      </Modal>
      <Modal isOpen={isSuccess}>
        <div className="flex flex-col items-center justify-center gap-5">
          <FaCheckCircle className="text-blue-500 text-[100px]" />
          <p className="text-lg md:text-3xl font-semibold text-center">
            Congratulations
          </p>
          <p className="text-center text-lg text-gray-500">
            You have successfully joined
          </p>
          <p className="text-center text-lg text-gray-700">{cohortObj?.name}</p>
          <div className="flex items-center gap-4 mb-5">
            <button
              className="py-2 bg-blue-ribbon-500 text-white w-40  rounded-md"
              onClick={handleContinue}
            >
              Continue
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default JoinCohortModal;
