import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useGlobalContext } from '../context/context';
import { useProfileService } from '../services/profile.service';
import { useSwitchService } from '../services/switch.service';
import StageIcon from './StageIcon';
import { capitalizeFirstLetter } from '../utils/capitalizeFirstLetter';
import { getStatusColor } from '../utils/getStatusColor';

const Sidebar = ({ showSide, setShowSide, sideItem, route }) => {
  const currentUser = JSON.parse(localStorage.getItem('user'));
  const handleNavSide = () => setShowSide(false);
  const {
    setWorkspace,
    accountData,
    setUserData,
    setIsLoading,
    setUserProfile,
  } = useGlobalContext();
  const [cohorts, setCohorts] = useState([]);
  const [currentCohort, setCurrentCohort] = useState();
  const { switchCohort } = useSwitchService();
  const { fetchProfile } = useProfileService();
  const [openWS, setOpenWS] = useState(false);

  useEffect(() => {
    const workspaces = accountData.account?.workspaces;
    setCohorts(workspaces);
  }, [accountData]);

  const reduceWorkspace = cohorts?.reduce((spaces, space) => {
    spaces[space.id] = space;
    return spaces;
  }, {});

  useEffect(() => {
    setCurrentCohort(reduceWorkspace?.[currentUser.workspace_id]);
  }, [currentUser, reduceWorkspace]);

  const handleCohortChange = async (target) => {
    setOpenWS(false);
    if (target?.id === currentCohort?.id) {
      return;
    }
    try {
      setIsLoading(true);
      const newUser = await switchCohort(
        { workspace_id: target.id },
        target.slug
      );

      setWorkspace(newUser.workspace_slug);
      localStorage.setItem('workspace_slug', newUser.workspace_slug);

      setUserData(newUser);
      localStorage.setItem('user', JSON.stringify(newUser));

      const profile = await fetchProfile(newUser.workspace_slug);

      setUserProfile(profile);
      localStorage.setItem('local-profile', JSON.stringify(profile));
    } catch (error) {
      console.error('Error switching cohort:', error);
      toast.error(error + '');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div
        className={`${
          showSide
            ? 'fixed left-0 h-screen'
            : 'h-screen absolute -left-[200%] md:left-0'
        } overflow-hidden w-[206px] md:w-[236px] transition-all ease-in-out duration-500 top-16 md:top-0 bottom-0 z-40 md:fixed `}
      >
        <div className="bg-white h-full flex flex-col overflow-auto">
          <div className="flex items-center gap-4 pt-6 px-4">
            <div className="">
              <div
                className="flex items-center gap-5 cursor-pointer"
                onClick={() => setOpenWS(!openWS)}
              >
                <img
                  src={currentCohort?.logo}
                  alt="kodecamp-logo"
                  className="w-[26.71px] h-[26px]"
                />
                <p>{currentCohort?.label}</p>
              </div>
              {openWS ? (
                <div
                  className={`bg-white shadow-md rounded-md z-40 absolute top-14 border border-gray-400`}
                >
                  {cohorts?.map((e) => (
                    <div
                      key={e.id}
                      className={`${
                        currentCohort?.id === e.id ? 'bg-gray-300' : ''
                      } flex items-center shadow gap-5 py-2 px-4 hover:bg-blue-100 cursor-pointer`}
                      onClick={() => handleCohortChange(e)}
                    >
                      <img
                        src={e?.logo}
                        alt=""
                        className="w-[26.71px] h-[26px]"
                      />
                      <p>{e?.label}</p>
                    </div>
                  ))}
                </div>
              ) : null}
            </div>
          </div>

          <nav className="mt-6 md:mt-10">
            <div className="relative">
              {sideItem?.map((side_item, index) => (
                <NavLink
                  key={index}
                  to={`/${route}/${side_item.link}`}
                  onClick={handleNavSide}
                  className={({ isActive }) =>
                    isActive
                      ? 'w-full font-thin text-[#0D6EFD] flex items-center gap-3 px-4 py-3 my-2 transition-colors duration-200 justify-start bg-[#0d6efd08] border-r-8 border-[#0D6EFD]'
                      : 'w-full font-thin text-[#808080] flex items-center gap-3 px-4 py-3 md:my-2 transition-colors duration-200 justify-start hover:text-blue-500 hover:bg-[#0d6efd08]'
                  }
                >
                  <span className="text-left">{side_item.logo}</span>
                  <span className="text-sm md:text-lg font-normal">
                    {side_item.name}
                  </span>
                </NavLink>
              ))}
            </div>
          </nav>
          <div className="flex-1 flex flex-col justify-between">
            <div className="flex-1 flex flex-col justify-center items-center">
              <p
                className="px-4 py-2 rounded-md"
                style={{
                  backgroundColor: getStatusColor(currentCohort?.status),
                }}
              >
                {capitalizeFirstLetter(currentCohort?.status)}
              </p>
            </div>
            <div>
              {currentUser?.type === 'learner' && <StageIcon />}
              <div className="mt-5 pb-4 flex flex-col gap-1 items-center justify-center">
                <p className="text-[#585858] text-sm md:text-[16px]">
                  {currentUser?.type === 'learner'
                    ? 'STUDENT '
                    : currentUser?.type === 'trainer'
                    ? 'TRAINER '
                    : 'ADMIN '}
                  ID
                </p>
                <p className="text-blue-ribbon-400 text-sm md:text-[16px]">
                  KC-
                  {currentUser?.type === 'learner'
                    ? 'STD'
                    : currentUser?.type === 'trainer'
                    ? 'TRN'
                    : 'ADM'}
                  -{currentUser?.id}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
