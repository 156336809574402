import React from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import InputWidget from '../../../components/global/InputWidget';
function GetProfileModal({ profileForm, setProfileForm }) {
  const [inputValue, setInputValue] = useState({
    firstname: '',
    lastname: '',
    dob: '',
    gender: 'male',
    marital: 'single',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const currentUser = JSON.parse(localStorage.getItem('user'));
  const navigate = useNavigate();

  const handleInputChange = ({ target }) => {
    setInputValue({ ...inputValue, [target.name]: target.value });
  };

  const handleSubmit = async (e) => {
    setIsLoading(true);
    const { firstname, lastname, dob, gender, marital } = inputValue;

    e.preventDefault();
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/profiles/`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${currentUser.access_token}`,
          },
          body: JSON.stringify({
            id: currentUser?.user?.id,
            first_name: firstname,
            last_name: lastname,
            date_of_birth: dob,
            gender: gender,
            marital_status: marital,
            owner_id: currentUser?.user?.id,
          }),
        }
      );
      if (!response.ok) {
        const errorData = await response.json();
        console.log(errorData.detail);
        if (Array.isArray(errorData)) {
          setErrorMsg(errorData.detail[0].msg);
          toast.error(errorData.detail[0].msg);
        } else {
          setErrorMsg(errorData.detail);
          toast.error(errorData.detail);
        }
        setIsLoading(false);
        setIsError(true);

        throw new Error(errorData);
      }

      toast.success('Sign up successful');
      if (currentUser) {
        if (currentUser.user?.type === 'learner') {
          navigate('/student/dashboard');
        } else if (currentUser.user?.type === 'trainer') {
          navigate('/instructor/dashboard');
        } else {
          navigate('/admin/dashboard');
        }
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error creating user:', error);
      setIsLoading(false);
      toast.error(error);
      setIsError(true);
    }
  };

  return (
    <>
      {profileForm ? (
        <>
          <div className="justify-center items-center w-full flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-full md:w-[50%] my-6 mx-auto">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative p-2 md:p-5 flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <h3 className="text-lg font-semibold text-center my-5">
                  Please complete you registration
                </h3>
                {/*body*/}
                <div className="relative p-6 flex-auto w-full">
                  {isError && (
                    <h1 className="text-red-600 text-sm text-center my-4">
                      {errorMsg}
                    </h1>
                  )}
                  <form action="" onSubmit={handleSubmit}>
                    {getProfileElement.map((data, idx) => (
                      <InputWidget
                        key={idx}
                        handleInputChange={handleInputChange}
                        form={inputValue}
                        label={data.label}
                        name={data.name}
                        placeholder={data.placeholder}
                        type={data.type}
                        method={data.method ? data.method : ''}
                        selectOption={
                          data.selectOption ? data.selectOption : ''
                        }
                      />
                    ))}
                    <button
                      type="submit"
                      className="w-full bg-blue-ribbon-500 rounded-md py-1 text-white cursor-pointer"
                    >
                      {'Complete Registration'}
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
      {isLoading && (
        <div className="z-50 absolute bg-black opacity-70 top-0 right-0 left-0 bottom-0 flex justify-center items-center">
          <div className="w-10 h-10 border-r-2 border-t-4 border-b-6 border-dotted border-gray-500 rounded-full animate-spin"></div>
        </div>
      )}
    </>
  );
}

export default GetProfileModal;

const getProfileElement = [
  {
    type: 'text',
    name: 'firstname',
    placeholder: 'Enter your first name',
    label: 'First Name',
  },
  {
    type: 'text',
    name: 'lastname',
    placeholder: 'Enter your last name',
    label: 'Last Name',
  },
  {
    type: 'date',
    name: 'dob',
    placeholder: 'DD/MM/YYY',
    label: 'Date of Birth',
  },
  {
    type: 'text',
    name: 'gender',
    placeholder: 'Select gender',
    label: 'Gender',
    method: 'select',
    selectOption: ['Male', 'Female', 'Other'],
  },
  //   {
  //     type: 'text',
  //     name: 'state',
  //     placeholder: 'Enter your state of origin',
  //     label: 'State of Origin',
  //   },
  //   {
  //     type: 'text',
  //     name: 'city',
  //     placeholder: 'Enter your city of origin',
  //     label: 'City of Origin',
  //   },
  //   {
  //     type: 'text',
  //     name: 'level',
  //     placeholder: 'Select level',
  //     label: 'Level',
  //     method: 'select',
  //     selectOption: ['Begginner', 'Intermediate'],
  //   },
  //   {
  //     type: 'text',
  //     name: 'track',
  //     placeholder: 'Select track',
  //     label: 'Track',
  //     method: 'select',
  //     selectOption: [
  //       'Front-End (Begginer)',
  //       'Front-End (React)',
  //       'Back-End (NodeJs-Begginner)',
  //       'Back-End (NodeJs-Intermediate)',
  //       'Andriod (Begginner)',
  //       'Andriod (Intermediate)',
  //       'UI/UX (Begginner)',
  //       'UX/UX (Intermediate)',
  //     ],
  //   },
  //   {
  //     type: 'text',
  //     name: 'employment',
  //     placeholder: 'Select employment',
  //     label: 'Employment Status',
  //     method: 'select',
  //     selectOption: ['Student', 'Unemployed', 'Self-Employed', 'Employed'],
  //   },
  //   {
  //     type: 'text',
  //     name: 'qualification',
  //     placeholder: 'Select qualification',
  //     label: 'Educational Qualification',
  //     method: 'select',
  //     selectOption: [
  //       'High School',
  //       'Undergraduate',
  //       'Postgraduate',
  //       'Masters',
  //       'PhD',
  //     ],
  //   },
  {
    type: 'text',
    name: 'marital',
    placeholder: 'Select marital status',
    label: 'Marital Status',
    method: 'select',
    selectOption: ['Single', 'Married', 'Others'],
  },
];
