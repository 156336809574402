const data = [
  {
    stage: 1,
    id: 1,
    total: 917,
    color: "#0D6EFD",
  },
  {
    stage: 2,
    id: 2,
    total: 1034,
    color: "#86B6FE",
  },
  {
    stage: 3,
    id: 3,
    total: 914,
    color: "#F9FD50",
  },
  {
    stage: 4,
    id: 4,
    total: 712,
    color: "#FDFEC5",
  },
  {
    stage: 5,
    id: 5,
    total: 243,
    color: "#00BD56",
  },
  {
    stage: 6,
    id: 6,
    total: 217,
    color: "#AAE9C7",
  },
  {
    stage: 7,
    id: 7,
    total: 277,
    color: "#E4E4E4",
  },
  {
    stage: 8,
    id: 8,
    total: 130,
    color: "#303030",
  },
];

export default data;
