import { fetchData } from '../hooks/api';

export const useAdminDashboardService = () => {
  const fetchDashboardData = async (workspace) => {
    return fetchData(`/admin/dashboard`, workspace);
  };

  const fetchStudentList = async (workspace) => {
    return fetchData(`/students`, workspace);
  };
  const fetchTrainerList = async (workspace) => {
    return fetchData(`/trainers`, workspace);
  };

  return { fetchDashboardData, fetchStudentList, fetchTrainerList };
};
