import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Logo from '../../assets/icons/logo.svg';
import AuthTheme from '../../components/authentication/AuthTheme';
import InputWidget from '../../components/global/InputWidget';
import { useGlobalContext } from '../../context/context';
import { useCategoryService } from '../../services/category.service';
import { useWorkspaceService } from '../../services/workspace.service';
import SignUp2Modal from './components/SignUpModal';

function InstructorSignUp() {
  const [form, setForm] = useState({
    firstname: '',
    lastname: '',
    username: '',
    email: '',
    phone: '',
    password: '',
    cpassword: '',
    dob: '',
    gender: 'male',
    country: 'Nigeria',
    city: '',
    level: 1,
    track: 1,
    employment: 'student',
    qualification: 'Undergraduate',
    marital: 'single',
  });
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [pageData, setPageData] = useState(formData.slice(0, 7));
  const [page, setPage] = useState(1);
  const {
    setGeneratedLevels,
    setGeneratedTracks,
    generatedLevels,
    generatedTracks,
  } = useGlobalContext();
  const { fetchCategory } = useCategoryService();
  const [allTracks, setAllTracks] = useState([]);
  const { fetchWorkspacesAvailable } = useWorkspaceService();
  const [availableCohorts, setAvailableCohorts] = useState([]);
  const [cohortId, setCohortId] = useState(0);
  const [selectedLevel, setSelectedLevel] = useState(0);
  const [stages, setStages] = useState([]);

  useEffect(() => {
    const fetchWSAvailable = async () => {
      const ws = await fetchWorkspacesAvailable();
      setAvailableCohorts(ws);
    };
    fetchWSAvailable();
  }, []);

  const fetchCat = async (workspace) => {
    const catLevels = await fetchCategory(workspace, 'level');
    const catTracks = await fetchCategory(workspace, 'track');
    const catStages = await fetchCategory(workspace, 'stage');
    setGeneratedLevels(catLevels);
    setAllTracks(catTracks);
    setStages(catStages);
  };

  useEffect(() => {
    const selectedCohort = availableCohorts.find((e) => e.slug === form.cohort);
    setCohortId(selectedCohort?.id);
  }, [form.cohort]);

  useEffect(() => {
    const selectedLevel = generatedLevels.find((e) => e.name === form.level);
    setSelectedLevel(selectedLevel);
  }, [form.level]);

  useEffect(() => {
    if (form.cohort) {
      fetchCat(form.cohort);
    }
  }, [form]);
  useEffect(() => {
    const filterTracks = () => {
      if (form.level !== undefined) {
        const tracks = allTracks.filter(
          (track) =>
            !!track.tags.find((tag) => selectedLevel?.tags.includes(tag))
        );
        setGeneratedTracks(tracks);
      }
    };
    filterTracks();
  }, [form.level, allTracks, selectedLevel]);

  const handleInputChange = ({ target }) => {
    setForm({ ...form, [target.name]: target.value });
  };

  const handleSubmit = async (e) => {
    const abortCont = new AbortController();
    e.preventDefault();

    if (form.password !== form.cpassword) {
      setIsError(true);
      setErrorMsg('Password not match');
      toast.error('Password not match');
      return;
    }
    if (page === 2) {
      setIsLoading(true);
      const {
        username,
        email,
        phone,
        password,
        firstname,
        lastname,
        dob,
        gender,
        marital,
        country,
        track,
        city,
        qualification,
        employment,
      } = form;
      const account = {
        id: 0,
        username: username,
        email: email.toLowerCase(),
        phone: phone,
        status: 'active',
        role: 'user',
        password: password,
      };
      const profile = {
        id: 0,
        first_name: firstname,
        last_name: lastname,
        date_of_birth: dob,
        gender: gender,
        marital_status: marital,
      };
      const info = {
        id: 0,
        employment_status: employment,
        qualification: qualification,
        survey_data: {
          country: country,
          city: city,
        },
      };
      const trainer = {
        id: 0,
        level_id: Number(selectedLevel?.id),
        track_id: Number(track),
      };

      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/open`, {
          signal: abortCont.signal,
          method: 'POST',

          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            workspace_id: cohortId,
            type: 'trainer',
            status: 'active',
            account,
            profile,
            info,
            trainer,
          }),
        });

        if (!response.ok) {
          const errorData = await response.json();
          if (Array.isArray(errorData)) {
            setErrorMsg(errorData.detail[0].msg);
            toast.error(errorData.detail[0].msg);
          } else {
            setErrorMsg(errorData.detail);
            toast.error(errorData.detail);
          }
          setIsLoading(false);
          setIsError(true);

          throw new Error(errorData);
        }

        toast.success('Sign up successful');
        setIsLoading(false);
        setSuccess(true);
      } catch (error) {
        if (error.name === 'AbortError') {
        } else {
          console.error('Error creating task:', error);
          setIsLoading(false);
          toast.error(error);
          setIsError(true);
        }
      }
    } else {
      setPage(2);
      setPageData(formData.slice(7));
    }
    return () => abortCont.abort();
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const token_expired = params.get('token_expired');
    if (token_expired === 'true') {
      toast.error('Session expired. Please login again to continue');
    }
  }, []);

  return (
    <AuthTheme>
      <div className="content-sign w-full md:p-10">
        <div className="flex flex-col items-center justify-center">
          <div className="w-20 h-[80px] rounded-lg bg-transparent shadow-md grid place-items-center">
            <img src={Logo} alt="kodecamp-logo" />
          </div>

          <h2 className="text-[#303030] text-lg font-[500] leading-[36px] tracking-[1px]">
            Create account
          </h2>
        </div>
        <div className="mt-10">
          {isError && (
            <h1 className="text-red-600 text-sm text-center mb-5">
              {errorMsg}
            </h1>
          )}
          <form action="" onSubmit={handleSubmit}>
            {page === 2 && (
              <>
                <div className="signup__form-div">
                  <label
                    className="signup__form-label block opacity-70 m-2"
                    htmlFor=""
                  >
                    Cohort
                  </label>
                  <select
                    className="border border-gray-500 text-sm text-grey-500 rounded-md mb-4 w-full px-2 h-10"
                    name="cohort"
                    id="cohort-select"
                    value={form.cohort}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">Select a cohort</option>
                    {availableCohorts?.map((e) => (
                      <option
                        key={e.id}
                        value={e.slug}
                        className="text-sm text-grey-500"
                      >
                        {e.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="signup__form-div">
                  <label
                    className="signup__form-label block opacity-70 m-2"
                    htmlFor=""
                  >
                    Skill level
                  </label>
                  <select
                    className="border border-gray-500 text-sm text-grey-500 rounded-md mb-4 w-full px-2 h-10"
                    name="level"
                    id="level-select"
                    value={form.level}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">Select your level</option>
                    {generatedLevels?.map((e) => (
                      <option
                        key={e.id}
                        value={e.name}
                        className="text-sm text-grey-500"
                      >
                        {e.title}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="signup__form-div">
                  <label
                    className="signup__form-label block opacity-70 m-2"
                    htmlFor=""
                  >
                    Track
                  </label>
                  <select
                    className="border border-gray-500 text-sm text-grey-500 rounded-md mb-4 w-full px-2 h-10"
                    name="track"
                    id="track-select"
                    value={form.track}
                    onChange={handleInputChange}
                    required
                  >
                    <option value="">Select track</option>
                    {generatedTracks?.map((e) => (
                      <option
                        key={e.id}
                        value={e.id}
                        className="text-sm text-grey-500"
                      >
                        {e.title}
                      </option>
                    ))}
                  </select>
                </div>
              </>
            )}
            {pageData.map((data, idx) => (
              <InputWidget
                key={idx}
                handleInputChange={handleInputChange}
                form={form}
                label={data.label}
                name={data.name}
                placeholder={data.placeholder}
                type={data.type}
                method={data.method ? data.method : ''}
                selectOption={data.selectOption ? data.selectOption : ''}
              />
            ))}
            <button
              type="submit"
              className="w-full bg-blue-ribbon-500 rounded-md py-1 text-white"
            >
              {page === 2 ? 'Create Account' : 'Next'}
            </button>

            <h1 className="text-center mt-4">
              Already have an account?{' '}
              <Link to="/" className="text-blue-ribbon-500">
                Sign In
              </Link>
            </h1>
          </form>
        </div>
      </div>
      {isLoading && (
        <div className="absolute bg-black opacity-70 top-0 right-0 left-0 bottom-0 flex justify-center items-center">
          <div className="w-10 h-10 border-r-2 border-t-4 border-b-6 border-dotted border-gray-500 rounded-full animate-spin"></div>
        </div>
      )}
      {success && <SignUp2Modal email={form.email} />}
    </AuthTheme>
  );
}

export default InstructorSignUp;

const formData = [
  {
    type: 'text',
    name: 'firstname',
    placeholder: 'Enter your first name',
    label: 'First Name',
  },
  {
    type: 'text',
    name: 'lastname',
    placeholder: 'Enter your last name',
    label: 'Last Name',
  },
  {
    type: 'text',
    name: 'username',
    placeholder: 'Enter your user name',
    label: 'Username',
  },
  {
    type: 'email',
    name: 'email',
    placeholder: 'Enter your email',
    label: 'Email',
  },
  {
    type: 'text',
    name: 'phone',
    placeholder: 'Enter your phone number',
    label: 'Phone Number',
  },
  {
    type: 'password',
    name: 'password',
    placeholder: 'Enter your password',
    label: 'Password',
  },
  {
    type: 'password',
    name: 'cpassword',
    placeholder: 'Enter password again',
    label: 'Confirm Password',
  },
  {
    type: 'date',
    name: 'dob',
    placeholder: 'DD/MM/YYY',
    label: 'Date of Birth',
  },
  {
    type: 'text',
    name: 'country',
    placeholder: 'Enter your country of origin',
    label: 'Country of Origin',
  },
  {
    type: 'text',
    name: 'city',
    placeholder: 'Enter your city of origin',
    label: 'City of Origin',
  },

  {
    type: 'text',
    name: 'employment',
    placeholder: 'Select employment',
    label: 'Employment Status',
    method: 'select',
    selectOption: [
      {
        label: 'Student',
        value: 'student',
      },
      {
        label: 'Unemployed',
        value: 'unemployed',
      },
      {
        label: 'Self-Employed',
        value: 'self-Employed',
      },
      {
        label: 'Employed',
        value: 'employed',
      },
    ],
  },
  {
    type: 'text',
    name: 'qualification',
    placeholder: 'Select qualification',
    label: 'Educational Qualification',
    method: 'select',
    selectOption: [
      {
        label: 'High School',
        value: 'high-School',
      },
      {
        label: 'Undergraduate',
        value: 'undergraduate',
      },
      {
        label: 'Postgraduate',
        value: 'postgraduate',
      },
      {
        label: 'Masters',
        value: 'masters',
      },
      {
        label: 'PhD',
        value: 'phD',
      },
    ],
  },
  {
    type: 'text',
    name: 'marital',
    placeholder: 'Select marital status',
    label: 'Marital Status',
    method: 'select',
    selectOption: [
      {
        label: 'Single',
        value: 'single',
      },
      {
        label: 'Married',
        value: 'married',
      },
      {
        label: 'Other',
        value: 'other',
      },
    ],
  },
];
