import { useEffect, useState } from 'react';

import doesTrainerMatchSearch from '../../../../components/global/SearchHelper';
import { useGlobalContext } from '../../../../context/context';
import { RefetchButton } from '../../../../context/Refetch';
import useFetch from '../../../../hooks/useFetch';
import AdminTrainerRow from './AdminTrainerRow';

const TrainerList = () => {
  const { tracks, levels, adminSearchValue, workspace } = useGlobalContext();

  const [trainersList, setTrainersList] = useState([]);
  const [filter, setFilter] = useState({
    level: [],
    track: [],
    stage: [],
  });

  const offset = 0;
  const limit = 1000;
  const {
    data: trainers,
    isPending,
    refetch: refetchTrainers,
  } = useFetch(`/trainers?offset=${offset}&limit=${limit}`, workspace);

  useEffect(() => {
    setFilter({
      level: Object.values(levels).map((level) => level.id) || [],
      track: Object.values(tracks).map((track) => track.id) || [],
    });
  }, [levels, tracks]);

  useEffect(() => {
    setTrainersList(
      trainers?.filter((item, idx) => {
        return (
          filter?.level?.includes(item.trainer?.level_id) &&
          filter?.track?.includes(item.trainer?.track_id)
        );
      })
    );
  }, [trainers, filter]);

  return (
    <div className="overflow-x-hidden mb-16">
      <div className="flex justify-between">
        <h3 className="text-2xl font-medium">Trainers</h3>
        <div className="flex items-center space-x-4"></div>
      </div>
      <div className="overflow-x-hidden">
        <div className="lg:w-full lg:overflow-x-hidden overflow-x-scroll">
          <div className="flex mt-4 space-x-5 text-[#0D6EFD] text-sm">
            <p
              key={0}
              className={`cursor-pointer ${
                filter?.level?.length === Object.keys(levels)?.length
                  ? 'text-gray-500 border-b-2 border-black'
                  : 'text-[#0D6EFD]'
              } `}
              onClick={(e) =>
                setFilter({
                  ...filter,
                  level: Object.values(levels).map((level) => level.id) || [],
                })
              }
            >
              All
            </p>
            {Object.values(levels).map((level, index) => (
              <p
                key={index}
                className={`cursor-pointer ${
                  filter?.level?.length !== Object.keys(levels)?.length &&
                  filter?.level?.includes(level.id)
                    ? 'text-gray-500 border-b-2 border-black'
                    : 'text-[#0D6EFD]'
                } `}
                onClick={(e) => setFilter({ ...filter, level: [level.id] })}
              >
                {level.title}
              </p>
            ))}
          </div>
        </div>
        <div className="lg:w-full lg:overflow-x-hidden overflow-x-scroll">
          <div className="flex my-4 space-x-5 text-[#0D6EFD] text-sm">
            <p
              key={0}
              className={`cursor-pointer ${
                filter?.track?.length === Object.keys(tracks)?.length
                  ? 'text-gray-500 border-b-2 border-black'
                  : 'text-[#0D6EFD]'
              } `}
              onClick={(e) =>
                setFilter({
                  ...filter,
                  track: Object.values(tracks).map((track) => track.id) || [],
                })
              }
            >
              All
            </p>
            {Object.values(tracks)?.map((track, index) => (
              <p
                key={index}
                className={`cursor-pointer ${
                  filter?.track?.length !== Object.keys(tracks)?.length &&
                  filter?.track?.includes(track.id)
                    ? 'text-gray-500 border-b-2 border-black'
                    : 'text-[#0D6EFD]'
                } `}
                onClick={(e) => setFilter({ ...filter, track: [track.id] })}
              >
                {track.title}
              </p>
            ))}
          </div>
        </div>
        <p className="text-center my-6">{trainersList?.length} trainers</p>
        <div className="overflow-x-auto w-full">
          <table className="min-w-full border-separate border-spacing-y-4 whitespace-nowrap">
            <thead>
              <tr className="text-[#0D6EFD] font-light bg-[#0D6EFD08]/5">
                <th className="py-4 px-2 rounded-l-lg">
                  <input type="checkbox" name="" id="" />
                </th>
                {tableHead?.map((e, idx) => (
                  <th
                    className={`${
                      idx === tableHead.length && 'rounded-l-lg'
                    } py-4 px-2 text-start`}
                    key={idx}
                  >
                    {e}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {trainersList
                ?.filter((e) => doesTrainerMatchSearch(e, adminSearchValue))
                ?.map((list, idx) => (
                  <AdminTrainerRow list={list} key={idx} />
                ))}
            </tbody>
          </table>
        </div>
        {isPending && (
          <div className="flex justify-center">
            <div className="w-6 h-6 border-r-2 border-t-4 border-b-6 border-dotted border-gray-500 rounded-full animate-spin"></div>
          </div>
        )}
      </div>
      <div className="flex lg:justify-end justify-start w-full"></div>
      <RefetchButton name="AdminTrainerRefetch" functions={[refetchTrainers]} />
    </div>
  );
};
export default TrainerList;

const tableHead = ['ID', 'Trainer Name', 'Gender', 'Track', 'Level'];
