import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import cancelIcon from '../../../assets/icons/cancel2025.svg';
import fileIcon from '../../../assets/icons/file2024.svg';
import { useGlobalContext } from '../../../context/context';
import { getSubmissionScore } from '../../../context/getSubmissionScore';
import {
  promoColor,
  promoText,
  scoreColor,
  scoreColor2,
  scoreText,
} from '../../../context/submissionStatus';
import { useTaskSubmissionService } from '../../../services/task.service';
import LinkPreview from '../LinkPreview';

const TaskDetailsStudent = ({ task }) => {
  const { versionOne, userProfile, stages, workspace } = useGlobalContext();
  const currentAccount = JSON.parse(localStorage.getItem('account'));
  const { id } = useParams();
  const [linkValues, setLinkValues] = useState(
    Array(task?.submission_links?.length).fill('')
  );
  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState('Not Submitted');
  const ref = useRef(null);
  const [showModal, setShowModal] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { submitTask } = useTaskSubmissionService(id);

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsFilePicked(true);
  };

  const handleInputChange = (index, value) => {
    const updatedValues = [...linkValues];
    updatedValues[index] = value;
    setLinkValues(updatedValues);
  };

  const handleSubmission = async () => {
    if (isLoading) return;
    setShowModal(false);
    setIsLoading(true);
    try {
      await submitTask({ submission_urls: linkValues });
      setIsLoading(false);
      setSubmissionStatus('Submitted');
      toast.success('Task Submitted successfully');
    } catch (error) {
      toast.error(error + '');
      console.error('Error submitting task:', error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (task?.submissions?.length > 0) {
      if (task.submissions[0]?.status === 'graded') {
        setSubmissionStatus('Graded');
      } else {
        setSubmissionStatus('Submitted');
      }
    } else {
      setSubmissionStatus('Not Submitted');
    }
  }, [task?.submissions]);

  useEffect(() => {
    if (task?.submissions[0]?.submission_urls.length > 0) {
      setLinkValues(task?.submissions[0]?.submission_urls);
    }
  }, [task]);

  return (
    <div>
      <div className="text-2xl bg-white rounded-xl p-5 self-start">
        <div className="flex items-start justify-between">
          <p className="text-lg">Status</p>
          <div className="pl-7">
            <p className={`${scoreColor(task?.submissions)} text-sm truncate`}>
              {scoreText(task?.submissions)}
            </p>

            {submissionStatus === 'Graded' && (
              <p
                className={`${promoColor(
                  task,
                  userProfile?.student,
                  stages
                )} text-[10px] truncate`}
              >
                {promoText(task, userProfile?.student, stages)}
              </p>
            )}
          </div>
        </div>
        <div className="mt-3">
          <p className={`text-sm ${scoreColor2(task)}`}>
            {getSubmissionScore(task?.submissions) ?? '-'}/
            <span className="text-gray-600">{task.points}</span>
          </p>
        </div>

        <div className="flex flex-col gap-5 mt-6">
          <div className="space-y-2">
            {task?.submission_links?.map((e, idx) => (
              <div key={idx} className="flex flex-col">
                <label className="text-lg text-[#808080] font-thin">{e}</label>
                {submissionStatus !== 'Submitted' &&
                submissionStatus !== 'Graded' ? (
                  <input
                    className="border-2 text-sm rounded-lg w-full px-2 h-[54px] placeholder:text-[14px]"
                    type="url"
                    placeholder={`Submit a valid URL`}
                    value={linkValues[idx]}
                    onChange={(e) => handleInputChange(idx, e.target.value)}
                  />
                ) : (
                  <a
                    className="text-sm break-all text-blue-500"
                    target={'_blank'}
                    href={linkValues[idx]}
                    rel="noreferrer"
                  >
                    {linkValues[idx]}
                  </a>
                )}
              </div>
            ))}
          </div>
          {/* attachment start */}

          {!versionOne && (
            <div className="flex flex-col gap-1">
              <p className="text-lg text-[#808080] font-thin">Attachment</p>
              <div
                className={
                  isFilePicked
                    ? 'hidden border-2 border-dashed border-gray-300 rounded-lg w-full px-2 h-[54px] '
                    : 'border-2 border-dashed border-gray-300 rounded-lg w-full px-2 h-[54px] '
                }
              >
                <label id="customLabel" className="cursor-pointer" ref={ref}>
                  <input
                    id="inputFile"
                    className="cursor-pointer mb-0 z-0 hidden"
                    type="file"
                    onChange={changeHandler}
                  />
                  <p className="cursor-pointer">
                    <span className="opacity-60 text-sm">Drag & drop or </span>
                    <span className="text-blue-600 underline-offset-8 text-sm">
                      browse files
                    </span>
                  </p>
                </label>
              </div>

              <div
                className={
                  isFilePicked
                    ? 'border-2 h-[70px] w-full rounded-2xl border-gray-400 flex'
                    : 'hidden'
                }
              >
                <div>
                  <img className="" src={fileIcon} alt="file icon" />
                </div>
                <div className="m-4">
                  {isFilePicked && (
                    <p className="opacity-70">{selectedFile.name}</p>
                  )}
                </div>
                <div>
                  <img
                    className="mt-4 ml-4 cursor-pointer"
                    src={cancelIcon}
                    alt="cancel"
                  />
                </div>
              </div>
            </div>
          )}

          <div>
            {submissionStatus !== 'Submitted' &&
              submissionStatus !== 'Graded' && (
                <button
                  className={
                    linkValues.filter((value) => value.trim() !== '').length ===
                    task?.submission_links.length
                      ? 'bg-blue-500 rounded-lg w-full h-[40px] text-base font-bold text-white my-4'
                      : 'bg-blue-500 rounded-lg w-full h-[40px] text-base font-bold text-white my-4 opacity-30 cursor-not-allowed'
                  }
                  disabled={
                    linkValues.filter((value) => value.trim() !== '').length !==
                      task?.submission_links.length && isLoading
                  }
                  onClick={() => setShowModal(true)}
                >
                  {isLoading ? 'Submitting...' : 'Submit'}
                </button>
              )}
          </div>
        </div>
      </div>
      <SubmissionModal
        handleSubmission={handleSubmission}
        setShowModal={setShowModal}
        showModal={showModal}
      />
    </div>
  );
};

export default TaskDetailsStudent;

function SubmissionModal({ handleSubmission, showModal, setShowModal }) {
  return (
    <>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <p className="my-4 text-slate-500 text-lg leading-relaxed text-center">
                    Are you sure you want to submit
                  </p>
                  <p className="text-[14px] text-center mt-4">
                    You won't be able to make changes after this
                  </p>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="bg-blue-500 text-white font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={handleSubmission}
                  >
                    Yes
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}
