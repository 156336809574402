import { useState, useEffect, useCallback } from 'react';

const useFetch = (endpoint, workspace = '') => {
  const [data, setData] = useState(null);
  const [isPending, setIsPending] = useState(true);
  const [error, setError] = useState(null);
  const [accountData, setaccountData] = useState(null);

  useEffect(() => {
    setaccountData(JSON.parse(localStorage.getItem('account')));
  }, []);

  const fetchData = useCallback(async () => {
    if (accountData) {
      if (new Date() > new Date(accountData?.expires)) {
        localStorage.clear();
        window.location.replace(`/?token_expired=${encodeURIComponent(true)}`);
        return;
      }

      setIsPending(true);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/${workspace}${endpoint}`,
          {
            headers: {
              Authorization: `Bearer ${accountData?.access_token}`,
            },
          }
        );

        if (!response.ok) {
          const errorData = await response.json();
          if (Array.isArray(errorData)) {
            setError(errorData.detail[0].msg);
          } else {
            setError(errorData.detail);
          }
          throw new Error(errorData);
        }

        const responseData = await response.json();
        setData(responseData);
        setError(null);
      } catch (err) {
        if (err.name === 'AbortError') {
          console.error('Fetch aborted');
        } else {
          console.error(err);
        }
      } finally {
        setIsPending(false);
      }
    }
  }, [accountData, endpoint, workspace]);

  useEffect(() => {
    fetchData();
  }, [endpoint, accountData, workspace]);

  const refetch = () => {
    fetchData();
  };

  return { data, isPending, error, refetch };
};

export default useFetch;
