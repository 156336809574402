import { useEffect, useState } from 'react';
import { Ban } from '../../../assets';
import { FaBan, FaTimes } from 'react-icons/fa';
import { GiSaveArrow } from 'react-icons/gi';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { Link, useNavigate } from 'react-router-dom';
import useFetch from '../../../hooks/useFetch';
import AvatarWidget from '../AvatarWidget';
import { useGlobalContext } from '../../../context/context';
import doesTrainerMatchSearch from '../SearchHelper';
import { RefetchButton } from '../../../context/Refetch';

const StudentList = ({ route }) => {
  const { stages, tracks, levels, setOverall, globalSearch, workspace } =
    useGlobalContext();

  const navigate = useNavigate();
  const [studentList, setStudentList] = useState([]);
  const [openSideMenu, setOpenSideMenu] = useState(null);
  const [sortColumn, setSortColumn] = useState('Stage');
  const [sortOrder, setSortOrder] = useState('asc');
  const [deepOption, setDeepOption] = useState({
    stage: [],
    certificate: '',
  });
  const [openFilter, setOpenFilter] = useState(false);
  const [stageSelected, setStageSelected] = useState([]);
  const [filter, setFilter] = useState({
    level: [],
    track: [],
    stage: [],
  });

  const offset = 0;
  const limit = 1000;
  const {
    data: students,
    isPending,
    refetch: refetchStudents,
  } = useFetch(`/students?offset=${offset}&limit=${limit}`, workspace);

  const { data: scores, refetch: refetchScores } = useFetch(
    `/submissions/scores?offset=${offset}&limit=${limit}`,
    workspace
  );

  let { data: certificates } = useFetch(
    '/admin/profiles/certificates',
    workspace
  );

  certificates = certificates?.reduce((acc, curr) => {
    acc[curr.owner_id] = curr;
    return acc;
  }, {});

  useEffect(() => {
    setOverall((prevOverall) => {
      const scoresObj = scores?.reduce((acc, crr) => {
        acc[crr.student_id] = crr;
        return acc;
      }, {});
      return { ...prevOverall, ...scoresObj };
    });
  }, [scores]);

  useEffect(() => {
    setFilter({
      level: Object.values(levels).map((level) => level.id) || [],
      track: Object.values(tracks).map((track) => track.id) || [],
      stage: Object.values(stages).map((stage) => stage.id) || [],
    });
  }, [levels, tracks, stages]);

  useEffect(() => {
    setStudentList(
      students?.filter((item) => {
        const levelMatch = filter?.level?.includes(item.student?.level_id);
        const trackMatch = filter?.track?.includes(item.student?.track_id);
        const stageMatch = filter?.stage?.includes(item.student?.stage_id);
        const certificateMatch =
          deepOption.certificate === 'uploaded'
            ? !!certificates[item.id]
            : deepOption.certificate === 'not uploaded'
            ? !certificates[item.id]
            : true;

        return levelMatch && trackMatch && stageMatch && certificateMatch;
      })
    );
  }, [students, filter, deepOption]);

  const handleSort = (column) => {
    if (column === sortColumn) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortOrder('asc');
    }
  };

  const [checkedList, setCheckedList] = useState([]);

  const handleSelectedStage = (checked, stage) => {
    if (checked) {
      setStageSelected((prev) => [...prev, stage]);

      setFilter((prev) => ({
        ...prev,
        stage: [...stageSelected.map((e) => e.id)],
      }));
    } else {
      setStageSelected((prev) => prev.filter((e) => e.id !== stage.id));
      setFilter((prev) => ({
        ...prev,
        stage: prev.stage.filter((e) => e.id !== stage.id),
      }));
    }
  };

  const handleSelect = (secOption) => {
    setDeepOption({
      ...deepOption,
      certificate: secOption,
    });
  };

  useEffect(() => {
    if (stageSelected.length < 1) {
      setFilter((prev) => ({
        ...prev,
        stage: Object.values(stages).map((stage) => stage.id) || [],
      }));
    } else {
      setFilter((prev) => ({
        ...prev,
        stage: stageSelected.map((e) => e.id),
      }));
    }
  }, [stageSelected, stages]);

  return (
    <div className="overflow-x-hidden mb-16 relative">
      <div className="flex justify-between">
        <h3 className="text-2xl font-medium">Students</h3>
        <div className="border-2 w-36 p-2 rounded-md transition-all ease-in-out duration-500">
          <p onClick={() => setOpenFilter(!openFilter)}>Filter By:</p>
          {openFilter && (
            <div className="transition-all ease-in-out duration-500 absolute border z-50 bg-white py-2 w-36">
              <ul className="px-2 space-y-1">
                <li className="font-bold text-sm">Stages</li>
                {Object.values(stages)
                  ?.sort((a, b) => a.title.localeCompare(b.title))
                  .map((stage, id) => (
                    <li
                      key={id}
                      className="flex items-center space-x-3 border-b font-light px-2 text-[12px]"
                    >
                      <input
                        type="checkbox"
                        name=""
                        id=""
                        className="w-[10px] h-[10px]"
                        checked={stageSelected?.some(
                          (item) => item.id === stage.id
                        )}
                        onChange={(e) =>
                          handleSelectedStage(e.target.checked, stage)
                        }
                      />
                      <p>{stage.title}</p>
                    </li>
                  ))}
                {filterOption.map((opt) => {
                  return (
                    <li key={opt.id} className="font-bold">
                      {opt.name}
                      <ul className="space-y-1">
                        {opt.options.map((secOption, idx) => {
                          return (
                            <li
                              key={idx}
                              className="border-b font-light px-2 text-[12px]"
                            >
                              <input
                                type={
                                  opt.name === 'stage' ? 'checkbox' : 'radio'
                                }
                                name="opt"
                                id=""
                                onChange={() => handleSelect(secOption, opt)}
                                className="mr-3 w-[10px] h-[10px]"
                              />
                              {secOption}
                            </li>
                          );
                        })}
                      </ul>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>
        {route === 'admin' && (
          <div className="flex items-center space-x-4">
            {checkedList.length > 0 ? (
              <div
                className="flex justify-center items-center space-x-4 text-gray-600 cursor-pointer"
                onClick={() => setCheckedList([])}
              >
                <FaTimes />
                <p>{checkedList.length} selected</p>
                <div className="bg-red-600 flex justify-center items-center p-2.5 cursor-pointer text-white rounded-lg">
                  <FaBan />
                  <p className="ml-2 lg:flex hidden">Banned Student</p>
                </div>{' '}
              </div>
            ) : (
              <div className="flex">
                <Link
                  to={`/${route}/student-list/promotion/demote`}
                  className="border border-[#0D6EFD] bg-transparent text-sm text-[#0D6EFD] flex justify-center items-center rounded-md py-2 lg:px-3 px-2"
                >
                  <GiSaveArrow className="" />
                  <p className="ml-2 lg:flex hidden">View Demotion List</p>
                </Link>
              </div>
            )}
            <div
              className="flex items-center space-x-2 text-white text-sm bg-blue-ribbon-500 rounded-md hover:bg-blue-ribbon-300 py-2 px-2 cursor-pointer"
              onClick={() =>
                navigate(`/${route}/student-list/promotion/promote`)
              }
            >
              <GiSaveArrow className="rotate-180" />
              <p className="">View Promotion List</p>
            </div>
          </div>
        )}
      </div>
      <div className="overflow-x-hidden">
        {route === 'admin' && (
          <>
            <div className="lg:w-full lg:overflow-x-hidden overflow-x-scroll">
              <div className="flex mt-4 space-x-5 text-[#0D6EFD] text-sm">
                <p
                  key={0}
                  className={`cursor-pointer ${
                    filter?.level?.length === Object.keys(levels)?.length
                      ? 'text-gray-500 border-b-2 border-black'
                      : 'text-[#0D6EFD]'
                  } `}
                  onClick={(e) =>
                    setFilter({
                      ...filter,
                      level:
                        Object.values(levels).map((level) => level.id) || [],
                    })
                  }
                >
                  All
                </p>
                {Object.values(levels).map((level, index) => (
                  <p
                    key={index}
                    className={`cursor-pointer ${
                      filter?.level?.length !== Object.keys(levels)?.length &&
                      filter?.level?.includes(level.id)
                        ? 'text-gray-500 border-b-2 border-black'
                        : 'text-[#0D6EFD]'
                    } `}
                    onClick={(e) => setFilter({ ...filter, level: [level.id] })}
                  >
                    {level.title}
                  </p>
                ))}
              </div>
            </div>
            <div className="lg:w-full lg:overflow-x-hidden overflow-x-scroll">
              <div className="flex my-4 space-x-5 text-[#0D6EFD] text-sm">
                <p
                  key={0}
                  className={`cursor-pointer ${
                    filter?.track?.length === Object.keys(tracks)?.length
                      ? 'text-gray-500 border-b-2 border-black'
                      : 'text-[#0D6EFD]'
                  } `}
                  onClick={(e) =>
                    setFilter({
                      ...filter,
                      track:
                        Object.values(tracks).map((track) => track.id) || [],
                    })
                  }
                >
                  All
                </p>
                {Object.values(tracks)?.map((track, index) => (
                  <p
                    key={index}
                    className={`cursor-pointer ${
                      filter?.track?.length !== Object.keys(tracks)?.length &&
                      filter?.track?.includes(track.id)
                        ? 'text-gray-500 border-b-2 border-black'
                        : 'text-[#0D6EFD]'
                    } `}
                    onClick={(e) => setFilter({ ...filter, track: [track.id] })}
                  >
                    {track.title}
                  </p>
                ))}
              </div>
            </div>
          </>
        )}
        <p className="text-center my-6">{studentList?.length} students</p>
        <div className="overflow-x-auto w-full">
          <table className="min-w-full border-separate border-spacing-y-4 whitespace-nowrap">
            <thead>
              <tr className="text-[#0D6EFD] font-light bg-[#0D6EFD08]/5">
                <th className="py-4 px-2 rounded-l-lg">
                  <input type="checkbox" name="" id="" />
                </th>
                {tableHead?.map((e, idx) => (
                  <th
                    className={`${
                      idx === tableHead.length && 'rounded-l-lg'
                    } py-4 px-2 text-start`}
                    key={idx}
                    onClick={() => handleSort(e)}
                  >
                    {e}
                    {sortColumn === e && (
                      <span>{sortOrder === 'asc' ? ' ▲' : ' ▼'}</span>
                    )}
                  </th>
                ))}
                <BsThreeDotsVertical className="opacity-0" />
              </tr>
            </thead>
            <tbody>
              {studentList
                ?.filter((e) => doesTrainerMatchSearch(e, globalSearch))
                ?.sort((a, b) => {
                  const aValue = stages[a.student?.stage_id]?.title ?? '';
                  const bValue = stages[b.student?.stage_id]?.title ?? '';

                  if (sortOrder === 'asc') {
                    return aValue.localeCompare(bValue);
                  } else {
                    return bValue.localeCompare(aValue);
                  }
                })
                .map((list, idx) => (
                  <StudentListRow
                    list={list}
                    key={idx}
                    certs={certificates}
                    route={route}
                    openSideMenu={openSideMenu}
                    setOpenSideMenu={setOpenSideMenu}
                  />
                ))}
            </tbody>
          </table>
        </div>
        {isPending && (
          <div className="flex justify-center">
            <div className="w-6 h-6 border-r-2 border-t-4 border-b-6 border-dotted border-gray-500 rounded-full animate-spin"></div>
          </div>
        )}
      </div>
      <div className="flex lg:justify-end justify-start w-full"></div>
      <RefetchButton
        name="studentListRefetch"
        functions={[refetchScores, refetchStudents]}
      />
    </div>
  );
};
export default StudentList;

const StudentListRow = ({
  list,
  certs,
  route,
  openSideMenu,
  setOpenSideMenu,
}) => {
  const { stages, tracks, overall } = useGlobalContext();

  const navigate = useNavigate();

  const toggleSideMenu = (id) => {
    setOpenSideMenu((prevId) => (prevId === id ? null : id));
  };

  return (
    <tr
      className="bg-white relative text-gray-500 text-sm hover:bg-blue-ribbon-100 items-center cursor-pointer transition-all ease-in-out duration-500"
      onClick={() =>
        route === 'admin' ? navigate(`/${route}/student/${list.id}`) : {}
      }
    >
      <td className="py-4 px-2 rounded-l-lg">
        <input type="checkbox" name="" id="" className="" />
      </td>
      <td className="py-4 px-2">{list?.id}</td>
      <td className={`py-4 px-2 ${certs?.[list?.id] && ''}`}>
        <div className="flex items-center space-x-2">
          <AvatarWidget
            avatarUrl={list?.profile?.avatar}
            w={'20px'}
            h={'20px'}
            text={'9px'}
            owner={list?.profile}
          />
          <span className="inline">
            {list?.profile?.first_name + ' ' + list?.profile?.last_name}
          </span>
        </div>
      </td>
      <td className="py-4 px-2">
        {list?.profile?.gender.slice(0, 1).toUpperCase() +
          list?.profile?.gender.slice(1)}
      </td>
      <td className="py-4 px-2">{tracks[list?.student?.track_id]?.title}</td>
      <td className="py-4 px-2">{stages[list?.student?.stage_id]?.title}</td>
      <td className="py-4 px-2">
        <div className="text-right pr-8">
          {overall?.[list?.id]?.total_score ?? 0}
          &nbsp;/&nbsp;
          {overall?.[list?.id]?.total_points ?? 0}
        </div>
      </td>
      <td
        className={`${
          certs?.[list?.id] ? 'text-[#00BD56]' : 'text-red-500'
        } py-4 px-2 text-[13px] rounded-r-lg flex flex-col`}
      >
        {certs?.[list?.id] ? 'Uploaded' : 'Not Uploaded'}
        <br></br>
        {certs?.[list?.id] && (
          <p
            className="text-[10px] border border-blue-ribbon-500 text-blue-ribbon-500 cursor-pointer inline p-1 hover:bg-blue-ribbon-500 hover:text-white z-50 rounded-md"
            onClick={(e) => {
              e.stopPropagation();
              window.open(
                process.env.REACT_APP_BASE_URL + certs?.[list?.id].url,
                '_blank'
              );
            }}
          >
            View Certificate
          </p>
        )}
      </td>
      <td>
        <BsThreeDotsVertical
          onClick={() => toggleSideMenu(list.id)}
          className="text-lg"
        />
      </td>
      {openSideMenu === list?.id ? (
        <ul className="absolute right-8 top-4 shadow-md bg-white rounded-md z-50 text-[12px]">
          <li
            className="py-1 px-2 hover:bg-blue-ribbon-100 transition-all ease-in-out duration-300 rounded-md"
            onClick={() => navigate(`/${route}/student/${list.id}`)}
          >
            View Student
          </li>
          <li
            className="py-1 px-2 hover:bg-blue-ribbon-100 transition-all ease-in-out duration-300 rounded-md"
            onClick={() =>
              certs?.[list?.id]
                ? window.open(
                    process.env.REACT_APP_BASE_URL + certs[list.id].url,
                    '_blank'
                  )
                : window.alert('Certificate not uploaded')
            }
          >
            View Certificate
          </li>
        </ul>
      ) : (
        ''
      )}
    </tr>
  );
};

const tableHead = [
  'ID',
  'User',
  'Gender',
  'Track',
  'Stage',
  'Overall Grade',
  'Certificate',
];

const filterOption = [
  {
    name: 'certificate',
    id: 2,
    options: ['uploaded', 'not uploaded', 'all'],
  },
];
