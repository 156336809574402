import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Progress from '../../../components/global/Progress';
import CalendarWidget from '../../../components/global/calender/CalendarWidget';
import { formatDateTime } from '../../../components/global/task/TaskDetailsSUBMISSION';
import { RefetchButton } from '../../../context/Refetch';
import { useGlobalContext } from '../../../context/context';
import {
  calculateWeekCount,
  calculateWeekPercentage,
} from '../../../context/datecalculate';
import { getSubmissionScore } from '../../../context/getSubmissionScore';
import {
  promoColor,
  promoText,
  scoreColor,
  scoreColor2,
  scoreText,
} from '../../../context/submissionStatus';

import JoinCohortModal from '../../../components/joinCohort/JoinCohortModal';
import { useCohortJoin } from '../../../components/joinCohort/useCohortJoin';
import { useSseContext } from '../../../context/sse';
import useFetch from '../../../hooks/useFetch';
import { useProfileService } from '../../../services/profile.service';
import { getCountOfStages } from '../../../utils/learningProgressInfo';

const StudentDashboard = () => {
  const {
    levels,
    stages,
    tracks,
    userProfile,
    workspace,
    oldStdRegForm,
    setOldStdRegForm,
    hideCohortJoinModal,
    overall,
    setOverall,
    setUserProfile,
  } = useGlobalContext();
  const { addMessageListener } = useSseContext();
  const currentUser = JSON.parse(localStorage.getItem('user'));
  const accountData = JSON.parse(localStorage.getItem('account'));
  const { fetchProfile } = useProfileService();

  const {
    form,
    hasNewCohort,
    isSuccess,
    handleContinue,
    handleFormSubmit,
    handleCancelJoinWorkspace,
    availableCohorts,
    generatedLevels,
    generatedTracks,
    handleInputChange,
    cohortObj,
  } = useCohortJoin('learner');

  let navigate = useNavigate();
  const offset = 0;
  const limit = 100;
  const {
    data: tasks,
    isPending,
    error,
    refetch: refetchTasks,
  } = useFetch(`/tasks?offset=${offset}&limit=${limit}`, workspace);

  const { data: scores, refetch: refetchScores } = useFetch(
    `/submissions/scores?offset=${offset}&limit=${limit}`,
    workspace
  );

  useEffect(() => {
    const scoresObj = scores?.reduce((acc, crr) => {
      acc[crr.student_id] = crr;
      return acc;
    }, {});
    setOverall(scoresObj);
  }, [scores]);

  useEffect(() => {
    addMessageListener(['demotion', 'promotion'], async (data) => {
      // Refresh if current user is one of those promoted.
      if (data?.affectedIds?.includes(userProfile?.id)) {
        refetchTasks();
        const profile = await fetchProfile('default');
        setUserProfile(profile);
        localStorage.setItem('local-profile', JSON.stringify(profile));
      }
    });
    addMessageListener(['task-created', 'task-updated'], (data) => {
      // Refresh if current user is in the level, track and stage of the updated or created task.
      if (
        data.task?.level_id === userProfile?.student?.level_id &&
        data.task?.track_id === userProfile?.student?.track_id &&
        data.task?.stage_id === userProfile?.student?.stage_id
      ) {
        refetchTasks();
        refetchScores();
      }
    });

    addMessageListener(['submissions-graded'], (data) => {
      // Refresh if current user is one of those graded
      if (data.students?.includes(userProfile?.id)) {
        refetchTasks();
        refetchScores();
      }
    });
  }, [userProfile]);

  useEffect(() => {
    if (accountData && accountData?.access_token) {
      const timeoutId = setInterval(() => {
        refetchTasks();
        refetchScores();
      }, 600000); // 600000 milliseconds = 10 minute

      return () => {
        // Clean up the timeout function when the component unmounts
        clearInterval(timeoutId);
      };
    }
  }, [accountData]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        refetchTasks();
        refetchScores();
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [workspace]);

  return (
    <>
      <div className="flex justify-between items-center">
        <h1 className="font-medium text-2xl">Dashboard</h1>
        {currentUser?.type === 'trainer' ? (
          <div className="flex items-center justify-between text-sm text-gray-700 space-x-2 py-3">
            <p className="">
              {tracks[userProfile?.trainer?.track_id]?.title +
                ' ' +
                currentUser?.type?.charAt(0).toUpperCase() +
                currentUser?.type.slice(1)}{' '}
            </p>
            <p className="w-[4px] h-[4px] rounded-full bg-black"></p>
            <p className="">{levels[userProfile?.trainer?.level_id]?.title}</p>
          </div>
        ) : currentUser?.type === 'learner' ? (
          <div className="flex items-center justify-between text-xs text-gray-500 space-x-2 py-3">
            <p className="">
              {tracks?.[userProfile?.student?.track_id]?.title +
                ' ' +
                currentUser?.type?.charAt(0).toUpperCase() +
                currentUser?.type.slice(1)}{' '}
            </p>
            <p className="w-[4px] h-[4px] rounded-full bg-black"></p>
            <p className="">
              {levels?.[userProfile?.student?.level_id]?.title}
            </p>
          </div>
        ) : null}
      </div>
      <div className="grid lg:grid-cols-5 gap-4 mt-5 ">
        <div className="w-full lg:col-span-3 relative">
          <div className="absolute right-0 left-0 top-0 bottom-0 z-20 flex justify-end items-start p-2"></div>
          <Progress
            progressTitle="Learning Progress"
            track={tracks?.[userProfile?.student?.track_id]?.title}
            week={calculateWeekCount()}
            percentage={parseInt(calculateWeekPercentage())}
            pointsTitle="Points Overall"
            totalScore={overall?.[userProfile?.id]?.total_score || 0}
            totalPoints={overall?.[userProfile?.id]?.total_points || 0}
            stagesTitle="Current Stage"
            currentStage={stages?.[userProfile?.student?.stage_id]?.order}
            countOfStages={getCountOfStages(stages)}
          />
        </div>

        <div className="bg-white p-4 w-full lg:col-span-2 rounded-xl relative">
          <div className="absolute right-0 left-0 top-0 bottom-0 z-20 flex justify-end items-start p-2">
            <div className="w-full h-full bg-white opacity-90 absolute"></div>
            <h1 className="text-sm text-gray-500 z-50 italic">Coming Soon</h1>
          </div>
          <CalendarWidget route={'/student/dashboard/morecalendar'} />
        </div>
      </div>

      <div className="bg-white rounded-xl">
        <h1 className="px-5 text-xl font-normal pt-4 pb-2 mt-5">Recent Task</h1>
        <div className="overflow-x-auto">
          {isPending && (
            <div className="min-h-[4rem] w-full flex items-center justify-center">
              <div className="w-8 h-8 border-r-2 border-t-4 border-b-6 border-dotted border-gray-500 rounded-full animate-spin"></div>
            </div>
          )}
          {error && <div className="text-sm bg-red-500 p-6 ">{error}</div>}

          <div className="w-full overflow-x-scroll lg:w-full lg:overflow-x-hidden">
            <div className="grid grid-cols-9 items-center w-[990px] lg:w-full bg-blue-ribbon-50 py-4 px-5 my-4 text-base text-[#0D6EFD]">
              <p className="">Stage</p>
              <p className="col-span-2">Task</p>
              <p className="col-span-2">Track</p>
              <p className="col-span-2">Due Date</p>
              <p className="pl-7">Points</p>
              <p className="pl-7">Status</p>
            </div>

            <div className="flex flex-col gap-3">
              {tasks?.tasks?.map((data) => (
                <div
                  key={data.id}
                  className="grid grid-cols-9 gap-2 items-center w-[990px] lg:w-full bg-white py-4 px-5 text-[#808080] text-sm rounded-xl mt-4 hover:bg-blue-ribbon-50 cursor-pointer"
                  onClick={() => navigate(`/student/task/${data.id}`)}
                >
                  <p>{stages?.[data.stage_id]?.title}</p>
                  <p className="col-span-2 truncate w-10/12">{data.title}</p>
                  <p className="col-span-2">{tracks?.[data.track_id]?.title}</p>
                  <p className="col-span-2">{formatDateTime(data?.due_date)}</p>
                  <p className="pl-7">
                    <span className={scoreColor2(data)}>
                      {getSubmissionScore(data.submissions) ?? '-'}
                    </span>
                    /{data.points}
                  </p>
                  <div className="pl-7">
                    <p
                      className={`${scoreColor(
                        data.submissions
                      )} text-sm truncate`}
                    >
                      {scoreText(data.submissions)}
                    </p>
                    {data.submissions[data.submissions.length - 1]?.status ===
                      'graded' && (
                      <p
                        className={`${promoColor(
                          data,
                          userProfile?.student,
                          stages
                        )} text-[10px] truncate`}
                      >
                        {promoText(data, userProfile?.student, stages)}
                      </p>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <RefetchButton
          name="stdDashboardRefetch"
          functions={[refetchTasks, refetchScores]}
        />
        <JoinCohortModal
          hasNewCohort={hasNewCohort}
          hideCohortJoinModal={hideCohortJoinModal}
          handleCancelJoinWorkspace={handleCancelJoinWorkspace}
          setOldStdRegForm={setOldStdRegForm}
          oldStdRegForm={oldStdRegForm}
          handleInputChange={handleInputChange}
          handleFormSubmit={handleFormSubmit}
          form={form}
          availableCohorts={availableCohorts}
          generatedLevels={generatedLevels}
          generatedTracks={generatedTracks}
          isSuccess={isSuccess}
          handleContinue={handleContinue}
          cohortObj={cohortObj}
        />
      </div>
    </>
  );
};

export default StudentDashboard;
