import React from 'react';
import { useNavigate } from 'react-router-dom';
import AvatarWidget from '../../../../components/global/AvatarWidget';
import { useGlobalContext } from '../../../../context/context';
import { capitalizeFirstLetter } from '../../../../utils/capitalizeFirstLetter';

const AdminTrainerRow = ({ list }) => {
  const { tracks, levels } = useGlobalContext();
  const navigate = useNavigate();

  return (
    <tr
      className="bg-white relative text-gray-500 text-sm hover:bg-blue-ribbon-100 cursor-pointer transition-all ease-in-out duration-500"
      onClick={() => navigate(`/admin/trainer/${list.account.id}`)}
    >
      <td className="py-4 px-2 rounded-l-lg text-center">
        <input type="checkbox" name="" id="" className="" />
      </td>
      <td className="py-4 px-2">{list?.account?.id}</td>
      <td className="py-4 px-2 flex items-center space-x-2">
        <AvatarWidget
          avatarUrl={list?.profile?.avatar}
          w={'20px'}
          h={'20px'}
          text={'9px'}
          owner={list?.profile}
        />
        <span>
          {list?.profile?.first_name + ' ' + list?.profile?.last_name}
        </span>
      </td>
      <td className="py-4 px-2">
        {capitalizeFirstLetter(list?.profile?.gender)}
      </td>
      <td className="py-4 px-2">{tracks[list?.trainer?.track_id]?.title}</td>
      {/* <td className='py-4 px-2'>{list?.profile?.email}</td> */}
      <td className="py-4 px-2">{levels[list?.trainer?.level_id]?.title}</td>
    </tr>
  );
};

export default AdminTrainerRow;
