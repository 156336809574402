import React from 'react';

export function ProgressBar({ title, value, percentage }) {
  return (
    <div className="flex flex-col gap-1">
      <div className="flex justify-between items-center">
        <p className="text-[15px] text-[#585858]">{title}</p>
        <p className="text-[15px] text-[#585858] font-semibold">{value}</p>
      </div>
      <div className="h-3 rounded-lg w-full bg-[#F5F5F5] myshadow">
        <div
          className={`h-full rounded-lg bg-blue-ribbon-500 myshadow`}
          style={{ width: `${percentage}%` }}
        ></div>
      </div>
    </div>
  );
}
