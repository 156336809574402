import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Logo from '../../assets/icons/logo.svg';
import AuthTheme from '../../components/authentication/AuthTheme';
import { useGlobalContext } from '../../context/context';
import { useCategoryService } from '../../services/category.service';
import { useWorkspaceService } from '../../services/workspace.service';
import {
  default as SignUp2Modal,
  default as Signup2Modal,
} from './components/SignUpModal';
import SignUpOne from './components/SignUpOne';
import SignUpTwo from './components/SignUpTwo';

const SignUp = () => {
  const userInput = {
    firstname: '',
    lastname: '',
    username: '',
    email: '',
    phone: '',
    password: '',
    cpassword: '',
    dob: '',
    gender: 'male',
    country: 'Nigeria',
    city: '',
    cohort: 'no cohort',
    level: {},
    track: {},
    employment: 'student',
    education: 'Undergraduate',
    marital: 'single',
    how: 'facebook',
  };
  const [eye, setEye] = useState(false);
  const [success, setSuccess] = useState(false);
  const [formData, setFormData] = useState(userInput);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [allTracks, setAllTracks] = useState([]);
  const [errorMsg, setErrorMsg] = useState(null);
  const { setGeneratedLevels, setGeneratedTracks, generatedLevels } =
    useGlobalContext();
  const [page, setPage] = useState(1);
  const { fetchCategory } = useCategoryService();
  const { fetchWorkspacesAvailable } = useWorkspaceService();
  const [availableCohorts, setAvailableCohorts] = useState([]);
  const [cohortId, setCohortId] = useState(0);
  const [selectedLevel, setSelectedLevel] = useState(0);
  const [stages, setStages] = useState([]);

  useEffect(() => {
    const fetchWSAvailable = async () => {
      const ws = await fetchWorkspacesAvailable();
      setAvailableCohorts(ws);
    };
    fetchWSAvailable();
  }, []);

  const fetchCat = async (workspace) => {
    const catLevels = await fetchCategory(workspace, 'level');
    const catTracks = await fetchCategory(workspace, 'track');
    const catStages = await fetchCategory(workspace, 'stage');
    setGeneratedLevels(catLevels);
    setAllTracks(catTracks);
    setStages(catStages);
  };

  useEffect(() => {
    const selectedCohort = availableCohorts.find(
      (e) => e.slug === formData.cohort
    );
    setCohortId(selectedCohort?.id);
  }, [formData.cohort]);

  useEffect(() => {
    const selectedLevel = generatedLevels.find(
      (e) => e.name === formData.level
    );
    setSelectedLevel(selectedLevel);
  }, [formData.level]);

  useEffect(() => {
    if (formData.cohort !== 'no cohort') {
      fetchCat(formData.cohort);
    }
  }, [formData.cohort]);

  useEffect(() => {
    const filterTracks = () => {
      if (formData.level !== undefined) {
        const tracks = allTracks.filter(
          (track) =>
            !!track.tags.find((tag) => selectedLevel?.tags.includes(tag))
        );
        setGeneratedTracks(tracks);
      }
    };
    filterTracks();
  }, [formData.level, allTracks, selectedLevel]);

  const handleInput = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const showPassword = () => {
    setEye(!eye);
  };

  const PageDisplay = () => {
    return page === 1 ? (
      <SignUpOne
        formData={formData}
        handleInput={handleInput}
        eye={eye}
        setEye={setEye}
        showPassword={showPassword}
      />
    ) : (
      <SignUpTwo formData={formData} handleInput={handleInput} />
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const abortCont = new AbortController();

    if (page !== 1) {
      setIsLoading(true);
      const {
        username,
        email,
        phone,
        password,
        firstname,
        lastname,
        dob,
        gender,
        marital,
        country,
        track,
        city,
        how,
        education,
        employment,
        about,
        describe,
        why,
      } = formData;

      const account = {
        id: 0,
        username: username,
        email: email.toLowerCase(),
        phone: phone,
        status: 'active',
        role: 'user',
        password: password,
      };
      const profile = {
        id: 0,
        first_name: firstname,
        last_name: lastname,
        date_of_birth: dob,
        gender: gender,
        marital_status: marital,
      };
      const info = {
        id: 0,
        employment_status: employment,
        qualification: education,
        information_source: how,
        survey_data: {
          about: about,
          describe: describe,
          why: why,
          country: country,
          city: city,
        },
      };
      const stagetoUse = stages?.sort((a, b) =>
        a.order < b.order ? -1 : 1
      )?.[0];

      const student = {
        id: 0,
        level_id: Number(selectedLevel?.id),
        track_id: Number(track),
        stage_id: stagetoUse.id,
      };

      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/open`, {
          signal: abortCont.signal,
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            workspace_id: cohortId,
            type: 'learner',
            status: 'active',
            account,
            profile,
            info,
            student,
          }),
        });

        if (!response.ok) {
          const errorData = await response.json();
          if (Array.isArray(errorData)) {
            setErrorMsg(errorData.detail[0].msg);
            toast.error(errorData.detail[0].msg);
          } else {
            setErrorMsg(errorData.detail);
            toast.error(errorData.detail);
          }
          setIsLoading(false);
          setIsError(true);

          throw new Error(errorData);
        }

        toast.success('Sign up successful');

        setIsLoading(false);
        setSuccess(true);
      } catch (error) {
        if (error === 'AbortError') {
          toast.error('fetch aborted');
        } else {
          toast.error(error);
        }
        console.error('Error creating user:', error);
        setIsLoading(false);
        setIsError(true);
      }
    } else {
      setPage((currPage) => currPage + 1);
    }

    setTimeout(() => {
      setIsLoading(false);
    }, 10000);
    return () => abortCont.abort();
  };

  return (
    <AuthTheme>
      <div className="flex flex-col gap-7 justify-center items-center">
        <div className="w-20 h-[80px] rounded-lg bg-transparent shadow-md mt-8  grid place-items-center">
          <img src={Logo} alt="kodecamp-logo" />
        </div>

        <h2 className="text-[#303030] text-[28px] font-[500] leading-[36px] tracking-[1px]">
          Create account
        </h2>
        {isError && (
          <h1 className="text-red-600 text-sm text-center mb-5">{errorMsg}</h1>
        )}
      </div>

      <form
        onSubmit={handleSubmit}
        className="flex w-full md:px-10 justify-center mt-[10px] items-center flex-col"
      >
        <div className="w-full md:w-[100%]">
          {!success ? PageDisplay() : <Signup2Modal />}
        </div>
        <div className="w-[100%]">
          <button
            type={'submit'}
            className="text-[18px] font-[500] leading-[22px] tracking-[0.1px] mt-[40px] w-[100%] outline-[0] border border-solid rounded-[8px] h-[56px] bg-[#0D6EFD] text-[#ffffff]"
          >
            {page === 1 ? 'Next' : 'Create Account'}
          </button>
        </div>
        <p className="mb-[40px] mt-[24px]">
          Already have an account?{' '}
          <Link to={'/'} className="text-[blue]" href="">
            Log In
          </Link>
        </p>
      </form>
      {isLoading && (
        <div className="absolute loader bg-black opacity-70 top-0 right-0 left-0 bottom-0 flex justify-center items-center">
          <div className="w-10 h-10 border-r-2 border-t-4 border-b-6 border-dotted border-gray-500 rounded-full animate-spin"></div>
        </div>
      )}
      {success && <SignUp2Modal email={formData.email} />}
    </AuthTheme>
  );
};

export default SignUp;
