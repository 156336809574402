import React, { useState } from 'react';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import AvatarWidget from '../../../components/global/AvatarWidget';
import { useGlobalContext } from '../../../context/context';
import useFetch from '../../../hooks/useFetch';
import { ProfileEdit } from './ProfileEdit';

const StudentProfile = () => {
  const [uploadOption, setUploadOption] = useState(false);

  const [isLoading] = useState(false);
  const [certFile, setCertFile] = useState(null);
  const [profileOption, setProfileOption] = useState('Personal details');
  const [editOption, setEditOption] = useState(false);
  const { userProfile, setUserProfile, levels, tracks, stages, workspace } =
    useGlobalContext();
  const accountData = JSON.parse(localStorage.getItem('account'));
  const [showModal, setShowModal] = useState(false);
  const [avatarUrl, setAvatarUrl] = useState('');

  const { data: cert } = useFetch(`/profiles/certificate`, workspace);

  useEffect(() => {
    setCertFile(cert);
  }, [cert]);

  const handleProfileEdit = () => {
    setEditOption(true);
  };

  useEffect(() => {
    if (userProfile?.profile?.avatar) {
      setAvatarUrl(`${userProfile.profile?.avatar}?cache=${Date.now()}`);
    }
  }, [userProfile]);
  return (
    <>
      <div className="flex justify-between items-center">
        <h1>Profile</h1>
        <button
          className="px-4 py-2 bg-blue-ribbon-500 text-white rounded-lg"
          onClick={handleProfileEdit}
        >
          <i className="fa-solid fa-pen mr-1"></i> Edit Profile
        </button>
      </div>
      <div className="bg-white rounded-lg px-8 py-4 mt-6">
        <div className="flex space-x-2 md:space-x-12">
          <h1
            className={`${
              profileOption === 'Personal details'
                ? 'border-b border-black'
                : 'text-blue-ribbon-500'
            } cursor-pointer`}
            onClick={(e) => setProfileOption(e.currentTarget.innerText)}
          >
            Personal details
          </h1>
          <h1
            className={`${
              profileOption === 'Professional details'
                ? 'border-b border-black'
                : 'text-blue-ribbon-500'
            } cursor-pointer`}
            onClick={(e) => setProfileOption(e.currentTarget.innerText)}
          >
            Professional details
          </h1>
        </div>
        {editOption ? (
          <ProfileEdit setEditOption={setEditOption} />
        ) : profileOption === 'Personal details' ? (
          <>
            <div className="md:flex items-center md:space-x-12 mt-8">
              <div
                className="img relative cursor-pointer flex justify-center items-center"
                onClick={() => setUploadOption(!uploadOption)}
              >
                {isLoading && (
                  <div className="z-40 absolute w-10 h-10 border-r-2 border-t-4 border-b-6 border-dotted border-red-500 rounded-full animate-spin"></div>
                )}
                <AvatarWidget
                  avatarUrl={avatarUrl}
                  w={'200px'}
                  h={'200px'}
                  text={'100px'}
                  owner={userProfile?.profile}
                />
                <i className="fa-solid fa-camera absolute right-16 bottom-6 md:right-4 text-blue-ribbon-500 md:bottom-4 bg-white p-3 rounded-full"></i>
                <div
                  className={`${
                    uploadOption ? 'block' : 'hidden'
                  } upload absolute top-0 -right-10 shadow-lg bg-white p-4 rounded-lg`}
                >
                  <div className="file cursor-pointer">
                    <label
                      htmlFor="img"
                      className="cursor-pointer"
                      onClick={() => setShowModal(true)}
                    >
                      <i className="fa-solid fa-image mr-1 cursor-pointer"></i>
                      Upload Picture
                    </label>
                  </div>
                </div>
              </div>
              <div className="right space-y-4">
                <h1 className="text-lg md:text-2xl">
                  {userProfile?.profile?.first_name +
                    ' ' +
                    userProfile?.profile?.last_name}
                </h1>
                <p>
                  <span className="text-sm mr-3 text-[grey]">Track:</span>
                  <span>{tracks[userProfile?.student?.track_id]?.title}</span>
                </p>
                <p>
                  <span className="text-sm mr-3 text-[grey]">Level:</span>
                  <span className="text-[#D4AF37]">
                    {levels[userProfile?.student?.level_id]?.title}
                  </span>
                </p>
                <p>
                  <span className="text-sm mr-3 text-[grey]">Stage:</span>
                  <span> {stages[userProfile?.student?.stage_id]?.title}</span>
                </p>
              </div>
            </div>
            <div className="grid grid-cols-2 md:grid-cols-4 mt-8 overflow-auto">
              <div className="left">
                <ul className="space-y-4">
                  <li className="border-b pb-1">Username</li>
                  <li className="border-b pb-1">Email Address</li>
                  <li className="border-b pb-1">Date of Birth</li>
                  <li className="border-b pb-1">Gender</li>
                  <li className="border-b pb-1">Phone Number</li>
                </ul>
              </div>
              <div className="right md:col-span-3">
                <ul className="space-y-4">
                  <li className="border-b pb-1 pl-5">
                    {accountData?.account?.username}
                  </li>
                  <li className="border-b pb-1 pl-5">
                    {accountData?.account?.email}
                  </li>
                  <li className="border-b pb-1 pl-5">
                    {formatDate(userProfile?.profile?.date_of_birth)}
                  </li>
                  <li className="border-b pb-1 pl-5">
                    {firstWordCapitalized(userProfile?.profile?.gender)}
                  </li>
                  <li className="border-b pb-1 pl-5">
                    {accountData?.account?.phone}
                  </li>
                </ul>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="grid grid-cols-2 md:grid-cols-4 mt-8">
              <div className="left">
                <ul className="space-y-4">
                  <li className="border-b pb-1">Employement Status</li>
                  <li className="border-b pb-1">Education Qualification</li>
                </ul>
              </div>

              <div className="right md:col-span-3">
                <ul className="space-y-4">
                  <li className="border-b pb-1 pl-5 font-bold">
                    {' '}
                    {firstWordCapitalized(userProfile?.info.employment_status)}
                  </li>
                  <li className="border-b pb-1 pl-5 font-bold">
                    {firstWordCapitalized(userProfile?.info.qualification)}
                  </li>
                </ul>
              </div>
            </div>
          </>
        )}

        <button
          className={`text-sm text-white  px-4 py-2 rounded-lg mt-4 ${
            !certFile ? 'bg-blue-ribbon-100' : 'bg-blue-ribbon-500'
          }`}
          type="button"
          disabled={!certFile}
          onClick={() => window.open(process.env.REACT_APP_BASE_URL + cert.url)}
        >
          <i className="fa-solid fa-certificate mr-2"></i>View Certificate
        </button>
      </div>
      <PictureModal
        showModal={showModal}
        setShowModal={setShowModal}
        setUserProfile={setUserProfile}
        workspace={workspace}
      />
    </>
  );
};

export default StudentProfile;

const firstWordCapitalized = (word) => {
  return word?.slice(0, 1).toUpperCase() + word?.slice(1);
};

function PictureModal({ showModal, setShowModal, setUserProfile, workspace }) {
  const accountData = JSON.parse(localStorage.getItem('account'));
  const [filePicked, setFilePicked] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleFileChange = ({ target }) => {
    setFilePicked(target.files[0]);
  };

  const handleUpload = () => {
    if (!filePicked) {
      console.log('No file selected.');
      return;
    }

    setIsLoading(true);

    const formData = new FormData();
    formData.append('avatar', filePicked);

    fetch(`${process.env.REACT_APP_API_URL}/${workspace}/profiles/avatar`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${accountData.access_token}`,
      },
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Error uploading file.');
        }
        console.log('Uploaded successfully');
        return response.json();
      })
      .then((data) => {
        setUserProfile(data);
        setIsLoading(false);
        toast.success('Uploaded Successfully');
        setShowModal(false);
      })
      .catch((error) => {
        console.error('Error uploading:', error);
        setIsLoading(false);
        setShowModal(false);
      });
  };

  return (
    <>
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                  <h3 className="font-semibold">Upload Picture</h3>
                  <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <input
                    type="file"
                    accept=".jpg, .jpeg, .png"
                    onChange={handleFileChange}
                  />
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Close
                  </button>
                  <button
                    className="bg-blue-ribbon-500 text-white active:bg-emerald-600 text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={handleUpload}
                    disabled={isLoading}
                  >
                    {isLoading ? 'Uploading...' : 'Upload'}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}

export function formatDate(dateString) {
  const date = new Date(dateString);

  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'short' });
  const year = date.getFullYear();

  let daySuffix = 'th';
  if (day === 1 || day === 21 || day === 31) {
    daySuffix = 'st';
  } else if (day === 2 || day === 22) {
    daySuffix = 'nd';
  } else if (day === 3 || day === 23) {
    daySuffix = 'rd';
  }

  return `${day}${daySuffix} ${month} ${year}`;
}
