import React from "react";

function NotAvailable() {
  return (
    <div className="w-full h-[80vh] flex justify-center items-center">
      <h1 className="">The page is not available at the moment</h1>
    </div>
  );
}

export default NotAvailable;
