import React, { useState } from 'react';

import { useParams } from 'react-router-dom';
import useFetch from '../../../hooks/useFetch';
import { NavLink, Link, Outlet, useLocation } from 'react-router-dom';
import { RiPencilFill } from 'react-icons/ri';
import { toast } from 'react-toastify';
import { useGlobalContext } from '../../../context/context';

function TaskDetails({ route }) {
  const currentUser = JSON.parse(localStorage.getItem('user'));
  const currentAccount = JSON.parse(localStorage.getItem('account'));
  const { id } = useParams();
  const {
    selectedItems,
    setSelectedItems,
    setTask,
    filterOption,
    setFilterOption,
    sortOption,
    setSortOption,
    workspace,
  } = useGlobalContext();
  const location = useLocation();
  const { data: task, isPending, error } = useFetch(`/tasks/${id}`, workspace);
  const [isLoading, setIsLoading] = useState(false);

  const setData = (data) => {
    let {
      id,
      track_id,
      stage_id,
      title,
      description,
      submission_links,
      resources,
      points,
      due_date,
      status,
      level_id,
      collabs,
    } = data;
    localStorage.setItem('id', id);
    localStorage.setItem('track_id', track_id);
    localStorage.setItem('stage_id', stage_id);
    localStorage.setItem('title', title);
    localStorage.setItem('description', description);
    localStorage.setItem('submission_links', JSON.stringify(submission_links));
    localStorage.setItem('resources', JSON.stringify(resources));
    localStorage.setItem('collaborators', JSON.stringify(collabs));
    localStorage.setItem('points', points);
    localStorage.setItem('due_date', due_date);
    localStorage.setItem('status', status);
    localStorage.setItem('level_id', level_id);
  };

  const handleSubmit = () => {
    if (selectedItems.length > 0) {
      setIsLoading(true);

      fetch(`${process.env.REACT_APP_API_URL}/${workspace}/tasks/${id}/grade`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${currentAccount.access_token}`,
        },
        body: JSON.stringify(selectedItems),
      })
        .then((response) => {
          if (!response.ok) {
            toast.error('Error');
            throw new Error('Failed');
          }
          setIsLoading(false);
          return response.json();
        })
        .then((data) => {
          setTask(data);
          setIsLoading(false);
          toast.success('Graded');
          setSelectedItems([]);
        })
        .catch((error) => {
          console.error('Error grading:', error);
          setIsLoading(false);
          toast.error('Error');
          setSelectedItems([]);
        });
    } else {
      toast.error('please check submissions to grade');
    }
  };

  return (
    <div>
      {isPending && <div className="text-sm">Loading.....</div>}
      {error && (
        <div className="text-sm bg-red-500 p-6 text-white mt-5">{error}</div>
      )}

      {task && (
        <div>
          <p className="text-sm text-blue-ribbon">
            Tasks / <span className="text-[#808080]">{task.title}</span>
          </p>

          <div className="flex flex-col gap-6 flex-wrap md:flex-row md:items-center justify-between">
            <div className="relative flex items-center gap-3 md:gap-9 mt-3">
              <NavLink
                to={`/${route}/task/${task.id}/details`}
                className={({ isActive }) =>
                  isActive
                    ? 'text-base font-medium text-[#303030] border-b border-[#303030]'
                    : 'text-blue-ribbon-500 text-base'
                }
              >
                Task
              </NavLink>

              <div className="flex items-center gap-5">
                <NavLink
                  to={`/${route}/task/${task.id}/submissions`}
                  className={({ isActive }) =>
                    isActive
                      ? 'text-base font-medium text-[#303030] border-b border-[#303030] flex items-center gap-2'
                      : 'text-blue-ribbon-500 flex items-center gap-2 text-base'
                  }
                >
                  <span>Submissions</span>
                  <span className="bg-blue-ribbon min-w-[20px] min-h-4 p-[2px] rounded-xl flex justify-center items-center right-[-15px] top-[-7px]">
                    <p className="text-white font-medium text-sm">
                      {task.submissions && task.submissions.length}
                    </p>
                  </span>
                </NavLink>
              </div>
            </div>

            {location.pathname === `/${route}/task/${id}/details` ? (
              task?.owner?.id === currentUser?.id && (
                <div className="flex justify-start">
                  <Link
                    to={`/instructor/task/edit-task/${task.id}`}
                    className="flex items-center gap-2 bg-white text-blue-ribbon border border-blue-ribbon px-4 py-2 rounded-md text-base"
                    onClick={() => setData(task)}
                  >
                    <RiPencilFill className="text-base" />
                    <p>Edit Task</p>
                  </Link>
                </div>
              )
            ) : (
              <div className="div flex flex-col md:flex-row md:items-center md:gap-5 gap-5 text-sm">
                <div className="flex flex-col md:flex-row md:items-center gap-2">
                  <p>Sort By</p>
                  <select
                    id=""
                    name="grade"
                    value={sortOption}
                    onChange={(e) => setSortOption(e.target.value)}
                    className="px-2 py-3 rounded-md text-[12px]"
                  >
                    <option value="">Sort</option>
                    <option value="grade">Grade</option>
                  </select>
                </div>

                <div className="flex flex-col md:flex-row md:items-center gap-2 md:mr-28">
                  <p>Filter</p>
                  <select
                    id=""
                    name="grade"
                    value={filterOption}
                    onChange={(e) => setFilterOption(e.target.value)}
                    className="px-2 py-3 rounded-md text-[12px]"
                  >
                    <option value="All">All</option>
                    <option value="submitted">Not Graded</option>
                    <option value="graded">Graded</option>
                  </select>
                </div>

                <div
                  className="px-2 md:px-4 py-3 cursor-pointer rounded-md text-[12px] bg-blue-ribbon-500 text-white fixed md:sticky right-10 top-32"
                  onClick={handleSubmit}
                >
                  {isLoading ? 'Submitting...' : 'Submit'}
                </div>
              </div>
            )}
          </div>

          <Outlet />
        </div>
      )}
    </div>
  );
}

export default TaskDetails;
