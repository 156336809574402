import React, { useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

const Drop = ({ data: options, selected, setSelected }) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <div className="relative">
      <div
        className="border bg-white rounded-lg flex justify-between items-center py-2 px-3 w-[160px] cursor-pointer"
        onClick={() => setOpen(!isOpen)}
      >
        <p className="text-base font-normal text-[#808080]">
          {selected ? selected : "All"}
        </p>

        {!isOpen ? (
          <IoIosArrowDown className="text-lg" />
        ) : (
          <IoIosArrowUp className="text-lg" />
        )}
      </div>

      {isOpen && (
        <div
          className={`absolute top-11 border bg-white space-y-2 rounded-lg w-[160px] py-3 cursor-pointer`}
        >
          {options.map((option, i) => (
            <p
              key={i}
              onClick={(e) => {
                setSelected(option);
                setOpen(false);
              }}
              className="px-3 text-base font-normal text-[#808080]"
            >
              {option}
            </p>
          ))}
        </div>
      )}
    </div>
  );
};

export default Drop;
