import React from "react";
import { Outlet } from "react-router-dom";

import PageWrapper from "../../layouts/PageWrapper";
import { student_SideItem } from "../../layouts/sidebar_data";

const Student = () => {
  return (
    <div>
      <PageWrapper route={"student"} sideItem={student_SideItem}>
        <Outlet />
      </PageWrapper>
    </div>
  );
};

export default Student;
