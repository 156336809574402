import React, { useEffect, useState } from 'react';

import { FaArrowUp, FaTimesCircle } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import AvatarWidget from '../../../../components/global/AvatarWidget';
import SelectGroup from '../../../../components/SelectGroup';
import { useGlobalContext } from '../../../../context/context';
import { RefetchButton } from '../../../../context/Refetch';
import useFetch from '../../../../hooks/useFetch';
import { useCategoryService } from '../../../../services/category.service';
import { useStudentTransitionService } from '../../../../services/student_transition.service';
import { hasEmptyValue } from './transitionValidation';

const StudentListPromotion = () => {
  const { pushPromotion, pushDemotion } = useStudentTransitionService();
  const { stages, tracks, levels, overall, setOverall, workspace } =
    useGlobalContext();
  const [mergeCategories, setMergeCategories] = useState({});
  const [stageSelected, setStageSelected] = useState([]);
  const [openStages, setOpenStages] = useState(false);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [checkedList, setCheckedList] = useState([]);
  const [studentList, setStudentList] = useState([]);
  const { fetchCategory } = useCategoryService();
  const [selections, setSelections] = useState({
    level: { from: '', to: '' },
    track: { from: '', to: '' },
    stage: { from: '', to: '' },
  });
  const [filter, setFilter] = useState({
    level: [],
    track: [],
    stage: [],
  });
  const { type: action } = useParams();

  const offset = 0;
  const limit = 1000;

  const {
    data: promotion,
    isPending,
    refetch: refetchPromotions,
  } = useFetch(
    `/admin/tasks/${
      action === 'demote' ? 'demotion' : 'promotion'
    }?offset=${offset}&limit=${limit}`,
    workspace
  );

  useEffect(() => {
    setFilter({
      level: Object.values(levels).map((level) => level.id) || [],
      track: Object.values(tracks).map((track) => track.id) || [],
      stage: Object.values(stages).map((stage) => stage.id) || [],
    });
  }, [levels, tracks, stages]);

  useEffect(() => {
    setFilter((prev) => ({
      level:
        selections.level.from !== ''
          ? [Number(selections.level.from)]
          : [...prev.level],

      track:
        selections.track.from !== ''
          ? [Number(selections.track.from)]
          : [...prev.track],
      stage:
        selections.stage.from !== ''
          ? [Number(selections.stage.from)]
          : [...prev.stage],
    }));
  }, [selections]);

  useEffect(() => {
    setStudentList(
      promotion?.students.filter((item, idx) => {
        return (
          filter?.level?.includes(item.student?.level_id) &&
          filter?.track?.includes(item.student?.track_id) &&
          filter?.stage?.includes(item.student?.stage_id)
        );
      })
    );
  }, [promotion?.students, filter]);

  useEffect(() => {
    if (checkedList?.length === 0) {
      setCheckedList(studentList?.map((student) => student.id) || []);
    }
  }, [studentList]);

  const handleSelectAll = () => {
    if (checkedList?.length === studentList?.length) {
      setCheckedList([]);
    } else {
      setCheckedList(studentList?.map((student) => student.id) || []);
    }
  };

  const handleChecked = (student_id) => {
    setCheckedList((prevList) =>
      prevList.includes(student_id)
        ? prevList.filter((id) => id !== student_id)
        : [...prevList, student_id]
    );
  };

  const handleTransition = async () => {
    if (action === 'demote' && hasEmptyValue(selections)) {
      toast.error('One or more fields is missing selection');
      return;
    }
    setIsLoading(true);
    const { level, stage, track } = filter;
    try {
      if (action === 'promote') {
        const data = {
          level_ids: level,
          track_ids: track,
          stage_ids: stage,
          cutoff: 70,
        };
        await pushPromotion(data);
      } else if (action === 'demote') {
        const data = {
          level_from: Number(selections.level.from),
          level_to: Number(selections.level.to),
          stage_from: Number(selections.stage.from),
          stage_to: Number(selections.stage.to),
          track_from: Number(selections.track.from),
          track_to: Number(selections.track.to),
          student_ids: checkedList,
        };
        await pushDemotion(data);
      }
      toast.success('Operation successful!');
      setStudentList([]);
      refetchPromotions();
    } catch (error) {
      toast.error(error + '');
      console.error('Error during transition:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSelectedStage = (checked, data) => {
    if (checked) {
      setStageSelected((prev) => [...prev, data]);

      setFilter((prev) => ({
        ...prev,
        stage: [...stageSelected.map((e) => e.id)],
      }));
    } else {
      setStageSelected((prev) => prev.filter((e) => e.id !== data.id));
      setFilter((prev) => ({
        ...prev,
        stage: prev.stage.filter((e) => e.id !== data.id),
      }));
    }
  };

  useEffect(() => {
    if (stageSelected.length < 1) {
      setFilter((prev) => ({
        ...prev,
        stage: Object.values(stages).map((stage) => stage.id) || [],
      }));
    } else {
      setFilter((prev) => ({
        ...prev,
        stage: stageSelected.map((e) => e.id),
      }));
    }
  }, [stageSelected, stages]);

  const { data: scores, refetch: refetchScores } = useFetch(
    `/submissions/scores?offset=${offset}&limit=${limit}`,
    workspace
  );

  useEffect(() => {
    const scoresObj = scores?.reduce((acc, crr) => {
      acc[crr.student_id] = crr;
      return acc;
    }, {});
    setOverall(scoresObj);
  }, [scores]);

  const fetchData = async () => {
    try {
      const level = await fetchCategory(workspace, 'level');
      const track = await fetchCategory(workspace, 'track');
      const stage = await fetchCategory(workspace, 'stage');
      setMergeCategories({ level, track, stage });
    } catch (error) {
      setMergeCategories({ level: [], track: [], stage: [] });
    }
  };

  useEffect(() => {
    fetchData();
  }, [workspace]);

  return (
    <div className="overflow-x-hidden mb-16">
      <div className="flex justify-between items-center p-2 bg-blue-100 rounded-lg">
        <h3 className="text-xl">
          Students Due For {action === 'promote' ? 'Promotion' : 'Demotion'}
        </h3>
        <div className="flex items-center space-x-4">
          {action === 'promote' && (
            <div className="flex space-x-2 items-center">
              <p className="text-[12px] text-gray-500">Stage</p>
              <div className="relative w-32">
                <div
                  className="border border-gray-400 rounded-md px-2 h-[36px] py-1 text-[12px] text-gray-500 cursor-pointer truncate bg-white"
                  onClick={() => setOpenStages(!openStages)}
                >
                  {stageSelected.map((e) => e.title).join(', ')}
                </div>
                <ul
                  className={`${
                    openStages ? 'h-48' : 'h-0'
                  } space-y-1 absolute w-full px-2 bg-white flex flex-col border shadow-md items-start transition-all duration-500 ease-in-out overflow-hidden`}
                >
                  {Object.values(stages)?.map((data, id) => (
                    <li
                      key={id}
                      className="flex items-center space-x-3 text-[13px] text-gray-500"
                    >
                      <input
                        type="checkbox"
                        name=""
                        id=""
                        className="w-[10px] h-[10px]"
                        checked={stageSelected?.some(
                          (item) => item.id === data.id
                        )}
                        onChange={(e) =>
                          handleSelectedStage(e.target.checked, data)
                        }
                      />
                      <p onClick={() => handleSelectedStage(true, data)}>
                        {data.title}
                      </p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
          <div
            className="flex items-center space-x-2 text-white text-sm bg-blue-ribbon-500 rounded-md hover:bg-blue-ribbon-300 py-2 px-2 cursor-pointer"
            onClick={handleTransition}
          >
            {!isLoading && <FaArrowUp />}

            <p className="">
              {isLoading
                ? 'Please Wait...'
                : action === 'promote'
                ? 'Promote All'
                : 'Demote All'}
            </p>
          </div>
          <div
            className="border p-2 border-red-500 rounded-md text-sm cursor-pointer bg-white"
            onClick={() => navigate('/admin/student-list')}
          >
            <FaTimesCircle className="text-red-500" />
          </div>
        </div>
      </div>
      <div className="overflow-x-hidden">
        {action === 'demote' && (
          <>
            <SelectGroup
              selectOptions={mergeCategories}
              selections={selections}
              setSelections={setSelections}
            />
          </>
        )}
        {action === 'promote' ? (
          <>
            <div className="lg:w-full lg:overflow-x-hidden overflow-x-scroll">
              <div className="flex mt-4 space-x-5 text-[#0D6EFD] text-sm">
                <p
                  key={0}
                  className={`cursor-pointer ${
                    filter?.level?.length === Object.keys(levels)?.length
                      ? 'text-gray-500 border-b-2 border-black'
                      : 'text-[#0D6EFD]'
                  } `}
                  onClick={(e) =>
                    setFilter({
                      ...filter,
                      level:
                        Object.values(levels).map((level) => level.id) || [],
                    })
                  }
                >
                  All
                </p>

                {Object.values(levels).map((level, index) => (
                  <p
                    key={index}
                    className={`cursor-pointer ${
                      filter?.level?.length !== Object.keys(levels)?.length &&
                      filter?.level?.includes(level.id)
                        ? 'text-gray-500 border-b-2 border-black'
                        : 'text-[#0D6EFD]'
                    } `}
                    onClick={(e) => setFilter({ ...filter, level: [level.id] })}
                  >
                    {level.title}
                  </p>
                ))}
              </div>
            </div>
            <div className="lg:w-full lg:overflow-x-hidden overflow-x-scroll">
              <div className="flex my-4 space-x-5 text-[#0D6EFD] text-sm">
                <p
                  key={0}
                  className={`cursor-pointer ${
                    filter?.track?.length === Object.keys(tracks)?.length
                      ? 'text-gray-500 border-b-2 border-black'
                      : 'text-[#0D6EFD]'
                  } `}
                  onClick={(e) =>
                    setFilter({
                      ...filter,
                      track:
                        Object.values(tracks).map((track) => track.id) || [],
                    })
                  }
                >
                  All
                </p>
                {Object.values(tracks)?.map((track, index) => (
                  <p
                    key={index}
                    className={`cursor-pointer ${
                      filter?.track?.length !== Object.keys(tracks)?.length &&
                      filter?.track?.includes(track.id)
                        ? 'text-gray-500 border-b-2 border-black'
                        : 'text-[#0D6EFD]'
                    } `}
                    onClick={(e) => setFilter({ ...filter, track: [track.id] })}
                  >
                    {track.title}
                  </p>
                ))}
              </div>
            </div>
          </>
        ) : null}
        <p className="text-center my-6 text-gray-500 text-sm">
          {/* Showing {pageData.startIndex} - {pageData.endIndex} of{' '} */}
          {studentList?.length} students
        </p>
        <div className="overflow-x-auto w-full">
          <table className="min-w-full border-separate border-spacing-y-4 whitespace-nowrap">
            <thead>
              <tr className="text-[#0D6EFD] font-light bg-[#0D6EFD08]/5">
                {action === 'demote' && (
                  <th className="text-left px-2">
                    <input
                      type="checkbox"
                      name=""
                      id=""
                      className=""
                      checked={checkedList?.length === studentList?.length}
                      onChange={handleSelectAll}
                    />
                  </th>
                )}
                {tableHead?.map((e, idx) => (
                  <th
                    className={`${idx === 0 && 'rounded-l-lg'} ${
                      idx === tableHead.length && 'rounded-r-lg'
                    } py-4 px-2 text-start font-light text-sm`}
                    key={idx}
                  >
                    {e}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {studentList?.map((list, idx) => (
                <StudentListPromotionRow
                  action={action}
                  list={list}
                  key={idx}
                  task={promotion?.tasks}
                  overall={overall}
                  handleChecked={handleChecked}
                  checkedList={checkedList}
                />
              ))}
            </tbody>
          </table>
        </div>
        {isPending && (
          <div className="flex justify-center">
            <div className="w-6 h-6 border-r-2 border-t-4 border-b-6 border-dotted border-gray-500 rounded-full animate-spin"></div>
          </div>
        )}
      </div>
      <div className="flex lg:justify-end justify-start w-full">
        {/* <Pagination
          data={studentList?.filter(
            (e) =>
              levels[e.student?.level_id]?.title.toLowerCase() ===
              filter.level.toLowerCase()
          )}
          setNewData={setCurrentPosts}
          setPageData={setPageData}
        /> */}
      </div>
      <RefetchButton
        name="promotionRefetch"
        functions={[refetchScores, refetchPromotions]}
      />
    </div>
  );
};
export default StudentListPromotion;

const StudentListPromotionRow = ({
  action,
  list,
  task,
  overall,
  handleChecked,
  checkedList,
}) => {
  const { stages, tracks, levels } = useGlobalContext();
  const navigate = useNavigate();

  return (
    <tr className="bg-white relative text-gray-500 text-sm hover:bg-blue-ribbon-100 cursor-pointer transition-all ease-in-out duration-500">
      {action === 'demote' && (
        <td className="py-4 px-2  rounded-l-lg">
          <input
            type="checkbox"
            name=""
            id=""
            checked={checkedList?.includes(list?.id)}
            onChange={(e) => {
              e.stopPropagation();
              handleChecked(list?.id);
            }}
          />
        </td>
      )}
      <td className="py-4 px-2">{list?.id}</td>
      <td
        className="py-4 px-2 flex items-center space-x-2"
        onClick={() => navigate(`/admin/student/${list.id}`)}
      >
        <AvatarWidget
          avatarUrl={list?.profile?.avatar}
          w={'20px'}
          h={'20px'}
          text={'9px'}
          owner={list?.profile}
        />
        <span>
          {list?.profile?.first_name + ' ' + list?.profile?.last_name}
        </span>
      </td>
      <td className="py-4 px-2">{levels[list?.student?.level_id]?.title}</td>
      <td className="py-4 px-2">{tracks[list?.student?.track_id]?.title}</td>
      <td className="py-4 px-2">{stages[list?.student?.stage_id]?.title}</td>
      <td className="py-4 px-2">
        {list?.submissions[0]?.score || 0}&nbsp;/&nbsp;
        {taskScore(task, list?.submissions[0]?.task_id)}
      </td>

      <td className="py-4 px-2 rounded-r-lg">
        {' '}
        <div className="">
          {overall?.[list?.id]?.total_score ?? 0}
          &nbsp;/&nbsp;
          {overall?.[list?.id]?.total_points ?? 0}
        </div>
      </td>
    </tr>
  );
};

const tableHead = [
  'ID',
  'User',
  'Level',
  'Track',
  'Stage',
  'Grade in Last Task',
  'Overall Grade',
];

const taskScore = (task, taskId) => {
  return task.find((e) => e.id === taskId)?.points || 0;
};
