import React, { useEffect, useState } from 'react';

import { useGlobalContext } from '../../../context/context';
import { useWorkspaceService } from '../../../services/workspace.service';

const SignUpTwo = ({ formData, handleInput }) => {
  const { generatedLevels, generatedTracks } = useGlobalContext();
  const { fetchWorkspacesAvailable } = useWorkspaceService();
  const [availableCohorts, setAvailableCohorts] = useState([]);

  useEffect(() => {
    const fetchWSAvailable = async () => {
      const ws = await fetchWorkspacesAvailable();
      setAvailableCohorts(ws);
    };
    fetchWSAvailable();
  }, []);

  return (
    <div className="signup2 flex flex-col gap-4">
      <div className="signup__form-div">
        <label className="signup__form-label block opacity-70 m-2" htmlFor="">
          Date of Birth
        </label>
        <input
          className="signup__form-input border w-full h-[50px] p-2 border-gray-300"
          type="date"
          placeholder="Enter your date of birth"
          name="dob"
          value={formData.dob}
          onChange={handleInput}
          required
        />
      </div>

      <div className="signup__form-div">
        <label className="signup__form-label block opacity-70 m-2" htmlFor="">
          Gender
        </label>
        <select
          className="signup__form-input select
          w-full h-[50px] rounded-lg p-2 border border-gray-300 bg-white opacity-60"
          name="gender"
          id="gender-select"
          value={formData.gender}
          onChange={handleInput}
          required
        >
          <option value="">Select your gender</option>
          <option value="male">Male</option>
          <option value="female">Female</option>
        </select>
      </div>
      <div className="signup__form-div">
        <label className="signup__form-label block opacity-70 m-2" htmlFor="">
          Country of Origin
        </label>
        <input
          className="signup__form-input border w-full h-[50px] p-2 border-gray-300"
          type="text"
          placeholder="Type your country"
          name="country"
          value={formData.country}
          onChange={handleInput}
          required
        />
      </div>
      <div className="signup__form-div">
        <label className="signup__form-label block opacity-70 m-2" htmlFor="">
          City
        </label>
        <input
          className="signup__form-input border w-full h-[50px] p-2 border-gray-300"
          type="text"
          placeholder="Type your city"
          name="city"
          value={formData.city}
          onChange={handleInput}
          required
        />
      </div>
      <div className="signup__form-div">
        <label className="signup__form-label block opacity-70 m-2" htmlFor="">
          Marital Status
        </label>
        <select
          className="signup__form-input select
          w-full h-[50px] rounded-lg p-2 border border-gray-300 bg-white opacity-60"
          name="marital"
          id="marital-select"
          value={formData.marital}
          onChange={handleInput}
          required
        >
          <option value="single">Single</option>
          <option value="married">Married</option>
        </select>
      </div>

      <div className="signup__form-div">
        <label className="signup__form-label block opacity-70 m-2" htmlFor="">
          Cohort
        </label>
        <select
          className="signup__form-input select w-full h-[50px] rounded-lg p-2 border border-gray-300 bg-white opacity-60"
          name="cohort"
          id="cohort-select"
          value={formData.cohort}
          onChange={handleInput}
          required
        >
          <option value="">Select a cohort</option>
          {availableCohorts?.map((e) => (
            <option key={e.id} value={e.slug}>
              {e.name}
            </option>
          ))}
        </select>
      </div>

      <div className="signup__form-div">
        <label className="signup__form-label block opacity-70 m-2" htmlFor="">
          Skill level
        </label>
        <select
          className="signup__form-input select w-full h-[50px] rounded-lg p-2 border border-gray-300 bg-white opacity-60"
          name="level"
          id="level-select"
          value={formData.level}
          onChange={handleInput}
          required
        >
          <option value="">Select your level</option>
          {generatedLevels?.map((e) => (
            <option key={e.id} value={e.name}>
              {e.title}
            </option>
          ))}
        </select>
      </div>

      <div className="signup__form-div">
        <label className="signup__form-label block opacity-70 m-2" htmlFor="">
          Track
        </label>
        <select
          className="signup__form-input select
          w-full h-[50px] rounded-lg p-2 border border-gray-300 bg-white opacity-60"
          name="track"
          id="track-select"
          value={formData.track}
          onChange={handleInput}
          required
        >
          <option value="">Select track</option>
          {generatedTracks?.map((e) => (
            <option key={e.id} value={e.id}>
              {e.title}
            </option>
          ))}
        </select>
      </div>

      <div className="signup__form-div">
        <label className="signup__form-label block opacity-70 m-2" htmlFor="">
          Employment status
        </label>
        <select
          className="signup__form-input select w-full h-[50px] rounded-lg p-2 border border-gray-300 bg-white opacity-60"
          name="employment"
          id="employment-select"
          value={formData.employment}
          onChange={handleInput}
          required
        >
          <option value="">Select employment status</option>
          <option value="Student">Student</option>
          <option value="Unemployed">Unemployed</option>
          <option value="Self Employed">Self Employed</option>
          <option value="Full Time">Full Time </option>
          <option value="Part Time">Part Time</option>
        </select>
      </div>

      <div className="signup__form-div">
        <label className="signup__form-label block opacity-70 m-2" htmlFor="">
          Educational qualification
        </label>
        <select
          className="signup__form-input select w-full h-[50px] rounded-lg p-2 border border-gray-300 bg-white opacity-60"
          name="education"
          id="education-select"
          value={formData.education}
          onChange={handleInput}
          required
        >
          <option value="">Select your highest level of education</option>
          <option value="Primary">Primary</option>
          <option value="Secondary">Secondary</option>
          <option value="Undergraduate">Undergraduate</option>
          <option value="Graduate">Graduate</option>
          <option value="Post Graduate">Post Graduate</option>
        </select>
      </div>
      <div className="signup__form-div">
        <label className="signup__form-label block opacity-70 m-2" htmlFor="">
          What best describe you?
        </label>
        <select
          className="signup__form-input select w-full h-[50px] rounded-lg p-2 border border-gray-300 bg-white opacity-60"
          name="describe"
          id="describe-select"
          value={formData.describe}
          onChange={handleInput}
          required
        >
          <option value="">Choose a description</option>
          <option value="Currently in Tech">Currently in Tech</option>
          <option value="Looking to switch to tech">
            Looking to switch to tech
          </option>
          <option value="Not interested in tech">Not interested in tech</option>
        </select>
      </div>
      <div className="signup__form-div">
        <label className="signup__form-label block opacity-70 m-2" htmlFor="">
          Tell us about yourself
        </label>
        <input
          className="signup__form-input border w-full h-[50px] p-2 border-gray-300"
          type="text"
          placeholder="Brief"
          name="about"
          value={formData.about}
          onChange={handleInput}
          required
        />
      </div>
      <div className="signup__form-div">
        <label className="signup__form-label block opacity-70 m-2" htmlFor="">
          Why do you want to take this course?
        </label>
        <select
          className="signup__form-input select w-full h-[50px] rounded-lg p-2 border border-gray-300 bg-white opacity-60"
          name="why"
          id="why-select"
          value={formData.why}
          onChange={handleInput}
          required
        >
          <option value="">Choose your reason</option>
          <option value="Improve my skills">Improve my skills</option>
          <option value="Learn a new skill">Learn a new skill</option>
          <option value="Get a job in tech">Get a job in tech</option>
          <option value="For fun">For fun</option>
          <option value="To get a promotion">To get a promotion</option>
          <option value="Career change">Career change</option>
        </select>
      </div>
      <div className="signup__form-div">
        <label className="signup__form-label block opacity-70 m-2" htmlFor="">
          Have you attended Kodecamp before
        </label>
        <select
          className="signup__form-input select w-full h-[50px] rounded-lg p-2 border border-gray-300 bg-white opacity-60"
          name="attend"
          id="education-select"
          value={formData.attend}
          onChange={handleInput}
          required
        >
          <option value="yes">Yes</option>
          <option value="no">No</option>
        </select>
      </div>

      <div className="signup__form-div">
        <label className="signup__form-label block opacity-70 m-2" htmlFor="">
          How did you hear about us?
        </label>
        <select
          className="signup__form-input select w-full h-[50px] rounded-lg p-2 border border-gray-300 bg-white opacity-60"
          name="how"
          id="how-select"
          value={formData.how}
          onChange={handleInput}
          required
        >
          <option value="">Select one</option>
          <option value="Twitter">Twitter</option>
          <option value="Facebook">Facebook</option>
          <option value="Instagram">Instagram</option>
          <option value="Email">Email</option>
        </select>
      </div>
    </div>
  );
};

export default SignUpTwo;
