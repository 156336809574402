import { FormBuilder } from '@kodehauzinternship/react-library';
import { useEffect, useState } from 'react';
import { FaCheckCircle, FaPlus, FaQuestionCircle } from 'react-icons/fa';
import StatusModal from '../../../../components/StatusModal';
import Modal from '../../../../components/global/Modal';
import { useGlobalContext } from '../../../../context/context';
import { useAdminCategoryService } from '../../../../services/admin.category.service';
import { useCategoryService } from '../../../../services/category.service';
import Accordion from './Accordion';

function Configurations() {
  const [showCategoryForm, setShowCategoryForm] = useState(false);
  const [categoryAdded, setCategoryAdded] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [mergeCategories, setMergeCategories] = useState({});
  const [itemToDelete, setItemToDelete] = useState({});
  const [formData, setFormData] = useState({});
  const [categoryType, setCategoryType] = useState('');
  const { workspace } = useGlobalContext();
  const { createCategory, deleteCategory, updateCategory, getCategory } =
    useAdminCategoryService();
  const { fetchCategory } = useCategoryService();

  const [loadingState, setLoadingState] = useState(false);

  const AddCategoryFormElements = () => {
    const data = [
      {
        id: 'title',
        label: '',
        name: 'title',
        eType: 'text',
        dType: 'text',
        placeholder: '',
        mData: {
          variant: 'outlined',
          label_style: {
            marginBottom: '10px',
            fontSize: '12px',
            color: 'gray',
          },
          custom_label: 'Title',
          width: 48,
        },
        validation: {
          required: {
            value: true,
            message: 'Value is required',
          },
        },
      },
      {
        id: 'name',
        label: '',
        name: 'name',
        eType: 'text',
        dType: 'text',
        placeholder: '',
        mData: {
          variant: 'outlined',
          label_style: {
            marginBottom: '10px',
            fontSize: '12px',
            color: 'gray',
          },
          custom_label: 'Name',
          width: 48,
        },
        validation: {
          required: {
            value: true,
            message: 'Value is required',
          },
        },
      },

      {
        id: 'order',
        label: '',
        name: 'order',
        eType: 'number',
        dType: 'number',
        placeholder: 'Decide order number',
        validation: {
          required: {
            value: true,
            message: 'Value is required',
          },
        },
        mData: {
          variant: 'outlined',
          label_style: {
            marginBottom: '10px',
            fontSize: '12px',
            color: 'gray',
          },
          width: 48,
          custom_label: 'Order',
        },
      },
      {
        id: 'Description',
        label: '',
        name: 'description',
        eType: 'textarea',
        dType: 'text',
        placeholder: '',
        mData: {
          variant: 'outlined',
          label_style: {
            marginBottom: '10px',
            fontSize: '12px',
            color: 'gray',
          },
          custom_label: 'Description',
          width: 48,
          multiline: true,
          rows: 1,
        },
        validation: {
          required: {
            value: true,
            message: 'Value is required',
          },
        },
      },
      {
        id: 'tags',
        label: '',
        name: 'tags',
        eType: 'taginput',
        dType: 'taginput',
        placeholder: 'Category type',
        mData: {
          variant: 'outlined',
          label_style: {
            marginBottom: '10px',
            fontSize: '12px',
            color: 'gray',
          },
          width: 48,
          custom_label: 'Tag Input',
          // select: true,
          options: mergeCategories?.tag?.map((e) => ({
            value: e.name,
            text: e.title,
          })),
        },
      },
      {
        id: 'button',
        label: `${
          formData.id ? `Update ${categoryType}` : `Add ${categoryType}`
        }`,
        name: '',
        eType: 'button',
        dType: 'submit',
        mData: {
          align: 'center',
          variant: 'contained',
          width: 60,
          label_style: {
            display: 'none',
          },
        },
      },
    ];

    return data;
  };

  const fetchData = async () => {
    try {
      const level = await fetchCategory(workspace, 'level');
      const tag = await fetchCategory(workspace, 'tag');
      const track = await fetchCategory(workspace, 'track');
      const stage = await fetchCategory(workspace, 'stage');
      setMergeCategories({ tag, level, track, stage });
    } catch (error) {
      setMergeCategories({ tag: [], level: [], track: [], stage: [] });
    }
  };

  useEffect(() => {
    fetchData();
  }, [workspace]);

  const handleEdit = (category, item) => {
    setCategoryType(category);
    setFormData(item);
    setShowCategoryForm(true);
  };

  const confirmItemDelete = (category, item) => {
    setConfirmDelete(true);
    setItemToDelete({ category: category, item: item });
  };

  const handleDelete = async () => {
    const { category, item } = itemToDelete;

    setLoadingState(true);
    try {
      await deleteCategory(categoryType, item.id);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingState(false);
      setConfirmDelete(false);
    }
  };

  const handleFormSubmit = async (category_data) => {
    setLoadingState(true);
    try {
      const { tags, ...rest } = category_data;
      if (category_data.id) {
        await updateCategory(categoryType, {
          tags: tags ?? [],
          ...rest,
        });
      } else {
        await createCategory(categoryType, {
          tags: tags ?? [],
          ...rest,
        });
      }
      fetchData();
      setCategoryAdded(true);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingState(false);
      setShowCategoryForm(false);
    }
  };

  const onOkayClick = () => {
    setCategoryAdded(false);
    setShowCategoryForm(false);
    setFormData({});
  };

  return (
    <div className="mb-20">
      <div className="flex justify-between items-center mb-5">
        <h1 className="text-xl">Categories</h1>
        <button
          className="px-5 py-2 rounded-md bg-blue-500 text-white text-sm flex items-center gap-2"
          onClick={() => setShowCategoryForm(true)}
          disabled
        >
          <span>
            <FaPlus />
          </span>
          <span>Create Category</span>
        </button>
      </div>
      <div className="mx-auto mt-8">
        {Object.keys(mergeCategories).map((categoryKey) => (
          <Accordion
            key={categoryKey}
            category={categoryKey}
            items={mergeCategories[categoryKey]}
            onEdit={handleEdit}
            onDelete={confirmItemDelete}
            setShowCategoryForm={setShowCategoryForm}
            setCategoryType={setCategoryType}
          />
        ))}
      </div>
      <>
        <Modal
          isOpen={showCategoryForm}
          onClose={() => {
            setShowCategoryForm(false);
            setFormData({});
          }}
        >
          <h1 className="mb-4">Add {categoryType}</h1>
          <FormBuilder
            elements={AddCategoryFormElements()}
            formData={formData}
            onSubmit={handleFormSubmit}
            loadingState={loadingState}
          />
        </Modal>
        <StatusModal
          isOpen={categoryAdded}
          onClose={() => {
            setCategoryAdded(false);
            setFormData({});
          }}
        >
          <div className="bg-white rounded-md flex flex-col items-center space-y-5 w-80 text-center">
            <FaCheckCircle className="text-[65px] text-green-400" />
            <p className="text-2xl text-gray-700">
              Category {formData.id ? 'updated' : 'added'} succesfully
            </p>
            {!formData.id && (
              <p className="text-smtext-gray-500">
                Category added to the workspace
              </p>
            )}
            <button
              className="px-14 w-[90%] py-2 rounded-md bg-blue-500 text-white text-sm"
              onClick={onOkayClick}
            >
              <span>Okay</span>
            </button>
          </div>
        </StatusModal>
        <StatusModal
          isOpen={confirmDelete}
          onClose={() => setConfirmDelete(false)}
        >
          <div className="bg-white rounded-md flex flex-col items-center space-y-5 w-80 text-center">
            <FaQuestionCircle className="text-[65px] text-red-400" />
            <p className="text-2xl text-gray-700">
              Are you sure you want to delete?
            </p>
            <button
              className={`px-14 w-[90%] py-2 rounded-md bg-red-500 text-white text-sm`}
              onClick={handleDelete}
              disabled={loadingState}
            >
              {loadingState ? <span>Deleting...</span> : <span>Delete</span>}
            </button>
          </div>
        </StatusModal>
      </>
    </div>
  );
}

export default Configurations;
