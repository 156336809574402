import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
import '../../../App.css';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { useParams } from 'react-router-dom';
import useFetch from '../../../hooks/useFetch';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import Success from '../../modals/Success';
import DeleteModals from '../../modals/DeleteModals';
import { useGlobalContext } from '../../../context/context';
import { toast } from 'react-toastify';
import LinkPreview from '../LinkPreview';
import { useTaskService } from '../../../services/task.service';

export default function EditTask({ route }) {
  const { id } = useParams();
  const [resources, setResources] = useState([]);
  const [addResources, setAddResources] = useState('');
  const [dataId, setDataId] = useState(null);
  const [taskTitle, setTaskTitle] = useState('');
  const [taskDesc, setTaskDesc] = useState('');
  const [stage, setStage] = useState('');
  const [track, setTrack] = useState('');
  const [level, setLevel] = useState('');
  const [dueDate, setDueDate] = useState('');
  const [points, setPoints] = useState('');
  const [subLinks, setSubLinks] = useState([]);
  const [addLink, setAddLink] = useState('');
  const [isPending, setIsPending] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [selectedCollaborators, setSelectedCollaborators] = useState([]);
  const [showTrainerList, setShowTrainerList] = useState(false);
  const [changesDd, setChangesDd] = useState(false);
  const [showUpdated, setShowUpdated] = useState(false);
  const [deleteRequest, setDeleteRequest] = useState(false);
  const { updateTask } = useTaskService();
  const [mappedTrainers, setMappedTrainer] = useState([]);
  const currentUser = JSON.parse(localStorage.getItem('user'));
  const accountData = JSON.parse(localStorage.getItem('account'));

  const { stages, levels, tracks, userProfile, trainerList, workspace } =
    useGlobalContext();
  const { data: task } = useFetch(`/tasks/${id}`, workspace);

  const handleAddLink = () => {
    setSubLinks([...subLinks, addLink]);
    setAddLink('');
  };

  useEffect(() => {
    setMappedTrainer(
      Object.values(trainerList || {})?.filter(
        (e) =>
          e.trainer.level_id === task?.level_id &&
          e.trainer.track_id === task?.track_id
      )
    );
  }, [task, trainerList]);

  const onFormSubmit = () => {
    setResources([...resources, addResources]);
    setAddResources('');
  };

  const removeResources = ({ id }) => {
    setAddResources(addResources.filter((item) => item.id !== id));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (subLinks.length < 1) {
      toast.error('Please add submission link name');
      return;
    }

    if (taskDesc.trim() === '') {
      toast.error('Please add task description');
      return;
    }

    const abortCont = new AbortController();
    const task = {
      id,
      level_id: level,
      track_id: track,
      stage_id: stage,
      title: taskTitle,
      description: taskDesc,
      submission_links: subLinks,
      resources: resources,
      collaborators: selectedCollaborators,
      points: points,
      due_date: dueDate,
      status: 'posted',
    };

    try {
      setIsPending(true);
      updateTask(task, { signal: abortCont.signal });

      setShowUpdated(true);
    } catch (error) {
      if (error.name === 'AbortError') {
        console.error(error.name);
      } else {
        console.error('Error editing task:', error);
      }
      setIsPending(false);
      setErrorMsg(error.message);
      return () => abortCont.abort();
    } finally {
      setIsPending(false);
    }
  };

  const toggleCollaborator = (e) => {
    const value = e.target.value;
    setSelectedCollaborators((prevSelected) => {
      if (prevSelected.includes(+value)) {
        return prevSelected.filter((id) => id !== +value);
      } else {
        return [...prevSelected, +value];
      }
    });
  };

  useEffect(() => {
    setDataId(localStorage.getItem('id'));
    setTrack(localStorage.getItem('track_id'));
    setStage(localStorage.getItem('stage_id'));
    setTaskTitle(localStorage.getItem('title'));
    setTaskDesc(localStorage.getItem('description'));
    setSubLinks(JSON.parse(localStorage.getItem('submission_links')));
    setResources(JSON.parse(localStorage.getItem('resources')));
    setPoints(localStorage.getItem('points'));
    setDueDate(localStorage.getItem('due_date'));
    setLevel(localStorage.getItem('level_id'));
    setSelectedCollaborators(
      JSON.parse(localStorage.getItem('collaborators'))?.map((e) => e.user.id)
    );
  }, [setDataId]);

  return (
    <div>
      <p className="text-sm text-blue-ribbon-500">
        Tasks <span className="text-grey-500">/ {task && task.taskTitle}</span>
      </p>

      {errorMsg && <p className="text-red-500 text-xl">{errorMsg}</p>}

      <form action="POST" onSubmit={handleSubmit}>
        <div className="flex items-center justify-between mt-2">
          <h2 className="text-2xl">Edit Task</h2>
          <div className="flex items-center gap-2 relative">
            {!isPending && (
              <div className="flex items-center ">
                <div className="flex items-center text-white text-base">
                  <button
                    className="bg-blue-ribbon-500 px-4 py-2 rounded-l-lg cursor-pointer"
                    type="submit"
                  >
                    Save Task
                  </button>

                  <div
                    className="bg-blue-ribbon-500 px-3 py-2 rounded-r-lg border-l border-grey-100"
                    onClick={() => setChangesDd(!changesDd)}
                  >
                    {!changesDd ? (
                      <IoIosArrowDown className="text-[1.45rem]" />
                    ) : (
                      <IoIosArrowUp className="text-[1.45rem]" />
                    )}
                  </div>
                </div>
              </div>
            )}

            {changesDd ? (
              <div className="absolute right-0 top-12 bg-white border border-gray-400 px-3.5 py-4 rounded-lg text-base text-[#303030] flex flex-col gap-3 shadow">
                <div className="flex flex-col gap-3">
                  <p onClick={handleSubmit}>Save Task</p>
                  <p>Discard changes</p>
                </div>
                <p
                  className="text-red-500 border-t border-gray-400 pt-2"
                  onClick={() => setDeleteRequest(true)}
                >
                  Delete Task
                </p>
              </div>
            ) : null}

            {isPending && (
              <button
                disabled
                className="bg-blue-ribbon-500 px-4 py-2 rounded-lg text-white text-base"
              >
                Updating Task...
              </button>
            )}
          </div>
        </div>

        <div className="grid md:grid-cols-5 gap-6 mt-6">
          <div className="md:col-span-3 bg-white p-6 rounded-xl flex flex-col gap-3">
            <div className="flex flex-col gap-1">
              <label className="text-base text-grey-500">Task Title</label>
              <input
                type="text"
                placeholder="Give your task a name"
                className="border border-grey-500 px-4 py-3"
                value={taskTitle}
                required
                onChange={(e) => setTaskTitle(e.target.value)}
              />
            </div>

            <div className="flex flex-col gap-1 w-[99%]">
              <label className="text-base text-grey-500">
                Task Description
              </label>
              <div className="CkEditor">
                <CKEditor
                  className="h-full"
                  editor={Editor}
                  data={taskDesc}
                  onReady={(editor) => {}}
                  onChange={(event, editor) => {
                    setTaskDesc(editor.getData());
                  }}
                  onBlur={(event, editor) => {}}
                  onFocus={(event, editor) => {}}
                />
              </div>
            </div>
          </div>

          <div className="md:col-span-2 bg-white p-6 rounded-xl flex flex-col gap-3">
            <div className="flex flex-col gap-1">
              <label className="text-base text-grey-500">Stage</label>
              <select
                className="border border-grey-500 px-2 py-3 rounded-lg"
                value={stage}
                onChange={(e) => setStage(e.target.value)}
              >
                <option value="">What stage is this task for?</option>
                {Object.values(stages)?.map((e) => {
                  return (
                    <option key={e.name} value={e.id}>
                      {e.title}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="flex flex-col gap-1">
              <label className="text-base text-grey-500">Track</label>
              <input
                type="text"
                className="border border-grey-500 px-2 py-3 rounded-lg"
                value={tracks && tracks[userProfile?.trainer?.track_id]?.title}
                readOnly
              />
            </div>

            <div className="flex flex-col gap-1">
              <label className="text-base text-grey-500">Level</label>
              <input
                type="text"
                className="border border-grey-500 px-2 py-3 rounded-lg"
                value={levels && levels[userProfile?.trainer?.level_id]?.title}
                readOnly
              />
            </div>

            <div className="flex flex-col gap-1">
              <label className="text-base text-grey-500">Due date</label>
              <input
                type="datetime-local"
                className="border border-grey-500 px-2 py-3 rounded-lg"
                value={dueDate}
                onChange={(e) => setDueDate(e.target.value)}
              />
            </div>

            <div className="flex flex-col gap-1">
              <label className="text-base text-grey-500">Points</label>

              <input
                type="number"
                className="border border-grey-500 px-2 py-3 rounded-lg"
                max="100"
                placeholder="Points"
                value={points}
                required
                onChange={(e) => setPoints(e.target.value)}
              />
            </div>

            <>
              <label className="text-base text-grey-500">Collaborators</label>
              <div className="w-full py-3 px-2 rounded-lg border relative cursor-pointer">
                <label
                  onClick={() => {
                    setShowTrainerList(!showTrainerList);
                  }}
                  className="text-gray-500 text-[12px] cursor-pointer"
                >
                  {selectedCollaborators?.length > 0
                    ? selectedCollaborators
                        ?.map(
                          (id) =>
                            trainerList?.[id]?.profile?.first_name +
                            ' ' +
                            trainerList?.[id]?.profile?.last_name
                        )
                        .join(', ')
                    : 'Select Collaborators:'}
                </label>
                {showTrainerList && (
                  <div className="absolute left-0 right-0 top-14 md:top-10 bg-white rounded-lg shadow-lg">
                    {mappedTrainers &&
                      mappedTrainers?.map((e) => (
                        <div key={e?.id} className="flex items-center p-2 mb-2">
                          <input
                            type="checkbox"
                            value={e?.id}
                            checked={
                              selectedCollaborators?.includes(`${e?.id}`) ||
                              selectedCollaborators?.includes(e?.id)
                            }
                            onChange={toggleCollaborator}
                            className="bg-yellow-500 m-0 p-0 w-3 h-3"
                          />
                          <span className="ml-3 m-0 text-[12px]">{`${e?.profile?.first_name} ${e?.profile?.last_name}`}</span>
                        </div>
                      ))}
                  </div>
                )}
              </div>
            </>
          </div>
        </div>

        <div className="grid md:grid-cols-5 gap-6 mt-6">
          <div className="md:col-span-3 bg-white p-2 md:p-6 rounded-xl">
            <h1 className="text-xl">Submission Info</h1>
            <div className="flex flex-col gap-1 mt-2 mb-4">
              <label className="text-base text-grey-500">Link Name</label>
              <input
                type="text"
                className="py-4 px-3 border border-gray"
                placeholder="What platform should this link to? e.g figmaprototype"
                value={addLink}
                onChange={(e) => setAddLink(e.target.value)}
              />
            </div>
            <div
              className="flex justify-start items-start"
              onClick={handleAddLink}
            >
              <h1 className="cursor-pointer bg-blue-ribbon-500 px-4 py-2 text-white text-sm rounded-md">
                {' '}
                Add Link Name
              </h1>
            </div>
          </div>

          <div className="md:col-span-2 bg-white p-2 md:p-6 rounded-xl">
            <h1 className="text-xl">Submission Links Required</h1>
            <ol className="list-decimal space-y-2 mt-2">
              {subLinks.length < 1 ? (
                <p className="text-sm text-[#585858] mt-4">Nil</p>
              ) : (
                subLinks.map((item, idx) => {
                  return (
                    <li
                      className="flex justify-between items-center w-full"
                      key={idx}
                    >
                      <div className="flex items-center overflow-x-auto">
                        <p className="text-sm mr-1">{idx + 1}.</p>
                        <p className="truncate">{item}</p>
                      </div>
                    </li>
                  );
                })
              )}
            </ol>
          </div>
        </div>

        <div className="grid md:grid-cols-5 gap-6 mt-6">
          <div className="md:col-span-3 bg-white p-2 md:p-6 rounded-xl">
            <h1 className="text-xl">Add Resources</h1>
            <form action="">
              <div className="flex flex-col gap-1 mt-2 mb-4">
                <label className="text-base text-grey-500">Resource Link</label>
                <input
                  type="url"
                  className="py-4 px-3 border border-gray"
                  placeholder="Enter link to file"
                  value={addResources}
                  onChange={(e) => setAddResources(e.target.value)}
                  required
                />
              </div>
              <div
                className="flex justify-start items-start"
                onClick={onFormSubmit}
              >
                <h1 className="cursor-pointer bg-blue-ribbon-500 px-4 py-2 text-white text-sm rounded-md">
                  {' '}
                  Add Resource
                </h1>
              </div>
            </form>
          </div>

          <div className="md:col-span-2 bg-white p-2 md:p-6 rounded-xl">
            <h1 className="text-xl">Added Resources</h1>
            <ul>
              {resources.length < 1 ? (
                <p className="text-sm text-[#585858] mt-4">Nil</p>
              ) : (
                resources.map((item, idx) => {
                  return (
                    <li
                      className="my-2 border py-1 px-1 list-none flex justify-between items-center rounded-md"
                      key={idx}
                    >
                      <a
                        href={item}
                        target="_blank"
                        rel="noreferrer"
                        className="text-blue-500 overflow-x-auto truncate"
                      >
                        {item}
                      </a>
                      <button
                        className="text-[red] font-bold"
                        onClick={() => removeResources(idx)}
                      >
                        x
                      </button>
                    </li>
                  );
                })
              )}
            </ul>
          </div>
        </div>
      </form>
      {showUpdated && (
        <Success
          setShow={setShowUpdated}
          title={'Task updated!'}
          body={'Changes made to this task have been saved'}
          path={`/${route}/task`}
        />
      )}

      {deleteRequest && (
        <DeleteModals
          Req={deleteRequest}
          setReq={setDeleteRequest}
          deleteTitle="Delete this task"
          deleteMessage={`Are you sure you want to delete the task “${taskTitle}”?`}
          title="Task Deleted!"
          message1={`The task “${taskTitle}” has been deleted`}
          path="/instructor/task/tasks"
          btnSuccess2="Okay"
        />
      )}
    </div>
  );
}
