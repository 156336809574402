import { useGlobalContext } from '../context/context';
import { fetchData } from '../hooks/api';

export const useProfileService = () => {
  const { workspace: currentWS } = useGlobalContext();
  const fetchProfile = async (workspace = null) => {
    const slug = workspace || currentWS;
    return fetchData('/profiles', slug);
  };
  return { fetchProfile };
};
