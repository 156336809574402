import { createData } from '../hooks/api';

export const useSwitchService = () => {
  const switchCohort = async (workspace_id, workspace_slug) => {
    return createData('/users/switch', workspace_id, workspace_slug);
  };

  return {
    switchCohort,
  };
};
