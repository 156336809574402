import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import { useGlobalContext } from '../context/context';

import ForgotPassword from '../pages/authentication/ForgotPassword';
import Login from '../pages/authentication/Login';
import NewPassword from '../pages/authentication/NewPassword';
import SignUp from '../pages/authentication/SIgnUp';
import InstructorSignUp from '../pages/authentication/InstructorSignUp';
import VerifyEmail from '../pages/authentication/VerifyEmail';
import Error404 from '../pages/error/Error404';

import Student from '../pages/student/Student';

import {
  StudentProfile,
  StudentDashboard,
  StudentSupport,
  Settings,
  StudentClassroom,
  SavedBookmarks,
  AboutStudent,
  Calender,
  StudentCurriculum,
} from '../pages/student/components';

import Instructor from '../pages/instructor/Instructor';
import {
  InstructorDashboard,
  InstructorProfile,
  ClassroomTrainer,
  CurriculumTrainer,
  InstructorStudentProfile,
} from '../pages/instructor/components';

import NewLesson from '../pages/instructor/components/NewLesson';
import AboutTrainer from '../pages/instructor/components/AboutTrainer';

import Admin from '../pages/admin/Admin';
import {
  AdminDashboard,
  AdminProfile,
  CurriculumAdmin,
  AdminClassroom,
  AdminNewLesson,
  AboutAdmin,
  AdminTrainerList,
  TrainerProfile,
  AdminSupport,
  MoreCalendar,
  StudentListPromotion,
  Configurations,
} from '../pages/admin/components';

import EditTask from '../components/global/task/EditTask';
import CreateTask from '../components/global/task/CreateTask';
import TaskDetailsSUBMISSION from '../components/global/task/TaskDetailsSUBMISSION';
import TaskDetailsTASK from '../components/global/task/TaskDetailsTASK';
import TaskDetails from '../components/global/task/TaskDetails';
import TaskHeader from '../components/global/task/TaskHeader';
import TaskList from '../components/global/task/TaskList';
import DraftTask from '../components/global/task/DraftTask';
import StudentList from '../components/global/studentlist/StudentList';
import Workspace from '../pages/admin/components/Workspace/Workspace';

const AllRoutes = () => {
  const currentUser = JSON.parse(localStorage.getItem('user'));
  const currentAccount = JSON.parse(localStorage.getItem('account'));

  const { userData } = useGlobalContext();

  return (
    <BrowserRouter>
      <Routes>
        {/* Onboarding and Authentication Routes => localhost:3000/~ */}
        <Route path="/" element={<Login />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/signup-instructor" element={<InstructorSignUp />} />
        <Route path="/verify-email" element={<VerifyEmail />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="forgot-password/new-password" element={<NewPassword />} />

        {/* Student Routes => localhost:3000/student/~ */}

        {currentAccount?.access_token && currentUser?.type === 'learner' ? (
          <Route path="student" element={<Student />}>
            <Route
              path=""
              element={<Navigate replace to="/student/dashboard" />}
            />
            <Route path="dashboard" element={<StudentDashboard />} />
            <Route path="dashboard/morecalendar" element={<MoreCalendar />} />
            <Route path="profile" element={<StudentProfile />} />
            {/* <Route path='tasks' element={<StudentTasks />} /> */}
            <Route path="task" element={<TaskList route={'student'} />} />
            <Route
              path="task/:id"
              element={<TaskDetailsTASK route={'student'} />}
            />
            <Route path="settings" element={<Settings />} />
            <Route path="support" element={<StudentSupport />} />
            <Route path="calender" element={<Calender />} />
            <Route path="studentclassroom" element={<StudentClassroom />} />
            <Route
              path="studentclassroom/savedbookmarks"
              element={<SavedBookmarks />}
            />
            <Route path="aboutstudent/:id" element={<AboutStudent />} />
            <Route path="studentcurriculum" element={<StudentCurriculum />} />
          </Route>
        ) : currentAccount?.access_token && currentUser?.type === 'trainer' ? (
          <Route path="instructor" element={<Instructor />}>
            <Route
              path=""
              element={<Navigate replace to="/instructor/dashboard" />}
            />
            <Route path="dashboard" element={<InstructorDashboard />} />
            <Route path="dashboard/morecalendar" element={<MoreCalendar />} />
            <Route path="profile" element={<InstructorProfile />} />
            <Route path="classroom" element={<ClassroomTrainer />} />
            <Route path="newlesson/:id" element={<NewLesson />} />
            <Route path="abouttrainer/:id" element={<AboutTrainer />} />
            <Route
              path="task"
              element={
                <TaskHeader
                  taskroute={'/instructor/task/tasks'}
                  draftroute={'/instructor/task/draft'}
                  addtaskroute={`/instructor/task/create-new-task`}
                />
              }
            >
              <Route path="" element={<Navigate replace to="tasks" />} />
              <Route path="tasks" element={<TaskList route={'instructor'} />} />
              <Route path="draft" element={<DraftTask />} />
            </Route>
            <Route
              path="task/:id"
              element={<TaskDetails route={'instructor'} />}
            >
              <Route path="" element={<Navigate replace to="details" />} />
              <Route
                path="details"
                element={<TaskDetailsTASK route={'instructor'} />}
              />
              <Route path="submissions" element={<TaskDetailsSUBMISSION />} />
            </Route>
            <Route
              path="task/create-new-task/"
              element={<CreateTask route={'/instructor/task'} />}
            />
            <Route
              path="task/edit-task/:id"
              element={<EditTask route={'instructor'} />}
            />
            <Route path="curriculum" element={<CurriculumTrainer />} />
            <Route path="settings" element={<Settings />} />
            <Route path="support" element={<StudentSupport />} />
            <Route
              path="student"
              element={<StudentList route={'instructor'} />}
            />
            <Route path="student/:id" element={<InstructorStudentProfile />} />
          </Route>
        ) : currentAccount?.access_token && currentUser?.type === 'admin' ? (
          <Route path="admin" element={<Admin />}>
            <Route
              path=""
              element={<Navigate replace to="/admin/dashboard" />}
            />
            <Route path="dashboard" element={<AdminDashboard />} />
            <Route path="dashboard/morecalendar" element={<MoreCalendar />} />
            <Route path="profile" element={<AdminProfile />} />
            <Route path="curriculum" element={<CurriculumAdmin />} />
            <Route path="adminclassroom" element={<AdminClassroom />} />
            <Route path="adminnewlesson/:id" element={<AdminNewLesson />} />
            <Route path="aboutadmin/:id" element={<AboutAdmin />} />
            <Route
              path="student-list"
              element={<StudentList route={'admin'} />}
            />
            <Route
              path="student-list/promotion/:type"
              element={<StudentListPromotion />}
            />
            <Route path="trainer" element={<AdminTrainerList />} />
            <Route path="trainer/:id" element={<TrainerProfile />} />
            <Route
              path="task"
              element={
                <TaskHeader
                  taskroute={'/admin/task/tasks'}
                  draftroute={'/admin/task/draft'}
                  addtaskroute={`/admin/task/create-new-task`}
                />
              }
            >
              <Route path="" element={<Navigate replace to="tasks" />} />
              <Route path="tasks" element={<TaskList route={'admin'} />} />
              <Route path="draft" element={<DraftTask />} />
            </Route>
            <Route path="task/:id" element={<TaskDetails route={'admin'} />}>
              <Route path="" element={<Navigate replace to="details" />} />
              <Route path="details" element={<TaskDetailsTASK />} />
              <Route path="submissions" element={<TaskDetailsSUBMISSION />} />
            </Route>
            <Route
              path="task/create-new-task/"
              element={<CreateTask route={'/admin/task'} />}
            />
            <Route
              path="task/edit-task/:id"
              element={<EditTask route={'admin'} />}
            />
            <Route path="admin-support" element={<AdminSupport />} />
            <Route path="settings" element={<Settings />} />
            <Route path="student/:id" element={<InstructorStudentProfile />} />
            <Route path="workspace" element={<Workspace />} />
            <Route path="configuration" element={<Configurations />} />
          </Route>
        ) : (
          <Route path="/" element={<Login />} />
        )}

        {/* Error 404 Page */}
        <Route path="*" element={<Error404 />} />
      </Routes>
    </BrowserRouter>
  );
};

export default AllRoutes;
