import React from 'react';
import { Link } from 'react-router-dom';
import { useGlobalContext } from '../../context/context';

export default function ProfileDropDown({ profileDropDown, route }) {
  const currentUser = JSON.parse(localStorage.getItem('user'));
  const { userProfile, tracks, levels, stages } = useGlobalContext();

  const handleLogout = async () => {
    localStorage.clear();
    window.location.replace('/');
  };

  return (
    <div className={`${profileDropDown ? 'block' : 'hidden'}`}>
      <div className="bg-white fixed top-24 right-8 rounded shadow z-50 border border-gray-300">
        <div className="absolute bg-white h-6 w-6 rotate-45 right-6 -top-3 border-l border-t border-gray-300"></div>
        <div className="head p-3 border-b">
          <h1 className="text-blue-ribbon-500 uppercase">
            {userProfile?.profile?.first_name +
              ' ' +
              userProfile?.profile?.last_name}
          </h1>

          <p className="text-sm text-gray-500 mt-2">{userProfile?.username}</p>
          {currentUser?.type === 'trainer' ? (
            <div className="flex items-center justify-between text-xs text-gray-700 space-x-2 py-3">
              <p className="">
                {tracks[userProfile?.trainer?.track_id]?.title +
                  ' ' +
                  currentUser?.type?.charAt(0).toUpperCase() +
                  currentUser?.type.slice(1)}{' '}
              </p>
              <p className="w-[4px] h-[4px] rounded-full bg-black"></p>
              <p className="">
                {levels[userProfile?.trainer?.level_id]?.title}
              </p>
            </div>
          ) : currentUser?.type === 'learner' ? (
            <div className="flex items-center justify-between text-xs text-gray-500 space-x-2 py-3">
              <p className="">
                {tracks[userProfile?.student?.track_id]?.title}
              </p>
              <p className="w-[4px] h-[4px] rounded-full bg-black"></p>
              <p className="">
                {currentUser?.type?.charAt(0).toUpperCase() +
                  currentUser?.type.slice(1)}{' '}
              </p>
              <p className="w-[4px] h-[4px] rounded-full bg-black"></p>
              <p className="">
                {levels[userProfile?.student?.level_id]?.title}
              </p>
            </div>
          ) : null}
          {currentUser?.type === 'learner' && (
            <p className="text-sm text-gray-500">
              {stages[userProfile?.student?.stage_id]?.title}
            </p>
          )}
          {currentUser?.type === 'learner' ? (
            <p className="text-sm font-light text-gray-500 mt-2">
              ID: KC-STD-{currentUser?.id}
            </p>
          ) : currentUser?.type === 'trainer' ? (
            <p className="text-sm font-light text-gray-500">
              ID: KC-TRN-{currentUser?.id}
            </p>
          ) : (
            <p className="text-sm font-light text-gray-500">
              ID: KC-ADM-{currentUser?.id}
            </p>
          )}
        </div>

        <div className="border-b body p-3 text-[grey]">
          <Link to={`/${route}/profile`}>
            <h1 className="mb-4 hover:text-blue-ribbon-500 cursor-pointer">
              <i className="fa-solid fa-user mr-4"></i>View Profile
            </h1>
          </Link>
          {
            <Link to={`/${route}/settings`}>
              <h1 className="hover:text-blue-ribbon-500 cursor-pointer">
                <i className="fa-solid fa-lock mr-4"></i>Change Password
              </h1>
            </Link>
          }
        </div>
        <div className="footer p-3">
          <h1 className="text-[red] cursor-pointer" onClick={handleLogout}>
            <i className="fa-solid fa-right-from-bracket mr-4"></i>Logout
          </h1>
        </div>
      </div>
    </div>
  );
}
