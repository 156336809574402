import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { FaCertificate } from 'react-icons/fa';
import { useGlobalContext } from '../../../../context/context';
import useFetch from '../../../../hooks/useFetch';
import { capitalizeFirstLetter } from '../../../../utils/capitalizeFirstLetter';
import AvatarWidget from '../../../../components/global/AvatarWidget';

function TrainerProfile() {
  const { id } = useParams();
  const { workspace, levels, tracks } = useGlobalContext();
  const [trainer, setTrainer] = useState();

  const { data, isPending: isLoading } = useFetch(`/profiles/${id}`, workspace);

  useEffect(() => {
    setTrainer(data);
  }, [data]);

  if (isLoading) {
    return (
      <>
        <h1>Loading...</h1>
      </>
    );
  }
  return (
    <>
      <div>
        <div className="top">
          <span className="text-blue-ribbon-500 mr-2">Trainers/</span>
          <span>
            {trainer?.profile?.first_name + ' ' + trainer?.profile?.last_name}
          </span>
        </div>
      </div>
      <div className="p-5 bg-white rounded-md">
        <div className="flex space-x-8 items-center">
          <AvatarWidget
            avatarUrl={trainer?.profile?.avatar}
            w={'200px'}
            h={'200px'}
            text={'100px'}
            owner={trainer?.profile}
          />{' '}
          <div className="content space-y-4">
            <h1 className="text-xl">
              {trainer?.profile?.first_name + ' ' + trainer?.profile?.last_name}
            </h1>

            <p className="text-grey-500">
              Track:{' '}
              <span className="text-[grey]">
                {tracks[trainer?.trainer?.track_id]?.title}
              </span>
            </p>
            <p className="text-grey-500">
              Level:{' '}
              <span className="text-[grey]">
                {levels[trainer?.trainer?.level_id]?.title}
              </span>
            </p>
          </div>
        </div>
        <h1 className="underline mt-8">Trainer Bio</h1>
        <div className="overflow-auto">
          <div className="grid grid-cols-2 md:grid-cols-4 mt-8  w-[400px] md:w-full">
            <div className="left">
              <ul className="space-y-4">
                <li className="border-b pb-1">Username</li>
                <li className="border-b pb-1">Email Address</li>
                <li className="border-b pb-1">Date of Birth</li>
                <li className="border-b pb-1">Gender</li>
                <li className="border-b pb-1">Phone Number</li>
                <li className="border-b pb-1">Employment Status</li>
                <li className="border-b pb-1">Employment Qualification</li>
              </ul>
            </div>

            <div className="right md:col-span-3">
              <ul className="space-y-4">
                <li className="border-b pb-1 pl-5">{trainer?.username}</li>
                <li className="border-b pb-1 pl-5 overflow-hidden">
                  {trainer?.email}
                </li>
                <li className="border-b pb-1 pl-5">
                  {trainer?.id && trainer?.id}
                </li>
                <li className="border-b pb-1 pl-5">
                  {capitalizeFirstLetter(trainer?.profile?.gender)}
                </li>
                <li className="border-b pb-1 pl-5">{trainer?.phone}</li>
                <li className="border-b pb-1 pl-5">
                  {trainer?.info?.qualification}
                </li>
                <li className="border-b pb-1 pl-5">
                  {trainer?.info?.employment_status}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <button className="my-6 px-6 py-2 text-white bg-blue-ribbon-500 rounded-md">
          <FaCertificate className="inline mr-3 text-2xl" />
          <span>View CV</span>
        </button>
      </div>
    </>
  );
}

export default TrainerProfile;
