import React from 'react';
import './Progress.css';
import { ProgressBar } from './ProgressBar';

const Progress = (props) => {
  return (
    <div className="bg-white py-4 px-3 rounded-xl">
      <p className="text-lg">{props.progressTitle}</p>
      <div className="flex flex-col gap-6 justify-center mt-4">
        <ProgressBar
          title={`${props.track} - Week ${props.week}`}
          value={`${props.percentage}%`}
          percentage={props.percentage}
        />

        <ProgressBar
          title={props.pointsTitle}
          value={
            props.totalScore && props.totalPoints
              ? `${props.totalScore} / ${props.totalPoints}`
              : props.totalScore || props.totalPoints || ''
          }
          percentage={
            props.totalPoints === 0
              ? 0
              : (props.totalScore / props.totalPoints) * 100
          }
        />

        <ProgressBar
          title={props.stagesTitle}
          value={
            props.currentStage !== null && props.countOfStages !== null
              ? `${props.currentStage} / ${props.countOfStages}`
              : props.currentStage || props.countOfStages || ''
          }
          percentage={(props.currentStage / props.countOfStages) * 100}
        />
      </div>
    </div>
  );
};

export default Progress;
