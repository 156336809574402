// import { useCategoryService } from '../../services/category.service';

export const OldStdFormElements = (availableCohorts, levels, tracks) => {
  const data = [
    {
      type: 'select',
      name: 'cohort',
      placeholder: 'Select Cohor',
      label: 'Cohort',
      method: 'select',
      selectOption: availableCohorts?.map((e) => ({
        value: e.slug,
        label: e.label,
      })),
    },
    {
      type: 'select',
      name: 'level',
      placeholder: 'Select a level',
      label: 'Level',
      method: 'select',
      selectOption: levels?.map((e) => ({
        value: e.name,
        label: e.title,
      })),
    },
    {
      type: 'select',
      name: 'track',
      placeholder: 'Select a track',
      label: 'Track',
      method: 'select',
      selectOption: tracks?.map((e) => ({
        value: e.id,
        label: e.title,
      })),
    },
    // {
    //   type: 'select',
    //   name: 'type',
    //   placeholder: 'Select a role',
    //   label: 'Join as',
    //   method: 'select',
    //   selectOption: [
    //     { value: 'learner', label: 'Student' },
    //     { value: 'trainer', label: 'Trainer' },
    //   ],
    // },
    {
      type: 'text',
      name: 'employment',
      placeholder: 'Select employment',
      label: 'Employment Status',
      method: 'select',
      selectOption: [
        { value: 'student', label: 'Student' },
        { value: 'unemployed', label: 'Unemployed' },
        { value: 'self-employed', label: 'Self-Employed' },
        { value: 'employed', label: 'Employed' },
      ],
    },
    {
      type: 'text',
      name: 'qualification',
      placeholder: 'Select qualification',
      label: 'Educational Qualification',
      method: 'select',
      selectOption: [
        { value: 'high school', label: 'High School' },
        { value: 'undergraduate', label: 'Undergraduate' },
        { value: 'postgraduate', label: 'Postgraduate' },
        { value: 'masters', label: 'Masters' },
        { value: 'phd', label: 'PhD' },
      ],
    },
  ];
  return data;
};

export const AddWorkspaceFormElements = (editMode) => {
  const data = [
    {
      id: 'title',
      label: '',
      name: 'label',
      eType: 'text',
      dType: 'text',
      placeholder: '',
      mData: {
        variant: 'outlined',
        label_style: {
          marginBottom: '10px',
          fontSize: '12px',
          color: 'gray',
        },
        custom_label: 'Label',
        width: 48,
      },
      validation: {
        required: {
          value: true,
          message: 'Value is required',
        },
      },
    },
    {
      id: 'name',
      label: '',
      name: 'name',
      eType: 'text',
      dType: 'text',
      placeholder: '',
      mData: {
        variant: 'outlined',
        label_style: {
          marginBottom: '10px',
          fontSize: '12px',
          color: 'gray',
        },
        custom_label: 'Name',
        width: 48,
      },
      validation: {
        required: {
          value: true,
          message: 'Value is required',
        },
      },
    },
    {
      id: 'status',
      label: '',
      name: 'status',
      eType: 'text',
      dType: 'text',
      placeholder: 'Select status',

      validation: {
        required: {
          value: true,
          message: 'Value is required',
        },
      },
      mData: {
        variant: 'outlined',
        label_style: {
          marginBottom: '10px',
          fontSize: '12px',
          color: 'gray',
        },
        width: 48,
        custom_label: 'Workspace Status',
        select: true,
        options: [
          {
            value: 'open',
            text: 'Open',
          },
          {
            value: 'restricted',
            text: 'Restricted',
          },
          {
            value: 'readonly',
            text: 'Read Only',
          },
          {
            value: 'locked',
            text: 'Locked',
          },
        ],
      },
    },
    {
      id: 'upload',
      label: 'Upload Logo',
      name: 'logo',
      eType: 'upload',
      dType: 'upload',
      placeholder: 'Upload',
      helperText: 'Upload a workspace icon less than 2MB',
      mData: {
        variant: 'outlined',
        label_style: {
          marginBottom: '10px',
          fontSize: '12px',
          color: 'gray',
        },
        custom_label: 'Upload logo',
        width: 48,
      },
    },
    {
      id: 'start_date',
      name: 'start_date',
      eType: 'date',
      dType: 'datetime',
      mData: {
        width: 48,
        custom_label: 'Start Date',
        label_style: {
          marginBottom: '10px',
          fontSize: '12px',
          color: 'gray',
        },
      },
    },
    {
      id: 'end_date',
      name: 'end_date',
      eType: 'date',
      dType: 'datetime',
      mData: {
        width: 48,
        custom_label: 'End Date',
        label_style: {
          marginBottom: '10px',
          fontSize: '12px',
          color: 'gray',
        },
      },
    },
    {
      id: 'Description',
      label: '',
      name: 'description',
      eType: 'textarea',
      dType: 'text',
      placeholder: '',
      mData: {
        variant: 'outlined',
        label_style: {
          marginBottom: '10px',
          fontSize: '12px',
          color: 'gray',
        },
        custom_label: 'Description',
        // width: 60,
        multiline: true,
        rows: 4,
      },
      validation: {
        required: {
          value: true,
          message: 'Value is required',
        },
      },
    },
    {
      id: 'reg_link',
      label: 'Create registration link for new users',
      name: 'generate_link',
      eType: 'checkbox',
      dType: 'boolean',
      mData: {
        width: 48,
      },
    },
    {
      id: 'advertise',
      label: 'Advertise new workspace to existing LMS users',
      name: 'publish_to_users',
      eType: 'checkbox',
      dType: 'boolean',
      mData: {
        width: 48,
      },
    },
    {
      id: 'button',
      label: `${editMode ? 'Save' : 'Create'} Workspace`,
      name: '',
      eType: 'button',
      dType: 'submit',
      mData: {
        align: 'center',
        variant: 'contained',
        width: 60,
        label_style: {
          display: 'none',
        },
      },
    },
  ];

  return data;
};

export const AddCategoryFormElements = () => {
  const data = [
    {
      id: 'title',
      label: '',
      name: 'title',
      eType: 'text',
      dType: 'text',
      placeholder: '',
      mData: {
        variant: 'outlined',
        label_style: {
          marginBottom: '10px',
          fontSize: '12px',
          color: 'gray',
        },
        custom_label: 'Title',
        width: 48,
      },
      validation: {
        required: {
          value: true,
          message: 'Value is required',
        },
      },
    },
    {
      id: 'name',
      label: '',
      name: 'name',
      eType: 'text',
      dType: 'text',
      placeholder: '',
      mData: {
        variant: 'outlined',
        label_style: {
          marginBottom: '10px',
          fontSize: '12px',
          color: 'gray',
        },
        custom_label: 'Name',
        width: 48,
      },
      validation: {
        required: {
          value: true,
          message: 'Value is required',
        },
      },
    },
    {
      id: 'type',
      label: '',
      name: 'type',
      eType: 'text',
      dType: 'select',
      placeholder: 'Category type',
      validation: {
        required: {
          value: true,
          message: 'Value is required',
        },
      },
      mData: {
        variant: 'outlined',
        label_style: {
          marginBottom: '10px',
          fontSize: '12px',
          color: 'gray',
        },
        width: 48,
        custom_label: 'Category Type',
        select: true,
        options: [
          {
            value: 'level',
            text: 'Level',
          },
          {
            value: 'track',
            text: 'Track',
          },
          {
            value: 'stage',
            text: 'Stage',
          },
        ],
      },
    },
    {
      id: 'order',
      label: '',
      name: 'order',
      eType: 'number',
      dType: 'number',
      placeholder: 'Decide order number',
      validation: {
        required: {
          value: true,
          message: 'Value is required',
        },
      },
      mData: {
        variant: 'outlined',
        label_style: {
          marginBottom: '10px',
          fontSize: '12px',
          color: 'gray',
        },
        width: 48,
        custom_label: 'Order',
      },
    },
    {
      id: 'Description',
      label: '',
      name: 'description',
      eType: 'textarea',
      dType: 'text',
      placeholder: '',
      mData: {
        variant: 'outlined',
        label_style: {
          marginBottom: '10px',
          fontSize: '12px',
          color: 'gray',
        },
        custom_label: 'Description',
        width: 48,
        multiline: true,
        rows: 4,
      },
      validation: {
        required: {
          value: true,
          message: 'Value is required',
        },
      },
    },
    {
      id: 'button',
      label: 'Add Category',
      name: '',
      eType: 'button',
      dType: 'submit',
      mData: {
        align: 'center',
        variant: 'contained',
        width: 60,
        label_style: {
          display: 'none',
        },
      },
    },
  ];

  return data;
};
