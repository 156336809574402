import { useGlobalContext } from '../context/context';
import { createData, deleteData, fetchData, updateData } from '../hooks/api';

export const useAdminCategoryService = () => {
  const { workspace } = useGlobalContext();
  const getCategory = async (cat_type, category_id, options = {}) => {
    return fetchData(`/cat/${cat_type}/${category_id}`, workspace, options);
  };

  const updateCategory = async (cat_type, category, options = {}) => {
    return updateData(
      `/cat/${cat_type}`,
      category.id,
      category,
      workspace,
      options
    );
  };

  const createCategory = async (cat_type, category, options = {}) => {
    return createData(`/cat/${cat_type}`, category, workspace, options);
  };

  const deleteCategory = async (cat_type, category_id, options = {}) => {
    return deleteData(`/cat/${cat_type}`, category_id, workspace, options);
  };

  return { getCategory, updateCategory, createCategory, deleteCategory };
};
