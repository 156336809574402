import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import '../../App.css';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { FaTimes } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { useGlobalContext } from '../../../context/context';
import { useTaskService } from '../../../services/task.service';
import PopUp from '../../modals/PopUp';
import LinkPreview from '../LinkPreview';

const CreateTask = ({ route }) => {
  const { stages, levels, tracks, userProfile, trainerList } =
    useGlobalContext();
  const [resources, setResources] = useState([]);
  const [addResources, setAddResources] = useState('');
  const [subLinks, setSubLinks] = useState([]);
  const [addLink, setAddLink] = useState('');
  const [taskTitle, setTaskTitle] = useState('');
  const [taskDesc, setTaskDesc] = useState('');
  const [stage, setStage] = useState(0);
  const [dueDate, setDueDate] = useState('');
  const [points, setPoints] = useState(null);
  const [isPending] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [setIsError] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedCollaborators, setSelectedCollaborators] = useState([]);
  const [showTrainerList, setShowTrainerList] = useState(false);
  const [mappedTrainers, setMappedTrainer] = useState([]);
  const { createTask } = useTaskService();
  const currentUser = JSON.parse(localStorage.getItem('user'));
  const navigate = useNavigate();

  const onFormSubmit = () => {
    setResources([...resources, addResources]);
    setAddResources('');
  };

  const removeResources = (idx) => {
    setResources(resources.filter((item, id) => id !== idx));
  };

  useEffect(() => {
    setMappedTrainer(
      Object.values(trainerList || {})?.filter(
        (e) =>
          e.trainer.level_id === levels[userProfile?.trainer?.level_id]?.id &&
          e.trainer.track_id === tracks[userProfile?.trainer?.track_id]?.id
      )
    );
  }, [levels, tracks, trainerList, userProfile]);

  const handleAddLink = () => {
    setSubLinks([...subLinks, addLink]);
    setAddLink('');
  };

  const removeLink = (idx) => {
    setSubLinks(subLinks.filter((item, id) => id !== idx));
  };

  const toggleCollaborator = (e) => {
    const value = e.target.value;
    setSelectedCollaborators((prevSelected) => {
      if (prevSelected.includes(value)) {
        return prevSelected.filter((id) => id !== value);
      } else {
        return [...prevSelected, value];
      }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setShowModal(false);
    const abortCont = new AbortController();
    if (subLinks.length < 1) {
      toast.error('Please add submission link name');
      return;
    }

    if (taskDesc.trim() === '') {
      toast.error('Please add task description');
      return;
    }

    setIsLoading(true);
    try {
      await createTask(
        {
          level_id: userProfile?.trainer?.level_id,
          track_id: userProfile?.trainer?.track_id,
          stage_id: stage,
          title: taskTitle,
          description: taskDesc,
          submission_links: subLinks,
          resources: resources,
          points: points,
          due_date: dueDate,
          collaborators: selectedCollaborators,
          status: 'posted',
          owner_id: currentUser?.id,
        },
        { signal: abortCont.signal }
      );
      toast.success('Task created successful');
      navigate(route);
      setIsLoading(false);
    } catch (error) {
      if (error === 'AbortError') {
        toast.error('fetch aborted');
      } else {
        toast.error(error);
        console.error(error);
      }
      console.error('Error creating user:', error);
      setIsLoading(false);
      setIsError(true);
    }
    return () => abortCont.abort();
  };

  return (
    <div className="relative">
      {isPending && (
        <PopUp
          title="Task created successfully!"
          message1={`The task “${taskTitle}” has been created.`}
          message2="You’ll need to post this task before it can be visible to students."
          path="/admin/task/tasks"
        />
      )}

      <p className="text-sm text-blue-ribbon-500">
        Tasks <span className="text-grey-500">/ New Task</span>
      </p>
      <form action="POST" onSubmit={handleSubmit}>
        <div className="flex flex-col gap-5 md:flex-row md:items-center justify-between mt-2">
          <h2 className="text-xl md:text-xl">New Task</h2>
          <div className="flex items-center gap-2">
            <div
              className="px-3 py-2 text-sm border border-gray-500 cursor-pointer bg-transparent text-black rounded-md"
              onClick={() => navigate(`${route}`)}
            >
              Cancel
            </div>

            <div
              className="px-3 py-2 text-sm cursor-pointer bg-blue-ribbon-500 rounded-md text-white"
              onClick={() => setShowModal(true)}
            >
              {isLoading ? (
                <div className="w-4 h-4 border-dotted border-t border-b border-r rounded-full animate-spin"></div>
              ) : (
                'Create Task'
              )}
            </div>
          </div>
        </div>
        {showModal && (
          <div className="absolute right-0 left-0 bottom-0 top-0 z-50 flex justify-center items-start pt-14">
            <div className="absolute right-0 left-0 bottom-0 top-0 bg-black opacity-20"></div>
            <div className="modal bg-white min-w-64 min-h-96 p-5 rounded-md z-50">
              <p className="mt-5">Are you sure you want to create the task</p>
              <div className="flex items-center justify-end space-x-4 mt-5">
                <p
                  className="text-red-500 text-sm cursor-pointer"
                  onClick={() => setShowModal(false)}
                >
                  Cancel
                </p>
                <button
                  type="submit"
                  className="h-9 px-10 text-white text-sm rounded-md bg-blue-ribbon-500"
                >
                  {isLoading ? 'Please wait' : 'Create'}
                </button>
              </div>
            </div>
          </div>
        )}
        <div className="grid md:grid-cols-5 gap-6 mt-6">
          <div className="w-full md:col-span-3 bg-white p-2 md:p-6 rounded-xl">
            <div className="flex flex-col gap-3">
              <div className="flex flex-col gap-1">
                <label className="text-base text-grey-500">Task Title</label>
                <input
                  type="text"
                  placeholder="Give your task a name"
                  className="border border-grey-500 px-4 py-3"
                  value={taskTitle}
                  required
                  onChange={(e) => setTaskTitle(e.target.value)}
                />
              </div>

              <div className="flex flex-col w-[99%] gap-1">
                <label className="text-base text-grey-500">
                  Task Description
                </label>
                <div className="CkEditor">
                  <CKEditor
                    className="h-full"
                    editor={Editor}
                    data={taskDesc}
                    onReady={(editor) => {
                      // You can store the "editor" and use when it is needed.
                      // console.log("Editor is ready to use!", editor);
                    }}
                    onChange={(event, editor) => {
                      setTaskDesc(editor.getData());
                      // console.log({ event, editor, data });
                    }}
                    onBlur={(event, editor) => {
                      // console.log('Blur.', editor);
                    }}
                    onFocus={(event, editor) => {
                      // console.log('Focus.', editor);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="w-full md:col-span-2 bg-white p-2 md:p-6 rounded-xl">
            <div className="flex flex-col gap-3">
              <div className="flex flex-col gap-1">
                <label className="text-base text-grey-500">Stage</label>
                <select
                  className="border border-grey-500 px-2 py-3 rounded-lg"
                  value={stage}
                  onChange={(e) => setStage(e.target.value)}
                >
                  <option value="">What stage is this task for?</option>
                  {Object.values(stages)?.map((e) => {
                    return (
                      <option key={e.name} value={e.id}>
                        {e.title}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className="flex flex-col gap-1">
                <label className="text-base text-grey-500">Track</label>
                <input
                  type="text"
                  className="border border-grey-500 px-2 py-3 rounded-lg"
                  value={
                    tracks && tracks[userProfile?.trainer?.track_id]?.title
                  }
                  readOnly
                />
              </div>

              <div className="flex flex-col gap-1">
                <label className="text-base text-grey-500">Level</label>
                <input
                  type="text"
                  className="border border-grey-500 px-2 py-3 rounded-lg"
                  value={
                    levels && levels[userProfile?.trainer?.level_id]?.title
                  }
                  readOnly
                />
              </div>

              <div className="flex flex-col gap-1">
                <label className="text-base text-grey-500">Due date</label>
                <input
                  type="datetime-local"
                  className="border border-grey-500 px-2 py-3 rounded-lg w-full"
                  value={dueDate}
                  required
                  onChange={(e) => setDueDate(e.target.value)}
                />
              </div>

              <div className="flex flex-col gap-1">
                <label className="text-base text-grey-500">Points</label>

                <input
                  type="number"
                  className="border border-grey-500 px-2 py-3 rounded-lg"
                  max="100"
                  placeholder="Points"
                  value={points}
                  required
                  onChange={(e) => setPoints(e.target.value)}
                />
              </div>
              <>
                <label className="text-base text-grey-500">Collaborators</label>
                <div className="w-full py-3 px-2 rounded-lg border relative cursor-pointer">
                  <label
                    onClick={() => setShowTrainerList(!showTrainerList)}
                    className="text-gray-500 text-[12px] cursor-pointer"
                  >
                    {selectedCollaborators?.length > 0
                      ? selectedCollaborators
                          ?.map((id) => trainerList[id]?.profile?.first_name)
                          .join(', ')
                      : 'Select Collaborators:'}
                  </label>
                  {showTrainerList && (
                    <div className="absolute left-0 right-0 top-14 md:top-10 bg-white rounded-lg shadow-lg">
                      {mappedTrainers &&
                        mappedTrainers?.map((e) => (
                          <div
                            key={e.id}
                            className="flex items-center p-2 mb-2"
                          >
                            <input
                              type="checkbox"
                              value={e?.id}
                              checked={selectedCollaborators.includes(
                                `${e?.id}`
                              )}
                              onChange={toggleCollaborator}
                              className="bg-yellow-500 m-0 p-0 w-3 h-3"
                            />
                            <span className="ml-3 m-0 text-[12px]">{`${e?.profile?.first_name} ${e?.profile?.last_name}`}</span>
                          </div>
                        ))}
                    </div>
                  )}
                </div>
              </>
            </div>
          </div>
        </div>

        <div className="grid md:grid-cols-5 gap-6 mt-6">
          <div className="md:col-span-3 bg-white p-2 md:p-6 rounded-xl">
            <h1 className="text-xl">Submission Info</h1>
            <div className="flex flex-col gap-1 mt-2 mb-4">
              <label className="text-base text-grey-500">Link Name</label>
              <input
                type="text"
                className="py-4 px-3 border border-gray"
                placeholder="What platform should this link to? e.g figmaprototype"
                value={addLink}
                onChange={(e) => setAddLink(e.target.value)}
              />
            </div>
            <div
              className="flex justify-start items-start"
              onClick={handleAddLink}
            >
              <h1 className="cursor-pointer bg-blue-ribbon-500 px-4 py-2 text-white text-sm rounded-md">
                {' '}
                Add Link Name
              </h1>
            </div>
          </div>

          <div className="md:col-span-2 bg-white p-2 md:p-6 rounded-xl">
            <h1 className="text-xl">Submission Links Required</h1>
            <ol className="list-decimal space-y-2 mt-2">
              {subLinks.length < 1 ? (
                <p className="text-sm text-[#585858] mt-4">Nil</p>
              ) : (
                subLinks.map((link, idx) => {
                  return (
                    <li
                      className="flex justify-between items-center w-full"
                      key={idx}
                    >
                      <div className="flex items-center overflow-x-auto">
                        <p className="text-sm mr-1">{idx + 1}.</p>
                        <p className="truncate">{link}</p>
                      </div>
                      <div className="w-5 h-5">
                        <FaTimes
                          onClick={() => removeLink(idx)}
                          className="bg-red-600 text-white p-1 rounded"
                        />
                      </div>
                    </li>
                  );
                })
              )}
            </ol>
          </div>
        </div>

        <div className="grid md:grid-cols-5 gap-6 mt-6">
          <div className="md:col-span-3 bg-white p-2 md:p-6 rounded-xl">
            <h1 className="text-xl">Add Resources</h1>
            <div className="flex flex-col gap-1 mt-2 mb-4">
              <label className="text-base text-grey-500">Resource Link</label>
              <input
                type="url"
                className="py-4 px-3 border border-gray"
                placeholder="Enter link to file"
                value={addResources}
                onChange={(e) => setAddResources(e.target.value)}
              />
            </div>
            <div
              className="flex justify-start items-start"
              onClick={onFormSubmit}
            >
              <h1 className="cursor-pointer bg-blue-ribbon-500 px-4 py-2 text-white text-sm rounded-md">
                {' '}
                Add Resource
              </h1>
            </div>
          </div>

          <div className="md:col-span-2 bg-white p-2 md:p-6 rounded-xl h-[220px] overflow-x-auto">
            <h1 className="text-xl">Added Resources</h1>
            <ol className="list-decimal space-y-2 mt-2">
              {resources.length < 1 ? (
                <p className="text-sm text-[#585858] mt-4">Nil</p>
              ) : (
                resources.map((resource, idx) => {
                  return (
                    <li
                      className="flex justify-between items-center w-full"
                      key={idx}
                    >
                      <div className="w-4/5">
                        <a
                          href={resource}
                          target="_blank"
                          rel="noreferrer"
                          className="text-blue-500 overflow-x-auto truncate"
                        >
                          {resource}
                        </a>
                      </div>
                      <div className="w-5 h-5">
                        <FaTimes
                          onClick={() => removeResources(idx)}
                          className="bg-red-600 text-white p-1 rounded"
                        />
                      </div>
                    </li>
                  );
                })
              )}
            </ol>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CreateTask;
