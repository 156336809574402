import React from 'react';
import { AiFillStar } from 'react-icons/ai';
import { useGlobalContext } from '../context/context';

function StageIcon() {
  const { userProfile, stages } = useGlobalContext();

  function stageIcons(stage) {
    switch (stage) {
      case 0:
        return <AiFillStar className="text-lime-800" />;

      case 1:
        return <AiFillStar className="text-cyan-800" />;

      case 2:
        return <AiFillStar className="text-teal-800" />;

      case 3:
        return <AiFillStar className="text-blue-800" />;

      case 4:
        return <AiFillStar className="text-purple-800" />;

      case 5:
        return <AiFillStar className="text-green-800" />;

      case 6:
        return <AiFillStar className="text-red-800" />;

      case 7:
        return <AiFillStar className="text-yellow-800" />;

      case 8:
        return <AiFillStar className="text-black" />;

      default:
        return <AiFillStar className="text-blue-800" />;
    }
  }

  return (
    <div className="flex justify-center text-[100px] relative flex items-center justify-center">
      {stageIcons(stages?.[userProfile?.student?.stage_id]?.order)}
      <h1 className="absolute text-[30px] text-white font-serif mb-2">
        {stages?.[userProfile?.student?.stage_id]?.order}
      </h1>
    </div>
  );
}

export default StageIcon;
