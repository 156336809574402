import React, { useEffect, useState } from 'react';
import './NewPassword.css';
import { AiFillEye } from 'react-icons/ai';

import { Good, Logo } from '../../assets/index';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate } from 'react-router-dom';
import ForgotPasswordModal from './components/ForgotPasswordModal';
import AuthTheme from '../../components/authentication/AuthTheme';
import { FaEyeSlash } from 'react-icons/fa';

const NewPassword = () => {
  const [passwordForm, setPasswordForm] = useState({
    password: '',
    cpassword: '',
  });
  const [toggleEye, setToggleEye] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();

  const handlePassword = (e) => {
    setPasswordForm({
      ...passwordForm,
      [e.target.name]: e.target.value,
    });
  };

  const handleToggle = () => {
    setToggleEye(!toggleEye);
  };

  useEffect(() => {
    const url = new URL(window.location.href);
    const token = url.searchParams.get('token');
    if (!token) {
      navigate('/forgot-password');
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (passwordForm.password === '') {
      toast.error('Password cannot be empty');
      return;
    }

    if (passwordForm.password !== passwordForm.cpassword) {
      toast.error('Passwords do not match');
      return;
    }

    setIsPending(true);

    const url = new URL(window.location.href);
    const token = url.searchParams.get('token');

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/reset-password`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            token: token,
            new_password: passwordForm.password,
          }),
        }
      );

      if (!response.ok) {
        const res = await response.json();
        toast.error(res.detail);
        throw new Error('Failed');
      }

      setIsPending(false);
      setModalOpen(true);
    } catch (error) {
      console.error('Error:', error);
      setIsPending(false);
    }
  };

  return (
    <div className="">
      <AuthTheme>
        <div className="w-11/12 flex flex-col items-center justify-center h-full justify-center items-center flex flex-col gap-6">
          <div className="text w-full flex flex-col gap-6 items-center justify-center">
            <div className="w-20 h-20 rounded-lg bg-transparent shadow-md grid place-items-center">
              <img src={Logo} alt="kodecamp-logo" />
            </div>

            <h2 className="text-[#303030] text-2xl font-medium">
              Create new password
            </h2>

            <div className="flex isolate">
              <span className="w-7 h-7 rounded-full flex justify-center items-center bg-green-600">
                <img src={Good} alt="tick" />
              </span>
              <div className="flex justify-center items-center">
                <hr className="w-14 h-px flex place-items-center text-center bg-gray-600" />
              </div>
              <span className="w-7 h-7 rounded-full flex justify-center items-center bg-green-600">
                <img src={Good} alt="tick" />
              </span>
              <div className="flex justify-center items-center">
                <hr className="w-14 h-px flex place-items-center text-center bg-gray-600" />
              </div>
              <span className="w-7 h-7 rounded-full flex justify-center items-center bg-[#585858]">
                <p className="font-medium text-lg text-center text-white">3</p>
              </span>
            </div>

            <p className="text-lg">
              Kindly set a new password and confirm the password
            </p>
          </div>

          <form onSubmit={handleSubmit} className="w-full flex flex-col gap-7">
            <div className="flex flex-col gap-2 relative">
              <label className="text-[#808080]">New Password</label>
              <div className="border border-gray-400 bg-white rounded-md px-3 flex justify-between items-center">
                <input
                  placeholder="Type new password"
                  type={toggleEye ? 'password' : 'text'}
                  className="w-full outline-none bg-transparent pl-2"
                  name="password"
                  value={passwordForm.password}
                  onChange={handlePassword}
                />
                {toggleEye ? (
                  <AiFillEye
                    className="text-[#808080] text-xl"
                    onClick={handleToggle}
                  />
                ) : (
                  <FaEyeSlash
                    className="text-[#808080] text-xl"
                    onClick={handleToggle}
                  />
                )}
              </div>
            </div>

            <div className="flex flex-col gap-2 relative">
              <label className="text-[#808080]">Confirm Password</label>
              <div className="border border-gray-400 bg-white rounded-md px-3 flex justify-between items-center">
                <input
                  placeholder="Confirm new password"
                  type={toggleEye ? 'password' : 'text'}
                  className="w-full outline-none bg-transparent pl-2"
                  name="cpassword"
                  value={passwordForm.cpassword}
                  onChange={handlePassword}
                />
                {toggleEye ? (
                  <AiFillEye
                    className="text-[#808080] text-xl"
                    onClick={handleToggle}
                  />
                ) : (
                  <FaEyeSlash
                    className="text-[#808080] text-xl"
                    onClick={handleToggle}
                  />
                )}
              </div>
            </div>

            <button className="h-[54px] mt-[1rem] bg-[#0D6EFD] text-white">
              {isPending ? 'Please wait...' : 'Save Password'}
            </button>
          </form>

          <p className="text-center ">
            Remember password?{' '}
            <Link to="/" className="text-[#0D6EFD] font-semibold">
              Log in
            </Link>
          </p>
        </div>
      </AuthTheme>
      {modalOpen && (
        <ForgotPasswordModal
          showModal={setModalOpen}
          font=""
          title={'Password reset successfull'}
          body={'You can now use your new password to log into your account'}
          button={'Login'}
        />
      )}
    </div>
  );
};

export default NewPassword;
